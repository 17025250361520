<template lang="pug">
    v-autocomplete.haq-text-field(v-model="selectedUsers" :disabled="currentProjectUsers.length == 0" :items="currentProjectUsers" item-text="name" item-value="id" multiple @input="updateParent")
        template(v-slot:selection="data")
            v-chip(v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)")
                v-avatar(left)
                    v-img(:src="data.item.dp" v-if="data.item.dp")
                    span(v-else) {{data.item.initials}}
                | {{data.item.name}}
        template(v-slot:item="data")
            v-list-item-avatar
                img(:src="data.item.dp" v-if="data.item.dp")
                span(v-else) {{data.item.initials}}
            v-list-item-content
                v-list-item-title {{data.item.name}}
                v-list-item-subtitle {{data.item.email}}
</template>

<script>
export default {
    name: "ProjectUsersAutoComplete",
    props: {
        users: []
    },
    data(){
        return {
            currentProjectUsers: [],
            selectedUsers: [],
            projectId: this.$route.params.projectId
        }
    },
    methods: {
        getSeletedUsers(){
            this.selectedUsers = this.users;
        },
        async getUsersForThisProject(){
            try {
                let result = await this.$store.dispatch("getUsersForProject", this.projectId)
                   this.currentProjectUsers = []
                    for(let r of result){
                        this.currentProjectUsers.push({
                            id: r.objectId,
                            name: r.firstName + " " + r.lastName,
                            initials: r.firstName.charAt(0) + r.lastName.charAt(0),
                            email: r.username,
                            dp: r.profilePhoto?.url,
                            selected: false
                        })
                    }
            } catch (error) {
                console.error(error);
                window.M.toast({html: "Error fetching users for the project"})
            }
        },
        remove(item){
            console.log(item, this.selectedUsers)
            let index = this.selectedUsers.findIndex((el) => el == item.id);
            this.selectedUsers.splice(index, 1)
            this.$emit("modifyAlertUsers", this.selectedUsers)
        },
        updateParent(e){
            this.$emit("modifyAlertUsers", this.selectedUsers)
        }
    },
    mounted(){
        this.getSeletedUsers();
        this.getUsersForThisProject()
    }
}
</script>