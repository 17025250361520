<template>
	<div class="loadingDiv" :class="{small: shareKiosk}">
		<KioskBranded v-if="kiosk.type == 'branded'"
			:site="site"
			:s="shareKiosk"
			:kioskUUID="kiosk.uuid"
			:siteObjectId="kiosk.site ? kiosk.site.objectId : null"
			:zoneObjectId="kiosk.zone ? kiosk.zone.objectId : null"
			:preview="preview"
			:config="kiosk.config"
			ref="kiosk-branded"
		/>
		<Kiosk v-if="kiosk.type !== 'branded'"
			:site="site"
			:s="shareKiosk"
			:kioskUUID="kiosk.uuid"
			:siteObjectId="kiosk.site ? kiosk.site.objectId : null"
			:zoneObjectId="kiosk.zone ? kiosk.zone.objectId : null"
			:preview="preview"
			:config="kiosk.config"
			ref="kiosk"
		/>
	</div>
</template>
<style lang="scss" scoped>
.loadingDiv {
	position: relative;
	top: -66px;
	left: -15px;
}
</style>
<script>
import { HTTPClient } from "../assets/js/http";
import KioskBranded from '@/components/KioskBranded.vue'
import Kiosk from '@/components/Kiosk.vue'
let client = new HTTPClient();
export default {
    name: 'KiosPublic',
    components: {
        KioskBranded,
        Kiosk,
    },
	props: {
		kiosk: Object,
		shareKiosk: Boolean,
		preview: Boolean
	},
    data(){
        return {
            kioskId: this.kiosk.uuid,
            //kiosk: null,
            site: null,
        }
    },
    methods: {
        async redirectToKiosk(){
            try {
                let kiosk = await client.robustQuery('/api/kiosk/' + this.kiosk.uuid);
                this.site = kiosk.site;
                console.log('', this.site)
				console.log('Kiosk', kiosk)
                // window.location.href = `/${path}?kioskId=${this.kioskId}&siteId=${query.siteId}&zoneId=${query.zoneId}`
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted(){
        if(!this.kioskId){
            console.log('No Kiosk Id provided')
			if (!this.preview) {
				window.location.href = "/"
			}
        } else {
            this.redirectToKiosk();
        }
    }
}
</script>