<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card
            v-card-title
                span(class="text-h5") Add Project
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-container
                    v-row
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveProject") Save

</template>
<script>
// import {HTTPClient} from '../../assets/js/http'
// let client = new HTTPClient();

export default {
    name: 'ModalProjectAdd',
    props: {
        openModal: String,
    },
    data() {
        return {
            dialog: this.openModal == 'add' ? true : false,
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit("closeModal")
		},
        saveProject(){
            return false;
        }
    },
}
</script>
<style lang="scss">

</style>