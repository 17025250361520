<template lang="pug">
	v-dialog(v-model="dialog" max-width="792px" persistent)
		v-card.haq-dialog-card
			v-card-title
				span(class="text-h5") Share Widget 
				v-spacer
				v-icon.close(text @click="close") mdi-close
			v-card-text
				v-container
					v-row
						v-container(style="background-color:rgb(255 255 255 / 20%); border-radius: 8px;padding:41px;min-height:148px")
							ol
								li Install the iaqjs npm package 
									a(href="https://github.com/HawkenIndustries/iaqjs" target="_blank") (npm install iaqjs)
								li Please copy and past the following code to add the widget to your website:
								li
									pre
										code()
											|	&lt;script&gt;
											|		var AQ = new IAQ('{{key}}');
											|		var o = { size: '{{widgetSize}}', theme: '{{widgetColor}}', iconMode: {{iconMode}}, bgGradient: {{gradientBackground}} }
											|		AQ.generate(domElement: Element, widgetId: '{{widgetPreview.objectId}}', options: o)
											|	&lt;/script&gt;
								li Replace "Element" with the DOM element you want to add your widget to
								li For questions or support please reach out to us at support@hawkenaq.com
			v-card-actions
				v-spacer
				v-btn.haq-btn.haq-btn-secondary(text @click="close") Close

</template>
<style lang="scss" scoped>
.haq-dialog-card {
	overflow-x: hidden;
	#widgetPreview {
		position: relative;
		left: 25%;
	}
}
</style>
<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Widget from '@/components/Widget.vue'
import WidgetMeasurementSelector from '@/components/widget_crud/WidgetMeasurementSelector.vue'

export default {
    name: 'ModalWidgetShare',
    components: {
        Navbar,
        Breadcrumbs,
		Widget,
		WidgetMeasurementSelector
    },
	props: {
		shareDialog: String,
		widgetPreview: Object,
		sites: Array
	},
    data() {
        return {
			dialog: this.shareDialog == 'share' ? true : false,
			widgetSizes: [
				{text: 'Mini', value: 'mini'},
				{text: 'Small', value: 'small'},
				{text: 'Medium', value: 'medium'},
				{text: 'Large', value: 'large'},
			],
			widgetColors: [
				{text: 'Light', value: 'light'},
				{text: 'Light 2', value: 'light-2'},
				{text: 'Blue', value: 'blue'},
				{text: 'Dark', value: 'dark'},
			],
			widgetSize: this.widgetPreview.defaultSize ? this.widgetPreview.defaultSize : 'large',
			widgetColor: this.widgetPreview.defaultColor ? this.widgetPreview.defaultColor :  'blue',
			gradientBackground: this.widgetPreview.defaultGradient != undefined ? this.widgetPreview.defaultGradient :  true,
			iconMode: this.widgetPreview.defaultIconMode != undefined ? this.widgetPreview.defaultIconMode :  false,
			addMeasurement: false,
			measurement: this.widgetPreview.measurements[0] || null,
			loaded: false,
			widgetUUID: this.widgetPreview.uuid,
            siteId: this.$route.params.siteId,
			key: this.sites.find(x => x.objectId == this.$route.params.siteId).clientKey
        }
    },
    methods: {
		close() {
			this.dialog = false;
			this.$emit('closeAddModal')
		},
		closeModal() {
			this.addMeasurement = false;
		},
		setWidget(m) {
			this.measurement = m;
			this.setupPreview(false)
		},
		async setupPreview(empty) {
			this.loaded = false;
			if (!empty) {
				let myNode = document.getElementById('widgetPreview')
				myNode.innerHTML = '';
			}
			let AQ = new window.IAQ("4e98a96cce429be1b423");
			let dom = document.querySelector('#widgetPreview');
			if (this.widgetPreview.measurements.length || this.measurement) {
				await AQ.generate(dom, this.widgetPreview.objectId, {theme: this.widgetColor, size: this.widgetSize, iconMode: this.iconMode, bgGradient: this.gradientBackground})
			}
			this.loaded = true;
		}
	},
	mounted() {
		console.log('mounted')
	},
	watch: {
		'$props.addDialog': {
			handler() {
				this.dialog = this.addDialog == 'share' ? true : false;
				if (!this.dialog) {
					this.close();
				}
			}
		},
	}
}
</script>