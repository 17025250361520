import Axios from 'axios';
import {get} from 'lodash';
const _ = {get};

export class HTTPClient {
    constructor(){
        this.client = Axios;
        Axios.interceptors.response.use(function(response){
            return response;
        }, function(e){
            // redirect on 401
            if(e.response&&e.response.status==401){
                localStorage.setItem('airQualityAdminToken', '');
                window.location.href = '/login';
            }
            return Promise.reject(e);
        });
        
        this.token = localStorage.getItem('airQualityAdminToken');
        if(this.token){
            this.client.defaults.headers.common['Authorization'] = "Bearer " + this.token;
        }
        this.client.defaults.headers.common['Content-Type'] = 'application/json';
    }

     /**
     * @param {string} url
     * @param {object} params
     * @returns {import('axios').AxiosResponse}
     */

    async robustQuery(url, params){
        try {
            let tries = 2;
            while(tries <= 3){
                let res = await this.client.get(url, {params : params} );
                if([200, 302].indexOf(res.status) > -1){
                    return res.data;
                } else {
                    tries ++;
                }
            }
            if(tries >=3 ){
                throw new Error('Connection attempts exceeded');
            }
        } catch (e) {
            let msg = (_.get(e, 'response.data.errorMessage')) || (e.response&&e.response.statusText) || '';
            throw new Error(msg);
        }
    }

    async robustPost(url, data) {
        // Should we attempt tries for robust post
        try {
            let res = await this.client.post(url, data);
            return res.data;
        } catch (e) {
            let msg = (_.get(e, 'response.data.errorMessage')) || (e.response&&e.response.statusText) || '';
            throw new Error(msg);
        }
    }

    async robustDelete(url, data) {
        try {
            let res = await this.client.delete(url, data);
            return res.data;
        } catch (e) {
            let msg = (_.get(e, 'response.data.errorMessage')) || (e.response&&e.response.statusText) || '';
            throw new Error(msg);
        }
    }
}