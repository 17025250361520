<template lang="pug">
    .haq-card
        .haq-card-header
            .haq-card-header-img.d-none.d-sm-flex(v-if="logoUrl")
                img(:src="logoUrl")
            .haq-card-header-info
                v-tooltip(top)
                    template(v-slot:activator="{on,attrs}")
                        .project-title.truncate(v-bind="attrs" v-on="on") {{title}}
                    span {{title}}
                v-tooltip(top)
                    template(v-slot:activator="{on,attrs}")
                        .customer-name.truncate(v-bind="attrs" v-on="on") {{customerName}}
                    span {{customerName}}
            v-spacer
            .haq-card-header-icons
                button.haq-card-header-icons-settings(@click="openModal")
                    v-icon mdi-cog
                a.haq-card-header-icons-link-to(@click="openProject")
                    v-icon mdi-open-in-new
        .haq-card-content
            v-btn.haq-card-button(@click="$router.push('/projects/' + projectId + '/sites')")
                .haq-card-button-icon
                    CardIcons(:itemType="'site'" :color="'#7D85C5'")
                .haq-card-button-title {{ siteCount }} {{ siteCount != 1 ? 'Sites' : 'Site' }}
            v-btn.haq-card-button(@click="$router.push('/projects/' + projectId + '/users')")
                .haq-card-button-icon
                    CardIcons(:itemType="'user'" :color="'#7D85C5'")
                .haq-card-button-title {{ userCount }} {{ userCount != 1 ? 'Users' : 'User' }}
            v-btn.haq-card-button(@click="$router.push('/projects/' + projectId + '/groups')")
                .haq-card-button-icon
                    CardIcons(:itemType="'group'" :color="'#7D85C5'")
                .haq-card-button-title {{ groupCount }} {{ groupCount != 1 ? 'Groups' : 'Group' }}
            //- v-btn.haq-card-button(@click="$router.push('/projects/' + projectId + '/alarms')")
            //-     .haq-card-button-icon
            //-         CardIcons(:itemType="'alarm'" :color="'#7D85C5'")
            //-     .haq-card-button-title Alarms
            //- v-btn.haq-card-button(disabled)
            //-     .haq-card-button-icon
            //-         CardIcons(:itemType="'alarm'" :color="'var(--card-button-deactivated-txt)'")
            //-     .haq-card-button-title Alarms
            //- v-btn.haq-card-button(disabled)
            //-     .haq-card-button-icon
            //-         CardIcons(:itemType="'report'" :color="'var(--card-button-deactivated-txt)'")
            //-     .haq-card-button-title Reports
</template>

<script>
import CardIcons from '@/components/CardIcons.vue';

export default {
    name: 'ProjectCard',
    components: {
        CardIcons,
    },
    props: {
        projectId: String,
        title: String,
        logoUrl: String,  
        customerName: String,      
        siteCount: Number,
        userCount: Number,
        groupCount: Number,
    },
    methods: {
        truncated(e) {
            return e.target.offsetWidth < e.target.scrollWidth;
        },
        openModal() {
			this.$emit("openEditModal",this.projectId)
		},
        openProject(){
            let subdomain = location.host.split('.')[0];
            let domain = location.host.split('.')[1];
            let beta = subdomain.indexOf("beta") > -1 ? true : false;
            let betaUrl = `https://beta.${domain}.com/projects/${this.projectId}`
            let prodUrl = `https://app.${domain}.com/projects/${this.projectId}`
            location = beta ? betaUrl : prodUrl;
        }
    },
}
</script>
<style lang="scss">

</style>
<style lang="scss" scoped>
.haq-card {
    .haq-card-header-info {
        .project-title {
            font-size: 1.2rem;
        }
    }
}
</style>