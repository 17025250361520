<template lang="pug">
    #manageAlertConfigs.haq-table-page
        Navbar(tab="")
        v-container
            v-row 
                v-col
                    Breadcrumbs(:items="bcItems")
            v-row
                v-col
                    .page-header
                        .page-title
                            h1 Alert Configurations
                        .page-actions
                            v-btn.haq-btn.haq-btn-primary.btn-add-alertconfig(plain @click="currentDialog='add'") <v-icon>mdi-plus</v-icon> Configuration
            v-row(v-if="currentAlertConfigs")
                v-col
                    .page-content
                        .page-has-table(v-if="currentAlertConfigs.length > 0")
                                v-data-table.elevation-8.haq-data-table(
                                    v-model="selectedDeleteAlertConfig" 
                                    show-select 
                                    item-key="objectId" 
                                    :search="searchEditAlertConfigs" 
                                    :headers="dtEditAlertConfigsHeaders" 
                                    :items="dtEditAlertConfigs" 
                                    :items-per-page="10")
                                    template(v-slot:top)
                                        .edit-alertConfigs-actions
                                            v-col(sm=5)
                                                v-text-field.haq-search(v-model="searchEditAlertConfigs" append-icon="mdi-magnify" label="Search Alert Configurations" dense outlined hide-details single-line)
                                            v-btn.haq-btn.haq-btn-primary.btn-add-alertConfig(v-show="selectedDeleteAlertConfig.length > 0" @click="currentDialog='delete'" plain)
                                                v-icon(color="warning") mdi-delete
                                                | Delete {{ selectedDeleteAlertConfig.length > 1 ? 'Configurations' : 'Configuration' }}
                                    template(v-slot:item.channels="{item}") 
                                        span.channel(v-for="c,index in item.channels") {{getChannelName(c)}}
                                            span(v-if="index+1 < item.channels.length") , 
                                    template(v-slot:item.config="{item}") {{item.config ? getConfigName(item.config) : ''}}
                                    template(v-slot:item.measurements="{item}") 
                                        span.channel(v-for="m,index in item.measurements") {{m.name}}
                                            span(v-if="index+1 < item.measurements.length") , 
                                    template(v-slot:item.alertType="{item}")
                                        .alert-type
                                            .alert-type-icon(:class="'at-' + item.alertType")
                                            | {{ item.alertType ? item.alertType.charAt(0).toUpperCase() + item.alertType.slice(1) : ''}}
                                    template(v-slot:item.edit="{ item }")
                                        v-icon(small class="mr-2" @click="openEditModal(item)") mdi-dots-vertical
                        .page-no-table(v-else)
                            p There are no currently no alert configrations.
                            v-btn.haq-btn.haq-btn-secondary.btn-return(href="/") Return Home
                            v-btn.haq-btn.haq-btn-primary.btn-add-alertconfig(plain @click="currentDialog='add'") <v-icon>mdi-plus</v-icon> Alert Configuration
        ModalAlertConfigAdd(v-if="currentDialog == 'add'" :openModal="currentDialog" v-on:closeModal="closeModal" 
            :siteId="siteId")
        ModalAlertConfigEdit(v-if="currentDialog == 'edit'" :openModal="currentDialog" v-on:closeModal="closeModal"
            :siteId="siteId"
            :editedAlertConfig="editedAlertConfig"
            :groups="currentProjectGroups"
            :editedIndex="editedIndex"
            :dtEditAlertConfigs="dtEditAlertConfigs")
        ModalAlertConfigDelete(v-if="currentDialog == 'delete'" :openModal="currentDialog" v-on:closeModal="closeModal"
            :selectedDeleteAlertConfig="selectedDeleteAlertConfig")
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import ModalAlertConfigAdd from '@/components/alert_crud/ModalAlertConfigAdd'
import ModalAlertConfigEdit from '@/components/alert_crud/ModalAlertConfigEdit'
import ModalAlertConfigDelete from '@/components/alert_crud/ModalAlertConfigDelete'
import {HTTPClient} from '../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'SiteAlertConfigs',
    components: {
        Navbar,
        Breadcrumbs,
        ModalAlertConfigAdd,
        ModalAlertConfigEdit,
        ModalAlertConfigDelete,
    },
    data() {
        return {
            projectId: this.$route.params.projectId,
            siteId: this.$route.params.siteId,
            project: null,
            site: null,
            currentAlertConfigs: null,
            currentProjectGroups: null,
            timer: null,
            bcItems: [
                { text: 'Home', disabled: false, href: '/', },
                { text: 'Projects', disabled: false, href: '/projects', },
                { text: `Sites`, disabled: false, href: '/projects/' + this.$route.params.projectId + '/sites', },
                { text: 'Alert Configurations', disabled: true, href: '', },
            ],
            currentDialog: null,
            // EDIT
            dtEditAlertConfigsHeaders:[],
            dtEditAlertConfigs:[],
            searchEditAlertConfigs: '',
            editedIndex: -1,
            editedAlertConfig: {
                objectId: null,
                site: null,
                message: null,
                channels: null,
                config: null,
                kind: null,
                alertType: null,
                groups: []
            },
            // Delete
            selectedDeleteAlertConfig: [],
        }
    },
    methods: {
        closeModal() {
			this.currentDialog = null;
            this.resetAlertConfigs();
		},
        openEditModal(alertConfig) {
            this.currentDialog = 'edit';
            this.editAlertConfigCurrentPhoto = alertConfig.profilePhoto;
            this.editedIndex = this.dtEditAlertConfigs.indexOf(alertConfig)
            this.editedAlertConfig = Object.assign({}, alertConfig)
        },
        closeEditModal() {
            this.closeModal();
            this.$nextTick(() => {
                this.editedAlertConfig = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            });
        },
        getChannelName(channel) {
            switch(channel) {
                // case 'ui':      return 'App';
                case 'email':   return 'Email';
                // case 'push':    return 'Push';
                case 'sms':     return 'SMS';
                default:        return '';
            }
        },
        getConfigKeyName(key) {
            switch(key) {
                case 'eq':      return 'Equals';
                case 'notEq':   return 'Does not equal';
                case 'gt':      return 'Greater than';
                case 'gte':     return 'Greater than/equal';
                case 'lt':      return 'Less than';
                case 'lte':     return 'Less than/equal';
                case 'in':      return 'Value IN the array';
                case 'notIn':   return 'Value NOT in the array';
                case 'off':     return 'Offline';
                default:        return '';
            }
        },
        getConfigName(config){
            let configKey = Object.keys(config)[0];
            let configVal = Object.values(config)[0];
            let key = this.getConfigKeyName(configKey);
            if(configKey === 'off')
                return key;
            else
                return key + ': ' + configVal;            
        },
        buildAlertConfigsTable(alertConfigs) {
            this.dtEditAlertConfigs = [];
            alertConfigs.forEach(ac => {
                let alertConfig = {
                    objectId: ac.objectId,
                    config: ac.config,
                    alertType: ac.alertType ? ac.alertType : null,
                    message: ac.message ? ac.message : '',
                    measurements: ac.measurements ? ac.measurements : '',
                    channels: ac.channels ? ac.channels : '',
                    edit: "",
                    users: ac.users,
                    groups: (ac.groups) ? ac.groups.map(g => g.objectId) : [],
                };
                this.dtEditAlertConfigs.push(alertConfig);
            });
            this.dtEditAlertConfigsHeaders = [
                { text: 'Configuration', value: 'config', },
                { text: 'Alert Type', value: 'alertType', },
                { text: 'Alert Message', value: 'message', align: 'start', },
                { text: 'Measurements', value: 'measurements', align: 'start', },
                { text: 'Notifications', value: 'channels', align: 'start', },
                { text: '', value: 'edit', align: 'right', sortable: false, width: 1, },
            ];
        },
        resetAlertConfigs() {
            this.fetchAlertConfigs()
            this.selectedDeleteAlertConfig = []
        },
        async fetchAlertConfigs(){
            try {
                let measurementRes = await client.robustQuery(`/admins/sites/${this.siteId}/alertableMeasurementsBasic`);
                let alertConfigsRes = await client.robustQuery(`/admins/sites/${this.siteId}/alertconfigs`);
                let currentProjectGroups = await client.robustQuery(`/api/projects/${this.projectId}/groups`);
                alertConfigsRes.alertConfigs.forEach(ac=> {
                    let measurements = []
                    measurementRes.results.forEach(m => {
                        if(m.alertConfigs.some(m => m.objectId === ac.objectId))
                            measurements.push(m)
                    })
                    ac.measurements = measurements
                })
                this.currentAlertConfigs = alertConfigsRes.alertConfigs
                this.buildAlertConfigsTable(alertConfigsRes.alertConfigs);
                this.currentProjectGroups = currentProjectGroups.results;
            } catch (error) {
                console.error(error);
            }
        },
    },
    mounted() {
        this.fetchAlertConfigs();
    },
    watch: {
		// eslint-disable-next-line no-unused-vars
		$route(to, from) {
            this.fetchAlertConfigs();
		},
	},
}
</script>
<style lang="sass" scoped>
    .controls
        display: flex
        align-items: center
    .edit-alertConfigs-actions
        display: flex
        align-items: flex-end
        justify-content: space-between
        padding-bottom: 15px
        gap: 35px
        .v-input--selection-controls
            margin: 0
            padding: 0
    .theme-icon
        font-size: 16px

    .alert-type
        display: flex
        flex-direction: row
        align-items: center
        .alert-type-icon
            border-radius: 50px
            height: 10px
            width: 10px
            margin-right: 10px
            &.at-info
                background: var(--score-blue-color)
            &.at-warning
                background: var(--score-yellow-color)
            &.at-critical
                background: var(--score-red-color)
</style>