<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card
            v-card-title
                span(class="text-h5") Edit Project
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-container
                    v-row
                        v-col(cols="12").title
                        v-col(sm="12")
                            v-text-field.haq-text-field(v-model="project.name" label="Name" hide-details)
                    v-row.settings
                        v-col(cols="12").title Settings
                        v-col(sm="12")
                            v-select.haq-text-field(v-model="project.whitelabel" :items="whitelabels" item-text="t" item-value="v" label="Whitelabel" hide-details)
                        v-col(sm="12")
                            v-select.haq-text-field(v-model="project.units" :items="units" item-text="t" item-value="v" label="Units" hide-details)
                    v-row.logos
                        v-col(cols="12")
                            .title Whitelabel Logos
                            .subtitle These are used in the main dashboard. You can add variants for the light and dark theme.
                        v-col(sm="6")
                            .logo 
                                .logo-name Dark Logo
                                img.darkLogo(v-if="project.darkLogo" :src="project.darkLogo.url ? project.darkLogo.url.replace('http://','https://') : newDarkLogo")
                                v-file-input(v-model="project.darkLogo" label="Dark Logo" accept="image/*" show-size hide-details @change="renderFile")
                        v-col(sm="6")
                            .logo 
                                .logo-name Light Logo
                                img.lightLogo(v-if="project.lightLogo" :src="project.lightLogo.url ? project.lightLogo.url.replace('http://','https://') : newLightLogo")
                                v-file-input(v-model="project.lightLogo" label="Light Logo" accept="image/*" show-size hide-details @change="renderFile")
                    v-row.logos
                        v-col(cols="12")
                            .title Branding Logos
                            .subtitle These are used in the branded kiosk.
                        v-col(sm="6")
                            .logo
                                .logo-name Primary Logo
                                img.brandingLogo1(v-if="project.brandingLogo1" :src="project.brandingLogo1.url ? project.brandingLogo1.url.replace('http://','https://') : newBrandingLogo1")
                                v-file-input(v-model="project.brandingLogo1" label="Primary Logo" accept="image/*" show-size hide-details @change="renderFile")
                        v-col(sm="6")
                            .logo
                                .logo-name Secondary Logo
                                img.brandingLogo2(v-if="project.brandingLogo2" :src="project.brandingLogo2.url ? project.brandingLogo2.url.replace('http://','https://') : newBrandingLogo2")
                                v-file-input(v-model="project.brandingLogo2" label="Secondary Logo" accept="image/*" show-size hide-details @change="renderFile")
                    
                            //- button.img-wrap(@click="changePhoto")
                            //-     img.haq-avatar(v-if="newPhoto" :src="newPhoto")
                            //-     img.haq-avatar(v-else-if="editUserCurrentPhoto" :src="editUserCurrentPhoto.url.replace('http://','https://')")
                            //-     img.haq-avatar(v-else src="/images/ssprofile.png")
                            //-     .button-text {{ defaultButtonText }}

            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveProject") Save
</template>
<script>
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalProjectEdit',
    props: {
        project: Object,
        openModal: String,
    },
    data() {
        return {
            whitelabels: [
                { v: '', t: 'None' },
                { v: 'CommanderAQ', t: 'CommanderAQ' },
                { v: 'Onformant', t: 'Onformant' },
            ],
            units: [
                { v: 'imperial', t: 'Imperial' },
                { v: 'metric', t: 'Metric' },
            ],
            newLightLogo: null,
            newDarkLogo: null,
            newBrandingLogo1: null,
            newBrandingLogo2: null,
            newLightLogoBase64: null,
            newDarkLogoBase64: null,
            newBrandingLogo1Base64: null,
            newBrandingLogo2Base64: null,
            dialog: this.openModal == 'edit' ? true : false,
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit("closeModal")
		},
        saveProject(){
            try {
                let clone = JSON.parse(JSON.stringify(this.project));
                
                if(this.newDarkLogoBase64) {
                    delete clone.darkLogo;
                    clone.darkLogo = this.newDarkLogoBase64;
                }
                if(this.newLightLogoBase64) {
                    delete clone.lightLogo;
                    clone.lightLogo = this.newLightLogoBase64;
                }
                if(this.newBrandingLogo1Base64) {
                    delete clone.brandingLogo1;
                    clone.brandingLogo1 = this.newBrandingLogo1Base64;
                }
                if (this.newBrandingLogo2Base64) {
                    delete clone.brandingLogo2;
                    clone.brandingLogo2 = this.newBrandingLogo2Base64;
                }

                client.robustPost(`/api/projects/${this.project.objectId}`,clone).then(function(result){
                    console.log('Project Updated',result);
                }).catch(function(error){
                    console.log("Project Not Updated", error);
                })
            }
            catch(error) {
                console.error(error);
            }
            this.closeModal();
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        async renderFile(e){
            try {
                if(e) {
                    let file = e;
                    let base64 = await this.getBase64(file);
                    if(file == this.project.darkLogo) {
                        this.newDarkLogoBase64 = {type: file.type, base64, name: file.name};
                        this.newDarkLogo = URL.createObjectURL(file);
                    }
                    else if(file == this.project.lightLogo) {
                        this.newLightLogoBase64 = {type: file.type, base64, name: file.name};
                        this.newLightLogo = URL.createObjectURL(file);
                    }
                    else if(file == this.project.brandingLogo1) {
                        this.newBrandingLogo1Base64 = {type: file.type, base64, name: file.name};
                        this.newBrandingLogo1 = URL.createObjectURL(file);
                    }
                    else if (file == this.project.brandingLogo2) {
                        this.newBrandingLogo2Base64 = {type: file.type, base64, name: file.name};
                        this.newBrandingLogo2 = URL.createObjectURL(file);
                    }
                    return true;
                }
                return false;
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted() {},    
}
</script>
<style lang="scss">
.logos {
    .logo img {
        max-height: 100px;
        max-width: 350px;
    }
        
}
    
</style>