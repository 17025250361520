import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App),
        mounted() {
        this.$nextTick(() => {
            if (window.M && typeof window.M.AutoInit === 'function') {
            window.M.AutoInit();
            }
        });
        }
    }).$mount('#app');
});

