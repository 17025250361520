<template lang="pug">
    #manageGroups.haq-table-page
        Navbar(tab="")
        v-container
            v-row 
                v-col
                    Breadcrumbs(:items="bcItems")
            v-row
                v-col(md="8")
                    .page-header
                        .page-title
                            h1 Groups for {{project ? project.name : ''}}
                        .page-actions
                            
                v-col(md="4")
                    v-select(v-model="projectId" :items="$store.state.projects" item-text="name" item-value="objectId" label="Change Project" @change="changeProject" hide-details dense outlined)
            v-row(v-if="currentProjectGroups")
                v-col
                    .page-content
                        .page-has-table(v-if="currentProjectGroups.length > 0")
                                v-data-table.elevation-8.haq-data-table(
                                    v-model="selectedRemoveGroup" 
                                    show-select 
                                    item-key="objectId" 
                                    :search="searchEditGroups" 
                                    :headers="dtEditGroupsHeaders" 
                                    :items="dtEditGroups" 
                                    :items-per-page="10")
                                    template(v-slot:top)
                                        .edit-groups-actions
                                            v-row
                                                v-col(sm=5)
                                                    v-text-field.haq-search(v-model="searchEditGroups" append-icon="mdi-magnify" label="Search Groups" dense hide-details single-line filled outlined)
                                                v-col(sm=7 align="end")
                                                    v-btn.haq-btn.haq-btn-primary.btn-add-group(plain @click="currentDialog='add'") <v-icon>mdi-plus</v-icon> Group
                                                    v-btn.haq-btn.haq-btn-primary.btn-add-group(v-show="selectedRemoveGroup.length > 0" @click="currentDialog='remove'" plain)
                                                        v-icon(color="warning") mdi-delete
                                                        | Remove {{ selectedRemoveGroup.length > 1 ? 'Groups' : 'Group' }}
                                    template(v-slot:item.email="{ item }") {{ item.email }} 
                                        v-icon(v-if="item.emailVerified" small color="success") mdi-check-decagram
                                    template(v-slot:item.theme="{item}")
                                        v-icon.theme-icon(v-if="item.theme === 'dark'") mdi-moon-waxing-crescent
                                        v-icon.theme-icon(v-if="item.theme === 'light'") mdi-white-balance-sunny
                                    template(v-slot:item.edit="{ item }")
                                        v-icon(small class="mr-2" @click="openEditModal(item)") mdi-dots-vertical
                        .page-no-table(v-else)
                            p There are no groups for this project.
                            v-btn.haq-btn.haq-btn-secondary.btn-return(href="/projects") Return to Projects
                            v-btn.haq-btn.haq-btn-primary.btn-add-group(plain @click="currentDialog='add'") <v-icon>mdi-plus</v-icon> Group
        ModalProjectGroupAdd(v-if="currentDialog == 'add'" :openModal="currentDialog" v-on:closeModal="closeModal" 
            :projectId="projectId"
            :project="project"
            :allProjectsGroups="allProjectsGroups" 
            :currentProjectGroups="currentProjectGroups")
        ModalProjectGroupEdit(v-if="currentDialog == 'edit'" :openModal="currentDialog" v-on:closeModal="closeModal"
            :projectId="projectId"
            :project="project"
            :group="editedGroup"
            :editedIndex="editedIndex"
            :dtEditGroups="dtEditGroups")
        ModalProjectGroupRemove(v-if="currentDialog == 'remove'" :openModal="currentDialog" v-on:closeModal="closeModal"
            :projectId="projectId"
            :project="project"
            :selectedRemoveGroup="selectedRemoveGroup")
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import ProjectGroupsTable from '@/components/ProjectGroupsTable.vue'
import ModalProjectGroupAdd from '@/components/project_crud/ModalProjectGroupAdd'
import ModalProjectGroupEdit from '@/components/project_crud/ModalProjectGroupEdit'
import ModalProjectGroupRemove from '@/components/project_crud/ModalProjectGroupRemove'
import {HTTPClient} from '../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ProjectGroups',
    components: {
        Navbar,
        Breadcrumbs,
        ProjectGroupsTable,
        ModalProjectGroupAdd,
        ModalProjectGroupEdit,
        ModalProjectGroupRemove,
    },
    data() {
        return {
            projectId: this.$route.params.projectId,
            project: null,
            allProjectsGroups: null,
            currentProjectGroups: null,
            timer: null,
            bcItems: [
                { text: 'Home', disabled: false, href: '/', },
                { text: 'Projects', disabled: false, href: '/projects', },
                { text: 'Groups', disabled: true, href: '', },
            ],
            currentDialog: null,
            // EDIT
            dtEditGroupsHeaders:[],
            dtEditGroups:[],
            searchEditGroups: '',
            editedIndex: -1,
            editedGroup: {
                objectId: '',
                name: '',
            },
            // REMOVE
            selectedRemoveGroup: [],
        }
    },
    methods: {
        closeModal() {
			this.currentDialog = null;
            this.resetGroups();
		},
        changeProject(projectId) {
            this.$router.push({name: 'ProjectGroups', params: {projectId}});
            this.fetchProject();
            this.fetchGroups();
        },
        openEditModal(group) {
            this.currentDialog = 'edit';
            this.editedIndex = this.dtEditGroups.indexOf(group)
            this.editedGroup = Object.assign({}, group)
        },
        closeEditModal() {
            this.closeModal();
            this.$nextTick(() => {
                this.editedGroup = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            });
        },
        buildGroupsTable(groups) {
            this.dtEditGroups = [];
            groups.forEach(u => {
                let group = {
                    objectId: u.objectId,
                    name: u.name,
                    edit: "",
                };
                this.dtEditGroups.push(group);
            });
            // manually setting the headers. might refine the api call to only get these fields then make this dynamic.
            this.dtEditGroupsHeaders = [
                { text: 'Name', value: 'name', align: 'start', },
                { text: '', value: 'edit', align: 'right', sortable: false, width: 1, },
            ];
        },
        resetGroups() {
            this.fetchGroups();
            this.selectedRemoveGroup = [];
        },
        fetchProject: async function(){
            try {
                let result = await client.robustQuery(`/admins/projects/${this.projectId}`);
                this.project = result.project;
            }
            catch(error) {
                console.error(error);
            }
        },
        fetchGroups: async function(){
            try {
                let allProjectsGroupsResult = await client.robustQuery(`/admins/projects`);
                let currentProjectGroupsResults = await client.robustQuery(`/admins/projects/${this.projectId}/groups`);
                
                this.buildGroupsTable(currentProjectGroupsResults.groups);
                this.allProjectsGroups = allProjectsGroupsResult.groups;
                this.currentProjectGroups = currentProjectGroupsResults.groups;
            } catch (error) {
                console.error(error);
            }
        },
    },
    mounted() {
        this.fetchProject();
        this.fetchGroups();
    },
    watch: {
		// eslint-disable-next-line no-unused-vars
		$route(to, from) {
            this.fetchProject();
            this.fetchGroups();
		},
	},
}
</script>
<style lang="scss" scoped>
.controls {
    display: flex;
    align-items: center;
}
.edit-groups-actions {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 15px;
    // gap: 35px;
    .v-input--selection-controls {
        margin: 0;
        padding: 0;
    }
}
.theme-icon {
    font-size: 16px;
}
</style>