<template lang="pug">
v-dialog(v-model="measurementDialog" max-width="548px")
	v-card.haq-dialog-card#widget-measurement
		v-card-text.mt-3
			v-row
				v-col
					span Zones
				v-col
					span Measurements
			v-container(style="border-radius: 8px; max-height: 248px")
				v-row
					v-col
						v-row
							v-col.col-6(style="padding-left:0px")
								.zoneSelector(style="padding: 12px")
									.row(
										v-for="z in zones"
										:key="z.objectId"
										@click="currZone = z.objectId;getZoneMeasurements()"
										:class="{selected: currZone == z.objectId}"
									)
										v-col.col-10
											span {{z.name}} 
										v-col.col-1
											span.zonePointer
												<svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M0 10L5 5L-4.37114e-07 -1.90735e-06L0 10Z" fill="#ffffff"/>
												</svg>
							v-col.col-6(style="padding-left:0px")
								.measurementSelector(style="padding:12px")
									.row(v-for="m in measurements" :key="m.objectId" @click="setMeasurement(m);currZone=null")
										v-col.col-10
											span {{m.name}} 
									.row(v-if="!loading && currZone && (!measurements || measurements.length == 0)")
										v-col.col-10
											span No measurements for this zone 
									v-progress-circular(v-if="loading" indeterminate color="primary")
			v-card-actions.footer
</template>
<script>
import {HTTPClient} from '../../assets/js/http';

let client = new HTTPClient();
export default {
    name: 'WidgetMeasurementSelector',
	
    components: {
        
    },
	props: {
		measurementDialog: Boolean,
		overallMeasurement: Boolean,
	},
    data() {
		return {
			projectId: this.$route.params.projectId,
			siteId: this.$route.params.siteId,
			zones: null,	
			currZone: null,
			measurements: null,
			loading: false,
		}
    },
	methods: {
		async getZones() {
			try {
				let result = await client.robustQuery(`/api/zones?siteId=${this.siteId}`);
				this.zones = result.results;
			} catch (error) {
				console.error(error);
			}
		},	
		async getZoneMeasurements() {
			try {
				this.loading = true;
				this.measurements = [];
				let result = await client.robustQuery(`/api/measurements?siteId=${this.siteId}&zoneId=${this.currZone}`);

				let scores = ['scoreaq', 'scoreow'];
				let components = ['spacetemperature','relativehumidity', 'co2', 'pm2.5', 'infectiontransmissibility', 'timeincomfortzone',
					'ionlevel', 'ionizerstatus', 'ionizationtime', 'pm10', 'carbonmonoxide', 'ozone', 'scoreaf', 'scoreaq', 'scoreow'];
				let measurements = [];
				if (!this.overallMeasurement) {
					for(let c of components){
						for(let m of result.results){
							if(m.type.toLowerCase() == c){
								measurements.push(m);
							}

						}
					}
				} else {
					for (let m of result.results) {
						if(scores.includes(m.type.toLowerCase()) && m.curScore > 0)
							measurements.push(m);
					}
				}
				this.measurements = measurements;
				this.loading = false;
			} catch (error) {
				console.error(error);
			}
		},
		async setMeasurement(m) {
			try {
				console.log('m', m)
				//await client.robustPost(`/api/widgets/${this.widgetUUID}`, {measurement: m.objectId, name: m.name})
				this.$emit('setMeasurement', m)
				this.currZone = null;
				this.measurements = [];
				this.$emit('close')
			} catch (error) {
				console.error(error);
			}
		}	
	},
	mounted() {
		this.getZones();	
	},
	watch: {
		measurementDialog: function(newVal) {
			if (!newVal) {
				this.$emit('close')
			}
		}	
	}
}
</script>
<style lang="scss" scoped>
#widget-measurement {
	max-height: 328px;
}
.footer {
	padding: 15px !important
}
.zoneSelector {
	max-height: 230px;
	border-right: 1px solid rgba(255, 255, 255, 0.1);
	overflow-y: scroll;	
	overflow-x: hidden;
	cursor: pointer;
	.row {
		&:hover {
			background-color: rgba(255, 255, 255, 0.1)
		}
		&.selected {
			background-color: rgba(255, 255, 255, 0.1)
		}
	}
}
.zoneSelector::-webkit-scrollbar {
  display: none;
}
.measurementSelector::-webkit-scrollbar {
  display: none;
}
.measurementSelector {
	max-height: 230px;
	overflow-y: scroll;	
	overflow-x: hidden;
	cursor: pointer;
	.row {
		&:hover {
			background-color: rgba(255, 255, 255, 0.1)
		}
	}
}
</style>