<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card
            v-card-title
                span(class="text-h5") Add Group
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-container
                    v-row
                        v-col(sm="12")
                            v-text-field(v-model="group.name" label="Name" hide-details dense filled outlined)
                    v-row(v-if="errorMessage")
                        v-col(sm="12")
                            v-col.error {{ errorMessage }}
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveGroup") Save

</template>
<script>
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalGroupAdd',
    props: {
        openModal: String,
        project: Object,
    },
    data() {
        return {
            dialog: this.openModal == 'add' ? true : false,
            errorMessage: null,
            group: {
                name: null,
            }
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit("closeModal")
		},
        saveGroup(){
            let clone = JSON.parse(JSON.stringify(this.group));
            this.errorMessage = null;

            client.robustPost(`/api/projects/${this.project.objectId}/groups`,clone).then(function(result){
                // console.log('Group Updated',result);
                window.M.toast({
                    html: `Group created`,
                });
            }).catch((error) => {
                console.log("Group not created", error);
                this.errorMessage = `Group not created: ${error.message}`;
                window.M.toast({
                    html: `Group not created: ${error.message}`,
                });
            });
            this.closeModal();
            return false;
        }
    },
}
</script>
<style lang="scss">

</style>