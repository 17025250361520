<template lang="pug">
    #manageSites.haq-card-page
        Navbar(tab="")
        v-container
            v-row
                v-col
                    Breadcrumbs(:items="bcItems")
            v-row
                v-col
                    .page-header
                        .page-title
                            h1 Sites for {{project ? project.name : ''}}
                        .page-actions
                            //- v-btn.haq-btn.haq-btn-primary.btn-add-site(plain @click="currentDialog = 'add'") <v-icon>mdi-plus</v-icon> site
            //- v-row
            //-     v-col(sm=5)
            //-         v-text-field.haq-search(v-model="searchQuery" append-icon="mdi-magnify" label="Filter Sites" dense filled hide-details single-line outlined)
            v-row(v-if="projects")
                v-col(md="6")
                    v-select.haq-text-field(v-model="projectId" :items="projects" item-text="name" item-value="objectId" label="Change Project" @change="changeProject" hide-details)
            v-row(v-if="sites")
                v-col
                    .page-content
                        .page-has-cards.sites(v-if="sites.length > 0")
                            SiteCard(
                                v-for="s in sites"
                                :projectId="projectId"
                                :siteId="s.objectId",
                                :name="s.name",
                                :shortName="s.shortName",
                                :address="s.address",
                                :activeStatus="s.isActive",
                                :logoUrl="s.logo ? s.logo.url : ''",
                                :zoneCount="s.zoneCount",
                                :kioskCount="s.kioskCount",
                                :alertConfigCount="s.alertConfigCount",
                                v-on:openEditModal="openEditModal"
                            )
                        .page-no-cards.no-sites(v-else)
                            p There are no sites for this project.
                            v-btn.haq-btn.haq-btn-secondary.btn-return(href="/projects") Return to Projects
                                //- <v-icon>mdi-keyboard-backspace</v-icon>
                            //- v-btn.haq-btn.haq-btn-primary.btn-add-site(plain @click="currentDialog = 'add'") <v-icon>mdi-plus</v-icon> Site
        ModalSiteEdit(v-if="currentDialog == 'edit'" :openModal="currentDialog" v-on:closeModal="closeModal"
            :site="editSite")
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import SiteCard from '@/components/SiteCard.vue';
import ModalSiteEdit from '@/components/site_crud/ModalSiteEdit.vue';
import {HTTPClient} from '../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ProjectSites',
    components: {
        Navbar,
        Breadcrumbs,
        SiteCard,
        ModalSiteEdit,
    },
    data() {
        return {
            projectId: this.$route.params.projectId,
            projects: null,
            project: null,
            sites: null,
            bcItems: [
                { text: 'Home', disabled: false, href: '/', },
                { text: 'Projects', disabled: false, href: '/projects', },
                { text: 'Sites', disabled: true, href: '', },
            ],
            currentDialog: null,
            editSite: null,
        }
    },
    methods: {
        closeModal(editedSite) {
            this.currentDialog = null;
            if(editedSite) {
                console.log(editedSite)
                this.editSite = editedSite
            }
		},
        openEditModal(siteId) {
            this.editSite = this.sites.find(s => s.objectId === siteId);
            this.currentDialog = 'edit';
        },
        changeProject(projectId) {
            this.$router.push({name: 'ProjectSites', params: {projectId}});
            this.fetchProject();
            this.fetchSites();
        },
        fetchProjects: async function(){
            try {
                let results = await client.robustQuery('/admins/projects');
                this.projects = results.projects;
            } catch (error) {
                console.error(error);
            }
        },
        fetchProject: async function(){
            try {
                let result = await client.robustQuery(`/admins/projects/${this.projectId}`);
                this.project = result.project;
            }
            catch(error) {
                console.error(error);
            }
        },
        fetchSites: async function(){
            try {
                let sites = [];
                let results = await client.robustQuery(`/admins/projects/${this.projectId}/sites`);
                if (results.sites.length > 0) {
                    results.sites.forEach(s => {
                        s.zoneCount = 0;
                        s.kioskCount = 0;
                        s.alertConfigCount = 0;
                        results.zones.forEach(z => {
                            if(z.site.objectId === s.objectId)
                                s.zoneCount++;
                        })
                        results.kiosks.forEach(k => {
                            if(k.site.objectId === s.objectId)
                                s.kioskCount++;
                        })
                        results.alertConfigs.forEach(ac => {
                            if(ac.site.objectId === s.objectId)
                                s.alertConfigCount++;
                        })
                        sites.push(s);
                    })
                }
                this.sites = sites;
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted() {
        this.fetchProjects();
        this.fetchProject();
        this.fetchSites();
    },
}
</script>