<template lang="pug">
  #homePage
    Navbar(tab="home")
    v-container
      //- v-row
      //-   v-col
      //-     Breadcrumbs(:items="bcItems")
      v-row
        v-col
          .welcome 
            h1 Hi{{firstName ? ' ' + firstName : ''}},
            p Welcome to your Portfolio Manager.
      //- v-row
      //-   v-col(col="12" sm="6" md="4")
      //-     v-card.small-card-link(href="/projects")
      //-       v-card-title Go to AQ
      //-       v-card-text Open the Air Quality Dashboard.
      //-       v-card-actions
      v-row
        v-col(col="12" sm="6" md="4")
          v-card.large-card-link(href="/projects")
            v-card-title Projects
            v-card-text Manage current projects
            v-card-actions
        //- v-col(col="12" sm="6" md="4")
        //-   v-card(href="/users")
        //-     v-card-title Users
        //-     v-card-text Manage all users
        //-     v-card-actions
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import {HTTPClient} from '../assets/js/http'

let client = new HTTPClient();

export default {
  name: 'Home',
  components: {
    Navbar,
    Breadcrumbs,
  },
  data(){
    return {
      bcItems: [
          { text: 'Home', disabled: true, href: '/', },
      ],
    }
  },
  methods: {
    getStats: async function(){
      try {
        let stats = await client.robustQuery('/admins/projects');
        console.log(stats);
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    // this.getStats();
    
  },
  computed: {
    firstName(){
      return this.$store.state?.user?.firstName
    },
  }
}
</script>
<style lang="scss">
</style>