import Vue from 'vue'
import Vuex from 'vuex'
import {HTTPClient} from '../assets/js/http';
let client = new HTTPClient();
import { wlData } from '../../../frontend/src/assets/js/constants';

Vue.use(Vuex)

function getCookieByName(name) {
  try {
   var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
   if(match){
     return decodeURIComponent(match[2])
   } else {
     return null;
   }
  } catch (error) {
    console.error(error);  
  }
}

export default new Vuex.Store({
  state: {
    logo: null,
    defaultLogo: true,
    project: null,
    projects: [],
    theme: window.User?.theme || 'dark',
    themes: window.Project?.themes || null,
    user: null,
    isSuperAdmin: false,
    usersForProject: {}
  },
  getters: {
    isLoggedIn(state){
      if(!state.token){
        // state.token = localStorage.getItem('airQualityAdminToken');
        state.token = getCookieByName('authToken');
      }
      return !!state.token
    },
    authStatus: state => state.status,
    getSite(state) {
      return state.site || null
    }
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token, user){
      state.status = 'success';
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = 'error';
    },
    setSite(state, site){
      state.site = site
    },
    logout(state) {
      state.status = null;
      state.token = null;
    },
    setProjects(state, projects){
      state.projects = projects
    },
    setProject(state, project){
      state.project = project;
    },
    setUser(state, user){
      state.user = user;
      if(user.role == "superAdmin")
        state.isSuperAdmin = true;
    },
    setUsersForProject(state, projectId, users){
        state.usersForProject[projectId] = users
    },
    setTheme(state,theme){
      state.theme = theme;
    },
    setLogo(state,logo){
      let theme = state.theme ? state.theme : 'dark';
      if(logo) {
        state.logo = logo;
        state.defaultLogo = false;
      }
      else {
        if(theme == 'light'){ // testing
            state.logo = wlData.lightCornerLogo||`/images/logo/light/hawken.png`;
        }else{
            state.logo = wlData.darkCornerLogo||`/images/logo/dark/hawken.png`;
        }
      }
    }
  },
  actions: {
    setTheme({commit}, theme) {
      commit('setTheme',theme);
      commit('setLogo');
    },
    setLogo({commit},logo) {
      commit('setLogo',logo)
    },
    async login({commit}, user) {
      try {
        let res = await client.robustPost('/users/login', user);
        localStorage.setItem('airQualityAdminToken', res.token)
        localStorage.setItem('user', JSON.stringify(res.user));
        commit('auth_success', res.token, res.user)
      } catch (error) {
        commit('auth_error')
        console.log(error);
        window.alert('Incorrect Username and Password');
        localStorage.removeItem('token');
        throw new Error(error);
      }
    },
    async logout({commit}) {
        try {
            window.location = "/logout";
        } catch (error) {
            commit('auth_error')
            throw new Error(error)
        }
    },
    async loadLocals({commit}){
      let res = await client.robustQuery('/locals');
      commit('setUser', res.user);
      let theme = res.user.theme ? res.user.theme : 'dark';
      commit('setTheme',theme);

      if (res.user.customer.themes) {
        if (theme === 'dark')
          commit('setLogo',res.user.customer.adminDarkLogo.url);
        else
          commit('setLogo',res.user.customer.adminLightLogo.url);
      }
      else {
        commit('setLogo');
      }

      if(Array.isArray(res.projects)){
        commit('setProjects', res.projects);
      }
      return res
    },

    localsThroughWindow({commit}){
      commit('setUser', window.User);
      let theme = window.User.theme ? window.User.theme : 'dark';
      commit('setTheme',theme);

      if (window.User.customer.themes) {
        if (theme === 'dark')
          commit('setLogo',window.User.customer.adminDarkLogo.url);
        else
          commit('setLogo',window.User.customer.adminLightLogo.url);
      }
      else {
        commit('setLogo');
      }
      if(Array.isArray(window.Projects)){
          commit('setProjects', window.Projects)
      }
    },
    async getUsersForProject({commit, state}, projectId){
        try {
            if(state.usersForProject[projectId] == null){
                let result = await client.robustQuery(`/admins/projects/${projectId}/users`);
                commit('setUsersForProject', result.users)
                console.log(result)
                return result.users
            } else {
                return state.usersForProject[projectId]
            }
        } catch (error) {
            console.error(error)
        }
    }
  },
  modules: {
  }
});