import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import Projects from '../views/Projects.vue'
import ProjectSites from '../views/ProjectSites.vue'
import ProjectUsers from '../views/ProjectUsers.vue'
import ProjectGroups from '../views/ProjectGroups.vue'
import SiteZones from '../views/SiteZones.vue'
import SiteAlertConfigs from '../views/SiteAlertConfigs.vue'
import Kiosks from '../views/Kiosks.vue'
import Widgets from '../views/Widgets.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: {
      path: '/projects'
    },
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sites',
    name: 'ProjectSites',
    component: ProjectSites,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/users',
    name: 'ProjectUsers',
    component: ProjectUsers,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/groups',
    name: 'ProjectGroups',
    component: ProjectGroups,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sites/:siteId/zones',
    name: 'SiteZones',
    component: SiteZones,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sites/:siteId/kiosks',
    name: 'Kiosks',
    component: Kiosks,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sites/:siteId/alertconfigs',
    name: 'SiteAlertConfigs',
    component: SiteAlertConfigs,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sites/:siteId/widgets',
    name: 'Widgets',
    component: Widgets,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sites/:siteId/floorplans',
    name: "Floorplans",
    component: () => import(/* webpackChunkName: "about" */ '../views/floorplans.vue'),
    meta: {
        requiresAuth: true
    }
  }
  // {
  //   path: '/project/:id',
  //   name: 'Project',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Project.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
    } else {
      let subdomain = location.host.split('.')[0];
      let domain = location.host.split('.')[1];
      let beta = subdomain.indexOf("beta") > -1 ? true : false;
      location = beta ? `https://beta.${domain}.com/login?from=https://betaadmin.${domain}.com/` : `https://app.${domain}.com/login?from=https://admin.${domain}.com/`;
    }
  } else {
    next() 
  }
})

export default router
