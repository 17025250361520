/*eslint no-undef: "warn"*/

<template lang="pug">
	#kiosk-wrapper(v-bind:class="{small: s}")
		#kiosk(v-if="site" v-bind:class="{small: s}" :style="$store.state.theme == 'dark' ? 'background-color:var(--bg-color)' : 'background-color:var(--bg-color3)'")
			#k-branded(style="background-color:var(--bg-color2)" v-if="$store.state.themes")
				.row.top
					.col.m12
						.logo(:class="{'logo-center':(logo1center === true)}")
							img.responsive-img(v-if="$store.state.logo", :src="$store.state.logo" style="height:100px")
						.time {{ timestamp }}
				.row.middle
					.col.m12
						.brand-measurement-highlight.carousel
							.carousel-slide(v-if="!preview" v-for="(i,slideindex) in carouselMeasurements", :class="'slide-count-' + carouselMeasurements.length",:style="getSlideAnimationDelay(slideindex)")
								.highlight-name(style="color:var(--text-color-1)" v-bind:class="{small: s}") {{ getKioskDis(i) }}
									.highlight-subname(v-if="zone" style="color:var(--text-color-1)") {{ zone.name }}
								.highlight-curScore(v-bind:class="{small: s}")
									KioskDonutIndicator(:id="siteId",:curVal="Math.round(i.curScore)",:units="'0'",:donutcolor="donutcolor",:textcolor="$store.state.themes.txt1")
								.highlight-progress(:style="{color:donutcolor}" v-bind:class="{small: s}") {{ getIndicatorText(i) }}
							.carousel-slide(v-if="preview" v-for="(i,slideindex) in previewCarouselMeasurements", :class="'slide-count-' + carouselMeasurements.length",:style="getSlideAnimationDelay(slideindex)")
								.highlight-name(v-if="!i.dummy" style="color:var(--text-color-1)" v-bind:class="{small: s}") {{ getKioskDis(i) }}
									.highlight-subname(v-if="zone" style="color:var(--text-color-1)") {{ zone.name }}
								.highlight-curScore(v-bind:class="{small: s}")
									KioskDonutIndicator(v-if="!i.dummy" :id="siteId",:curVal="Math.round(i.curScore)",:units="'0'",:donutcolor="donutcolor",:textcolor="$store.state.themes.txt1")
								.highlight-progress(v-if="!i.dummy" :style="{color:donutcolor}" v-bind:class="{small: s}") {{ getIndicatorText(i) }}
			#k-branded(:style="{backgroundColor: bgcolor, color: textcolor}"  v-if="!$store.state.themes")
				.row.top
					.col.m12
						.logo(:class="{'logo-center':(logo1center === true)}")
							img.responsive-img(v-if="brandingLogo1", :src="brandingLogo1.url.replace('http','https')")
						.time {{ timestamp }}
				.row.middle
					.col.m12
						.brand-measurement-highlight.carousel
							.carousel-slide(v-if="!preview" v-for="(i,slideindex) in carouselMeasurements", :class="'slide-count-' + carouselMeasurements.length",:style="getSlideAnimationDelay(slideindex)")
								.highlight-name(v-bind:class="{small: s}") {{ getKioskDis(i) }}
									.highlight-subname(v-if="zone" style="color:var(--text-color-1)") {{ zone.name }}
								.highlight-curScore(v-bind:class="{small: s}")
									KioskDonutIndicator(:id="siteId",:curVal="Math.round(i.curScore)",:units="'0'",:donutcolor="donutcolor",:textcolor="textcolor")
								.highlight-progress(:style="{color:donutcolor}" v-bind:class="{small: s}") {{getIndicatorText(i)}}
							.carousel-slide(v-if="preview" v-for="(i,slideindex) in previewCarouselMeasurements", :class="'slide-count-' + previewCarouselMeasurements.length",:style="getSlideAnimationDelay(slideindex)")
								.highlight-name(v-bind:class="{small: s}") {{ getKioskDis(i) }}
									.highlight-subname(v-if="zone" style="color:var(--text-color-1)") {{ zone.name }}
								.highlight-curScore(v-bind:class="{small: s}" style="cursor:pointer" @click="addMeasurement = true;overallMeasurement=true")
									KioskDonutIndicator(v-if="!i.dummy" :id="siteId",:curVal="Math.round(i.curScore)",:units="'0'",:donutcolor="donutcolor",:textcolor="textcolor")
								.highlight-progress(v-if="!i.dummy" :style="{color:donutcolor}" v-bind:class="{small: s}") {{getIndicatorText(i)}}
								v-btn.haq-btn(width="330" height="" style="position:relative;top:45%;transform:scale(2)" @click="addMeasurement = true;overallMeasurement=true") Select Measurement
				.row.bottom
					.col.m12
						.logo(:class="{'logo-center':(logo2center === true)}")
							img.responsive-img(v-if="brandingLogo2", :src="brandingLogo2.url.replace('http','https')")
			#k-cards(:style="$store.state.theme == 'dark' ? 'background-color:var(--bg-color)' : 'background-color:var(--bg-color3)'")
				.k-card(v-for="(i,index) in componentMeasurements", :class="'card-' + getKioskKind(i.type)" v-if="!preview")
					.k-card-measurement(:class="getKioskKind(i.type)")
						.k-card-measurement-icon
							MeasurementIcons(:measurement="getKioskKind(i.type)",:iconcolor="iconcolor",:size="40")
						.k-card-measurement-curVal {{getDisplayVal(i)}}
							span {{i.unit}}
						.k-card-measurement-name(v-bind:class="{small: s}") {{i.name}}
				.k-card(v-for="(i,index) in previewComponentMeasurements", :class="" v-if="preview" @click="addMeasurement = true; currIndex = index;overallMeasurement=false")
					.k-card-measurement()
						.k-card-measurement-icon
							MeasurementIcons(v-if="!i.dummy" :measurement="getKioskKind(i.type)",:iconcolor="iconcolor",:size="40")
						.k-card-measurement-curVal(v-if="!i.dummy") {{getDisplayVal(i)}}
							span {{i.unit}}
						.k-card-measurement-name(v-if="i.dummy" v-bind:class="{small: s}" style="text-align:center") {{i.name}}
						.k-card-measurement-name(v-else v-bind:class="{small: s}") {{i.name}}
		KioskMeasurementSelector(:measurementDialog="addMeasurement" :overallMeasurement="overallMeasurement" v-on:close="closeModal" v-on:setMeasurement="setKioskSlot")
</template>

<script>
import { HTTPClient } from "../assets/js/http";
import KioskDonutIndicator from '@/components/KioskDonutIndicator.vue'
import MeasurementIcons from '@/components/MeasurementIcons.vue';
import KioskMeasurementSelector from '@/components/kiosk_crud/KioskMeasurementSelector';
let client = new HTTPClient();
var $ = require('jquery')
export default {
	name: "KioskBranded",
	components: {
		KioskDonutIndicator,
		MeasurementIcons,
		KioskMeasurementSelector
	},
	props: {
		site: Object,
		kioskUUID: String,
		siteObjectId: String,
		zoneObjectId: String,
		s: Boolean,
		preview: Boolean,
		config: Object,
	},
	data() {
		return {
			siteId: this.$route.query.siteId || this.siteObjectId || this.$route.params.siteId,
			zoneId: this.$route.query.zoneId || this.zoneObjectId,
			carouselMeasurements: [],
			previewCarouselMeasurements: [{dummy: true, name: 'Click to Select a Measurement', curScore: 90}],
			styles: "",
			componentMeasurements: [],
			previewComponentMeasurements: [
				{dummy: true, name: 'Select Measurement'},
				{dummy: true, name: 'Select Measurement'},
				{dummy: true, name: 'Select Measurement'}],
			timestamp:'',
			kioskId: this.$route.params.id || this.kioskUUID,
			zone: null,
			bgcolor: this.site?.project.branding.bgcolor || '#FFFFFF',
			donutcolor: this.site?.project.branding.donutcolor || '#02C39A',
			textcolor: this.site?.project.branding.textcolor || '#1D213C',
			logo1center: this.site?.project.branding.logo1center,
			logo2center: this.site?.project.branding.logo2center,
			iconcolor: this.site?.project.branding.iconcolor,
			brandingLogo1: this.site?.brandingLogo1 || this.site?.project.brandingLogo1,
			brandingLogo2: this.site?.brandingLogo2 || this.site?.project.brandingLogo2,
			addMeasurement: false,
			currIndex: 0,
			overallMeasurement: false,
		}
	},
	methods: {
		setKioskSlot(m) {
			let scores = ['scoreaq', 'scoreow'];
			let components = ['spacetemperature','relativehumidity', 'co2', 'pm2.5', 'infectiontransmissibility', 'timeincomfortzone','ionlevel',
			'ionizerstatus', 'ionizationtime', 'pm10', 'carbonmonoxide', 'ozone', 'scoreaf', 'scoreaq', 'scoreow','equipment'];
			console.log('M', m)
			if(scores.includes(m.type.toLowerCase()) && m.curScore > 0 && this.carouselMeasurements.length < 4) {
				this.previewCarouselMeasurements = [m];
			} else {
			for(let c of components){
				if(this.componentMeasurements.length >= 3){
					break;
				}
				if(m.type.toLowerCase() == c){
					this.previewComponentMeasurements[this.currIndex] = m;
				}
			}
			}
		},
		closeModal() {
			this.addMeasurement = false;
		},
		getCurrentTime() {
			const today = new Date();
			this.timestamp = today.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
		},
		getSlideAnimationDelay(index) {
			let delay = ['0s','6s','12s','18s']
			return 'animation-delay:' + delay[index].toString() + ';';
		},
		getKioskKind(kind) {
			let k = kind.toLowerCase();
			if(k.includes('airquality') || k.includes('aq') || k.includes('scoreow'))
				return 'airquality';
			else {
				return k.toLowerCase();
			}
		},
		getDisplayVal(m){
			if (m.dummy) {
				return m.name
			}
			if(m.type.includes("score")){
				return m.curScore;
			}
			if(!isNaN(Number(m.curVal))){
				return Math.round(m.curVal)
			} else {
				return m.curVal
			}
		},
		async getMeasurements(){
			try{
				let measurementRes;
				if(this.zoneId!= null && this.zoneId!= ""){
					let zoneRes = await client.robustQuery('/api/zone/public/' + this.zoneId);
					this.zone = zoneRes;
				}
				if(this.site || this.config?.measurements.length){
					measurementRes = await client.robustQuery(`/api/kiosk/${this.kioskId}/measurements`);
					console.log('HERE', measurementRes)
					return measurementRes;
				} else {
					measurementRes  = await client.robustQuery('/api/measurements', {siteId: this.siteId, zoneId: this.zoneId});
					return measurementRes;
				}
			} catch (error) {
				console.error(error);
				//setTimeout(function() {
					//window.location.href = '/login'
				//}, 250);
			}
		},
		resizeToScreen(id){
			try{
				let that = this;
				let el = $('#'+id)[0];
				let iw = window.innerWidth;
				let ih = window.innerHeight;
				if(iw > window.outerWidth || ih > window.outerHeight){
					iw = window.outerWidth;
					ih = window.outerHeight;
				}
				let sr = iw / ih;
				let er = el.clientWidth / el.clientHeight; 
				// use the relative aspect ratio to determine which dimension to resize by
				if(sr < er){
					// use width
					let b = iw / el.clientWidth;
					let c = Math.floor(b*100)/100;
					let d = Math.round(el.clientWidth*(1-c)*50)/100;
					let dd = Math.round(el.clientHeight*(1-c)*50)/100;
					if(c < 1){
						$(el).css('transform', 'translate(-'+d+'px, -'+dd+'px) scale('+c+')');
					}else if(c > 1){
						dd = Math.round(el.clientHeight*(c-1)*50)/100;
						$(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					}else{
						$(el).css('transform', 'scale('+c+')');
					}
				}else{
					// use height
					let b = ih / el.clientHeight;
					let c = Math.floor(b*100)/100;
					// let dd = Math.round(el.clientHeight*(c-1)*50)/100;
					// $(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					let d = Math.round(el.clientWidth*(1-c)*50)/100;
					let dd = Math.round(el.clientHeight*(1-c)*50)/100;
					let ddd = Math.round(Math.abs(iw-el.clientWidth*c)*50)/100;
					if(c < 1){
						$(el).css('transform', 'translate(-'+(d-ddd)+'px, -'+dd+'px) scale('+c+')');
					}else if(c > 1){
						dd = Math.round(el.clientHeight*(c-1)*50)/100;
						$(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					}else{
						$(el).css('transform', 'scale('+c+')');
					}
				}
				if(!window['resizelistener'+id]){
					window['resizelistener'+id] = true;
					// window.addEventListener('resize', () => {
					// 	// detect window rotation and refresh
					// 	// console.log('resize');
					// 	that.resizeToScreen(id);
					// });
					let lastOrient = null;
					window.addEventListener('orientationchange', function(e){
						that.resizeToScreen(id);
						if(e.target.screen.orientation.angle == 0)
							window.location.reload();
						if(Math.abs(e.target.screen.orientation.angle)==90)
							window.location.reload();
						if(lastOrient != null){
							// console.log('lastOrient', lastOrient);
							// console.log('orientation', e.target.screen.orientation.angle);
							if(Math.abs(lastOrient) == 90 && Math.abs(e.target.screen.orientation.angle) != 90)
								window.location.reload();
							if(Math.abs(lastOrient) != 90 && Math.abs(e.target.screen.orientation.angle) == 90)
								window.location.reload();
						}
						lastOrient = e.target.screen.orientation.angle;
					});
				}
			}catch(e){
				console.error(e);
			}
		},
		async loadSite() {
			try {
				console.log('Ip', this.$route.params.siteId)
				console.log(this.$router.params)
				let scores = ['scoreaq', 'scoreow'];
				let components = ['spacetemperature','relativehumidity', 'co2', 'pm2.5', 'infectiontransmissibility', 'timeincomfortzone','ionlevel',
				'ionizerstatus', 'ionizationtime', 'pm10', 'carbonmonoxide', 'ozone', 'scoreaf', 'scoreaq', 'scoreow','equipment'];
				let measurementsRes = {results:[]};
				if (!this.preview) {
					measurementsRes = await this.getMeasurements();
				}
				
				if(!this.site){
					let siteRes = await client.robustQuery('/api/site', {siteId: this.siteId});
					this.site = siteRes.site;
					this.bgcolor = this.site?.project.branding?.bgcolor || '#FFFFFF';
					this.donutcolor= this.site?.project.branding?.donutcolor || '#02C39A';
					this.textcolor= this.site?.project.branding?.textcolor || '#1D213C';
					this.logo1center= this.site?.project.branding?.logo1center;
					this.logo2center= this.site?.project.branding?.logo2center;
					this.iconcolor= this.site?.project.branding?.iconcolor;
					this.brandingLogo1= this.site?.brandingLogo1 || this.site?.project.brandingLogo1;
					this.brandingLogo2= this.site?.brandingLogo2 || this.site?.project.brandingLogo2;
				}
				
				measurementsRes.results.forEach(m => {
					if(scores.includes(m.type.toLowerCase()) && m.curScore > 0 && this.carouselMeasurements.length < 4)
						this.carouselMeasurements.push(m);
					// if(components.includes(m.type.toLowerCase()) && m.curVal && this.componentMeasurements.length < 3)
					// 	this.componentMeasurements.push(m);
				});
				for(let c of components){
					for(let m of measurementsRes.results){
						if(this.componentMeasurements.length >= 3){
							break;
						}
						if(m.type.toLowerCase() == c){
							this.componentMeasurements.push(m);
						}
						
					}
				}
			} catch (error) {
				console.error(error);
				window.M.toast({html: 'Cannot Load Site'})
			} finally {
				setTimeout(() => {window.M.AutoInit();}, 100);
			}
		},
		applyStyling(){
			if(this.$store.themes){
				this.styles=`background-color:var(--bg-color1);color:var(--text-color-1)`
			} else {
				this.styles=`background-color:${this.site.project.branding.bgcolor};color:${this.site.project.branding.textcolor};`
			}
		}
	},
	mounted() {
		console.log('this.stite', this.site);
		this.loadSite();
	},
	created(){
		setInterval(this.getCurrentTime, 1000);
	},
	updated(){
		this.resizeToScreen('kiosk');
	},
	computed: {
		getKioskDis(){
			let scoreNames = {
				scoreAQ: "Current Air Quality",
				scoreOW: "Occupant Comfort",
				scoreOA: "Overall"
			}
			return(m) => {
				if(this.zone){
					// return `${this.zone.name} (${scoreNames[m.type]})`;
					return scoreNames[m.type];
				} else {
					return scoreNames[m.type];
					// return m.name;
				}
			}
		},
		getIndicatorText(){
			return (i) => {
				// span(v-if="i.curScore >= 75") Excellent
				// span(v-else-if="i.curScore < 75 && i.curScore >= 50") Good
				// span(v-else) Poor
				if(i.curScore >= 75){
					return "Excellent"
				} else if(i.curScore < 75 ** i.curScore >= 50){
					return "Good"
				} else {
					return "Poor"
				}
			}
		}
	}
};
</script>

<style lang="scss">
	.embed_documentation_footer--documentationFooter--2gpG4 {
		display: none;
	}
</style>
<style lang="scss" scoped>
	$airquality: #2EFCFA;
	$co2: #FFCA3A;
	$humidity: #9655FF;
	$pollutant: #02C39A;
	$temperature: #4E7CFF;
	$ventilation: #7C90FF;
	@keyframes kf1 {
		0%,100% { opacity: 1; }
	}
	@keyframes kf2 {
		50% { opacity: 1; }
		80% { opacity: 0; }
	}
	@keyframes kf3 {
		33% { opacity: 1; }
		53% { opacity: 0; }
	}
	@keyframes kf4 {
		25% { opacity: 1; }
		45% { opacity: 0; }
	}
	#kiosk-wrapper {
		width: 100vw;
		background-color:#000;
		&.small {
			height: 345px;
			width: unset;
			background-color: unset;
		}
	}
	#kiosk,
	#k-branded,
	#k-cards {
		background-color: #1d2039;
		height: 100vh;
		max-height: 840px;
		min-height: 840px;
	}
	#k-branded,
	#k-cards {
		float: left;
	}
	#kiosk {
		background: #1D2039;
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		margin: 0 auto;
		max-width: 1280px;
		min-height: 840px;
		min-width: 1280px;
		width: 100%;
		-webkit-font-smoothing: antialiased;
		overflow: hidden;
		.row {
			margin-bottom: 0;
		}
		&.small {
			position: relative;
			top: -205px;
			left: -335px;
			transform: scale(.45) !important;
			margin-top: 70px;
			border-radius: 40px;
		}
	}
	#k-branded {
		position: relative;
		width: 67.89%;
		.top {
			height: 15%;
			.logo {
				padding-top: 10px;
				text-align: left;
				img {
					max-height: 100px;
				}
				&.logo-center {
					text-align: center;
				}
			}
			.time {
				font-size: 1.6rem;
				opacity: .7;
				position: absolute;
				top: 48px;
				right: 48px;
			}
		}
		.bottom {
			height: 10%;
			.logo {
				text-align: left;
				img {
					max-height: 70px;
				}
				&.logo-center {
					text-align: center;
				}
			}
		}
		.middle {
			height: 75%;
			.carousel {
				min-height: 623px;
				min-width: 850px;
				.carousel-slide {
					left: 0;
					top: 0;
					opacity: 0;
					position: absolute;
					text-align: center;
					width: 100%;
					height: 100%;
			
					.highlight-name {
						font-size: 4em;
						font-weight: 300;
						line-height: 162.3px;
						&.small {
							position: relative;
							top: 14%
						}
					}
					.highlight-subname {
						position: absolute;
						font-size: 1.65rem;
						line-height: 1em;
						top: 0;
						width: 100%;
					}
					.highlight-curScore{
						.progressIndicator {
							margin: 0 auto;
							fill: grey;
						}
						&.small {
							position: relative;
							top: 14%
						}
					}
					.highlight-progress {
						font-size: 4em;
						line-height: 162.3px;
						opacity: .7;
						&.small {
							position: relative;
							top: 14%
						}
					}
					// transitions
					&.slide-count-1 { animation: kf1 6s infinite; }
					&.slide-count-2 { animation: kf2 12s infinite; }
					&.slide-count-3 { animation: kf3 18s infinite; }
					&.slide-count-4 { animation: kf4 24s infinite; }
				}
			}
		}
	}
	#k-cards {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		font-weight: 400;
		padding: 48px;
		width: 32.11%;
		.k-card {
			flex: 1 100%;
			max-height: 258px;
			max-width: 315px;
			&:last-child {
				max-height: 226px;
				margin-bottom: 0;
			}
			.k-card-measurement {
				cursor: pointer;
				background: #25294A;
				border-radius: 20px;
				min-width: 315px;
				min-height: 226px;
				padding: 45px 32px 32px;
				box-shadow: 0px 100px 80px rgba(2, 4, 12, 0.12), 0px 41.7776px 33.4221px rgba(2, 4, 12, 0.0862625), 0px 22.3363px 17.869px rgba(2, 4, 12, 0.0715329), 0px 12.5216px 10.0172px rgba(2, 4, 12, 0.06), 0px 6.6501px 5.32008px rgba(2, 4, 12, 0.0484671), 0px 2.76726px 2.21381px rgba(2, 4, 12, 0.0337375);
				&.spacetemperature { background: var(--spt2); }
				&.relativehumidity { background: var(--rh2) }
				&.airquality { background: var(--airquality2) }
				&.ventilation { background: var(--vt2); }
				&.co2 { background: var(--co2); }
				&.pollutant { background: var(--plt2); }
				&.pm10 { background: var(--plt2); }
				&.timeincomfortzone {background: var(--rh2)}
				&.infectiontransmissibility {background: var(--plt2)}
				&.equipment {background: var(--spt2)}
				
				.k-card-measurement-progress {
					color: #E8912B;
					font-size: 1.2rem;
					line-height: 2rem;
					text-align: right;
					&.progress-excellent { color: #02C39A; }
				}
				.k-card-measurement-icon {
					height: 40px;
					width: 40px;
					margin-bottom: 18px;
				}
				.k-card-measurement-curVal {
					font-size: 3.2rem;
					font-weight: 300;
					line-height: 3.3rem;
					span {
						color: rgba(#fff,.7);
						font-size: 1.2rem;
						margin-left: 5px;
					}
				}
				.k-card-measurement-name {
					font-size: 1.65rem;
					line-height: 2rem;
					&.small {
						font-size: 1.29rem;
					}
				}
			}
		}
	}
</style>