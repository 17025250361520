<template lang="pug">
    #manageUsers.haq-table-page
        Navbar(tab="")
        v-container
            v-row 
                v-col
                    Breadcrumbs(:items="bcItems")
            v-row
                v-col(md="8")
                    .page-header
                        .page-title
                            h1 Users for {{project ? project.name : ''}}
                        .page-actions
                            
                v-col(md="4")
                    v-select(v-model="projectId" :items="$store.state.projects" item-text="name" item-value="objectId" label="Change Project" @change="changeProject" hide-details dense outlined)
            v-row(v-if="currentProjectUsers")
                v-col
                    .page-content
                        .page-has-table(v-if="currentProjectUsers.length > 0")
                                v-data-table.elevation-8.haq-data-table(
                                    v-model="selectedRemoveUser" 
                                    show-select 
                                    item-key="objectId" 
                                    :search="searchEditUsers" 
                                    :headers="dtEditUsersHeaders" 
                                    :items="dtEditUsers" 
                                    :items-per-page="10")
                                    template(v-slot:top)
                                        .edit-users-actions
                                            v-row
                                                v-col(sm=5)
                                                    v-text-field.haq-search(v-model="searchEditUsers" append-icon="mdi-magnify" label="Search Users" dense hide-details single-line filled outlined)
                                                v-col(sm=7 align="end")
                                                    v-btn.haq-btn.haq-btn-primary.btn-add-user(plain @click="currentDialog='add'") <v-icon>mdi-plus</v-icon> User
                                                    v-btn.haq-btn.haq-btn-primary.btn-add-user(v-show="selectedRemoveUser.length > 0" @click="currentDialog='remove'" plain)
                                                        v-icon(color="warning") mdi-delete
                                                        | Remove {{ selectedRemoveUser.length > 1 ? 'Users' : 'User' }}
                                    template(v-slot:item.profilePhoto="{ item }")
                                        .img-wrap
                                            img.avatar(v-if="item.profilePhoto" :src="item.profilePhoto.url.replace('http://','https://')")
                                    template(v-slot:item.email="{ item }") {{ item.email }} 
                                        v-icon(v-if="item.emailVerified" small color="success") mdi-check-decagram
                                    template(v-slot:item.theme="{item}")
                                        v-icon.theme-icon(v-if="item.theme === 'dark'") mdi-moon-waxing-crescent
                                        v-icon.theme-icon(v-if="item.theme === 'light'") mdi-white-balance-sunny
                                    template(v-slot:item.edit="{ item }")
                                        v-icon(small class="mr-2" @click="openEditModal(item)") mdi-dots-vertical
                        .page-no-table(v-else)
                            p There are no users for this project.
                            v-btn.haq-btn.haq-btn-secondary.btn-return(href="/projects") Return to Projects
                            v-btn.haq-btn.haq-btn-primary.btn-add-user(plain @click="currentDialog='add'") <v-icon>mdi-plus</v-icon> User
        ModalProjectUserAdd(v-if="currentDialog == 'add'" :openModal="currentDialog" v-on:closeModal="closeModal" 
            :projectId="projectId"
            :project="project"
            :allProjectsUsers="allProjectsUsers" 
            :currentProjectUsers="currentProjectUsers")
        ModalProjectUserEdit(v-if="currentDialog == 'edit'" :openModal="currentDialog" v-on:closeModal="closeModal"
            :projectId="projectId"
            :editUserCurrentPhoto="editUserCurrentPhoto"
            :editedUser="editedUser"
            :editedIndex="editedIndex"
            :groups="currentProjectGroups"
            :dtEditUsers="dtEditUsers")
        ModalProjectUserRemove(v-if="currentDialog == 'remove'" :openModal="currentDialog" v-on:closeModal="closeModal"
            :projectId="projectId"
            :project="project"
            :selectedRemoveUser="selectedRemoveUser")
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import ProjectUsersTable from '@/components/ProjectUsersTable.vue'
import ModalProjectUserAdd from '@/components/project_crud/ModalProjectUserAdd'
import ModalProjectUserEdit from '@/components/project_crud/ModalProjectUserEdit'
import ModalProjectUserRemove from '@/components/project_crud/ModalProjectUserRemove'
import {HTTPClient} from '../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ProjectUsers',
    components: {
        Navbar,
        Breadcrumbs,
        ProjectUsersTable,
        ModalProjectUserAdd,
        ModalProjectUserEdit,
        ModalProjectUserRemove,
    },
    data() {
        return {
            projectId: this.$route.params.projectId,
            project: null,
            allProjectsUsers: null,
            currentProjectUsers: null,
            currentProjectGroups: null,
            timer: null,
            bcItems: [
                { text: 'Home', disabled: false, href: '/', },
                { text: 'Projects', disabled: false, href: '/projects', },
                { text: 'Users', disabled: true, href: '', },
            ],
            currentDialog: null,
            // EDIT
            dtEditUsersHeaders:[],
            dtEditUsers:[],
            searchEditUsers: '',
            editedIndex: -1,
            editUserCurrentPhoto: null,
            editedUser: {
                objectId: '',
                profilePhoto: '',
                profilePhotoB65: '',
                firstName: '',
                lastName: '',
                role: '',
                email: '',
                theme: '',
                groups: []
            },
            // REMOVE
            selectedRemoveUser: [],
        }
    },
    methods: {
        closeModal() {
			this.currentDialog = null;
            this.resetUsers();
		},
        changeProject(projectId) {
            this.$router.push({name: 'ProjectUsers', params: {projectId}});
            this.fetchProject();
            this.fetchUsers();
        },
        openEditModal(user) {
            this.currentDialog = 'edit';
            this.editUserCurrentPhoto = user.profilePhoto;
            this.editedIndex = this.dtEditUsers.indexOf(user)
            this.editedUser = Object.assign({}, user)
        },
        closeEditModal() {
            this.closeModal();
            this.$nextTick(() => {
                this.editedUser = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            });
        },
        buildUsersTable(users) {
            this.dtEditUsers = [];
            users.forEach(u => {
                let user = {
                    objectId: u.objectId,
                    profilePhoto: u.profilePhoto,
                    firstName: u.firstName,
                    lastName: u.lastName,
                    role: u.role,
                    email: u.username,
                    emailVerified: u.emailVerified,
                    theme: u.theme,
                    projects: u.projects,
                    phone: u.phone,
                    edit: "",
                    groups: (u.groups) ? u.groups.map(g => g.objectId) : [],
                };
                this.dtEditUsers.push(user);
            });
            // manually setting the headers. might refine the api call to only get these fields then make this dynamic.
            this.dtEditUsersHeaders = [
                { text: '', value: 'profilePhoto', align: 'center', sortable: false, width: 1, },
                { text: 'First Name', value: 'firstName', align: 'start', },
                { text: 'Last Name', value: 'lastName', align: 'start', },
                { text: 'Role', value: 'role', },
                { text: 'Email', value: 'email', },
                { text: 'Phone', value: 'phone', },
                { text: 'Theme', value: 'theme', align: 'center', sortable: false, },
                { text: '', value: 'edit', align: 'right', sortable: false, width: 1, },
            ];
        },
        resetUsers() {
            this.fetchUsers();
            this.selectedRemoveUser = [];
        },
        fetchProject: async function(){
            try {
                let result = await client.robustQuery(`/admins/projects/${this.projectId}`);
                this.project = result.project;
            }
            catch(error) {
                console.error(error);
            }
        },
        fetchUsers: async function(){
            try {
                let allProjectsUsersResult = await client.robustQuery(`/admins/projects`);
                let currentProjectUsersResults = await client.robustQuery(`/admins/projects/${this.projectId}/users`);
                let currentProjectGroups = await client.robustQuery(`/api/projects/${this.projectId}/groups`);
                
                this.buildUsersTable(currentProjectUsersResults.users);
                this.allProjectsUsers = allProjectsUsersResult.users;
                this.currentProjectUsers = currentProjectUsersResults.users;
                this.currentProjectGroups = currentProjectGroups.results;
            } catch (error) {
                console.error(error);
            }
        },
    },
    mounted() {
        this.fetchProject();
        this.fetchUsers();
    },
    watch: {
		// eslint-disable-next-line no-unused-vars
		$route(to, from) {
            this.fetchProject();
            this.fetchUsers();
		},
	},
}
</script>
<style lang="scss" scoped>
.controls {
    display: flex;
    align-items: center;
}
.edit-users-actions {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 15px;
    // gap: 35px;
    .v-input--selection-controls {
        margin: 0;
        padding: 0;
    }
}
.theme-icon {
    font-size: 16px;
}
</style>