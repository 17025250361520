<template lang="pug">
    v-dialog(srollable v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card(v-if="stillAddingUsers")
            v-card-title
                span(class="text-h5") Add User
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-container
                    v-row
                        //- v-combobox.white-text(label="Share with email" placeholder="Email address" multiple chips dark v-model="chips" :clearable="true")
                    v-row.with-border
                        v-col.text-h5(cols="12") Invite a New User
                        v-col(cols="12" md="6")
                            v-text-field.haq-text-field(v-model.trim="newUser.firstName" label="First Name" hide-details) 
                        v-col(cols="12" md="6")
                            v-text-field.haq-text-field(v-model.trim="newUser.lastName" label="Last Name" hide-details)
                        v-col(cols="12")
                            v-text-field.haq-text-field(v-model.trim="newUser.email" label="Email" :rules="[rules.email]" validate-on-blur)
                    v-row
                        v-col.text-h5(cols="12") Add Existing User(s)
                            v-data-table.elevation-4.haq-data-table(
                                v-model="selectedAddUser" 
                                show-select 
                                item-key="objectId" 
                                :search="searchAddUsers" 
                                :headers="dtAddUsersHeaders" 
                                :items="dtAddUsers" 
                                :items-per-page="5" 
                                @toggle-select-all="selectAllToggle")
                                template(v-slot:top)
                                    .add-users-actions
                                        v-text-field(v-model="searchAddUsers" append-icon="mdi-magnify" label="Search Users" single-line hide-details outlined filled dense).haq-search
                                template(v-slot:item.photo="{ item }")
                                    .img-wrap
                                        img.avatar(v-if="item.photo" :src="item.photo")
                                template(v-slot:item.data-table-select="{ item, isSelected, select }")
                                    v-simple-checkbox(:value="isSelected" :readonly="item.disabled" :disabled="item.disabled" :ripple="false" @input="select($event)")
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveAddUser") Save
        v-card.haq-dialog-card(v-else)
            v-card-title
                span(class="text-h5") Users Added
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                .message
                    .users-added(v-if="usersAdded.length > 0") The following users have been added to this project and notified via email.
                        ul
                            li.added-user(v-for="user in usersAdded") {{user.firstName}} {{user.lastname}} ({{user.email}})
                    .users-already-added(v-if="usersAlreadyAdded.length > 0") The following users have already been added to this project.
                        ul
                            li.already-added-user(v-for="user in usersAlreadyAdded") {{user.firstName}} {{user.lastname}} ({{user.email ? user.email : user.username}})
                    .users-not-added(v-if="usersNotAdded.length > 0") Failed to add the following users to the project. Please try again.
                        ul
                            li.not-added-user(v-for="user in usersNotAdded") {{user.firstName}} {{user.lastname}} ({{user.email}})
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Ok
</template>
<script>
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalProjectUserAdd',
    props: {
        projectId: String,
        project: Object,
        allProjectsUsers: Array,
        currentProjectUsers: Array,
		openModal: String,
	},
    data() {
        return {
            dialog: this.openModal == 'add' ? true : false,
            searchAddUsers: '',
            addUserDisabledCount: 0,
            selectedAddUser: [],
            dtAddUsersHeaders:[],
            dtAddUsers:[],
            rules: {
                // required: value => !!value || 'Required.',
                email: value => this.validateEmail(value) || 'Invalid e-mail.',
            },
            newUser: {
                firstName: null,
                lastName: null,
                email: null,
            },
            newUserEmailValid: null,
            allUsers: [],
            // Statuses
            stillAddingUsers: true,
            doneMessage: '',
            usersAdded: [],
            usersAlreadyAdded: [],
            usersNotAdded: [],
        }
    },
    methods: {
        validateEmail(email) {
            if(email) {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.newUserEmailValid = true;
                return pattern.test(email);
            }
            return true;            
        },
        closeModal() {
            this.dialog = false;
			this.$emit("closeModal")
		},
        selectAllToggle(props) {
            if(this.selectedAddUser.length != this.dtAddUsers.length - this.disabledCount) {
                this.selectedAddUser = [];
                props.items.forEach(item => {
                    if(!item.disabled) {
                        this.selectedAddUser.push(item);
                    } 
                });
            } else this.selectedAddUser = [];
        },
        buildAddUsersTable(allProjectsUsers,currentProjectUsers) {
            allProjectsUsers.forEach(u => {
                let disabled = false;
                if(currentProjectUsers.some(pu => pu.objectId === u.objectId))
                    disabled = true;
                let user = {
                    objectId: u.objectId,
                    photo: u.profilePhoto ? u.profilePhoto.url.replace('http://','https://') : '',
                    firstName: u.firstName,
                    lastName: u.lastName,
                    email: u.username,
                    projects: u.projects,
                    disabled: disabled,
                };
                this.dtAddUsers.push(user);
            });
            this.dtAddUsersHeaders = [
                { text: '', value: 'photo', align: 'center', sortable: false, width: 1, },
                { text: 'First Name', value: 'firstName', align: 'start', },
                { text: 'Last Name', value: 'lastName', align: 'start', },
                { text: 'Email', value: 'email', },
            ];
        },
        async saveAddUser() {
            try{
                if(this.newUser.email && this.newUserEmailValid) {
                    let results = await client.robustQuery('/users/verify',{email: this.newUser.email});
                    if(!results.user) {
                        await this.addNewUser(this.newUser, this.project);
                    }
                    else {
                        this.selectedAddUser.push(results.user);
                        await this.addExistingUser(this.selectedAddUser);
                    }
                } else {
                    await this.addExistingUser(this.selectedAddUser);
                }
                this.stillAddingUsers = false;
                // this.displayConfirmation();
            }catch(e){
                console.error(e);
                window.M.toast({html: 'Failed to invite user: '+e.message});
            }
        },
        async addNewUser(user, project) {
            try{
                let result = await client.robustPost(`/api/users`,{user: user, projectId: project.objectId});
                if (result.successMessage) {
                    this.inviteUser(user, true);
                    this.usersAdded.push(user);
                }
                else {
                    this.updateComplete = true;
                    this.usersNotAdded.push(user);
                }
            }catch(e){
                console.error(e);
                throw e;
            }
        },
        async addExistingUser(users) {
            for (let i = 0; i < users.length; i++) {
                if (users[i].projects.indexOf(this.projectId) === -1) {
                    users[i].projects.push(this.projectId);
                
                    let result = await client.robustPost(`/api/users/${users[i].objectId}`,users[i]);
                    if (result.successMessage) {
                        this.inviteUser(users[i], false);
                        this.usersAdded.push(users[i]);
                    }
                    else {
                        this.isSuccess = false;
                        this.usersNotAdded.push(users[i]);
                    }
                }
                else {
                    this.usersAlreadyAdded.push(users[i]);
                }
            }
        },
        async inviteUser(user, isNewUser) {
            try{
                await client.robustPost('/users/invite', {user: user, project: this.project, isNewUser: isNewUser});
                // console.log('Email delivered',result);
            }catch(error){
                console.error("Failed to invite user", error);
                // window.M.toast({html: 'Failed to invite user: '+error.message});
            }
        },
    },
    created() {
        this.dtAddUsers.map(item => {if (item.disabled) this.addUserDisabledCount += 1})
    },
    mounted() {
        if(this.allProjectsUsers && this.currentProjectUsers)
            this.buildAddUsersTable(this.allProjectsUsers,this.currentProjectUsers)
    },
}
</script>
<style lang="scss">
    .add-users-actions {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 15px;
        gap: 35px;
        .v-input--selection-controls {
            margin: 0;
            padding: 0;
        }
    }
</style>