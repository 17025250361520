<template lang="pug">
    v-dialog(v-model="dialogRemoveUser" max-width="792px" scrollable)
        v-card.update-user-dialog-card
            v-card-title
                span(class="text-h5") Remove User(s)
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-container
                    v-row
                        v-col(cols="12") Remove the following user(s) from {{project ? project.name : 'this project'}}?
                            //- .user(v-for="u,index in selectedRemoveUser") {{u.email}}
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveRemoveUsers") Confirm
</template>
<script>
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalRemoveProjectUser',
    props: {
        projectId: String,
        selectedRemoveUser: Object
    },
    data() {},
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit('closeModal')
		},
        saveRemoveUsers() {
            console.log("deleting...",this.selectedRemoveUser)
            if(this.selectedRemoveUser.length > 0) {
                this.selectedRemoveUser.forEach(u => {
                    if (u.projects.indexOf(this.projectId) > -1)
                        u.projects.pop(this.projectId);
                    client.robustPost(`/api/users/${u.objectId}`,u).then(function(result){
                        console.log('User Updated - Removed from project',result);
                    }).catch(function(error){
                        console.log("User Not Updated", error);
                        // Update dialog to show error message
                    })
                })
            }
            this.closeModal = false;
        }
    },
}
</script>