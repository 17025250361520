<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card.project-user-edit-dialog-card
            v-card-title
                span(class="text-h5") Edit User
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-container
                    v-row.personal-info.with-border
                        v-col(cols="12").title Personal Info
                        v-col(cols="12" sm="3" md="3").edit-photo
                            button.haq-avatar-wrap(@click="changePhoto")
                                img.haq-avatar(v-if="newPhoto" :src="newPhoto")
                                img.haq-avatar(v-else-if="editUserCurrentPhoto" :src="editUserCurrentPhoto.url.replace('http://','https://')")
                                img.haq-avatar(v-else src="/images/ssprofile.png")
                            v-btn.haq-btn.haq-btn-secondary(@click="changePhoto") {{ defaultButtonText }}
                            input(ref="uploader" class="d-none" type="file" accept="image/*" @change="renderFile")
                        v-col(cols="12" sm="9" md="9").edit-personal-info
                            v-col(sm="12")
                                v-text-field(v-model="editedUser.firstName" label="First Name" hide-details dense filled outlined)
                            v-col(sm="12")
                                v-text-field(v-model="editedUser.lastName" label="Last Name" hide-details dense filled outlined)
                            v-col(sm="12").email-wrp
                                v-text-field(v-model="editedUser.email" label="Email" type="email" hide-details dense filled outlined)
                            v-col(sm="12").email-wrp
                                v-text-field(v-model="editedUser.phone" label="Phone" hide-details dense filled outlined)
                    v-row.permissions.with-border
                        v-col(cols="12").title Permissions
                        v-col(cols="12" sm="12" md="6")
                            v-select(v-model="editedUser.role" :items="roles" item-text="t" item-value="v" label="Role" hide-details dense filled outlined)
                    v-row.groups
                        v-col(cols="12").title Groups
                        v-col(cols="12" sm="12" md="6")
                            v-autocomplete(v-model="editedUser.groups" :items="groups" item-text="name" item-value="objectId" label="Groups" multiple chips deletable-chips hide-details filled outlined)
                    //- v-row.theme
                    //-     v-col(cols="12").title Theme
                    //-     v-col(cols="12" sm="6" md="4")
                    //-         v-switch(v-model="editedUser.theme === 'light'" inset)
                    //-         v-radio-group(v-model="editedUser.theme")
                    //-             v-radio(v-for="t in themes" :key="v" :label="t" :value="v")
                                
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveEditUser") Save
</template>
<script>
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalProjectUserEdit',
    props: {
        projectId: String,
        project: Object,
        editUserCurrentPhoto: Object,
        groups: Array,
        editedUser: Object,
        editedIndex: Number,
        dtEditUsers: Array,
        openModal: String,
    },
    data() {
        return {
            dialog: this.openModal == 'edit' ? true : false,
            roles: [
                { v: 'admin', t: 'Admin' },
                { v: 'user', t: 'User' },
            ],
            themes: [
                { v: '', t: 'Default' },
                { v: 'dark', t: 'Dark' },
                { v: 'light', t: 'Light' },
            ],
            profilePhotoBase64: null,
            selectedFile: null,
            defaultButtonText: this.editUserCurrentPhoto ? 'Change Image' : 'Upload Image',
            newPhoto: null,
        }
    },
    computed: {
        buttonText() {
            return this.selectedFile ? this.selectedFile.name : this.defaultButtonText;
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit('closeModal');
            // this.$emit('closeEditModal',this.editedUser)
		},
        changePhoto() {
            this.$refs.uploader.click();
        },
        async saveEditUser() {
            if (this.editedIndex > -1) {
                try {
                    if(this.profilePhotoBase64 == null){
                        console.log("No PP update");
                        client.robustPost(`/api/users/${this.editedUser.objectId}`,this.editedUser).then(function(result){
                            console.log('User Updated',result);
                        }).catch(function(error){
                            console.log("User Not Updated", error);
                        })
                    } else {
                        console.log("Yes PP update");
                        let clone = JSON.parse(JSON.stringify(this.editedUser));
                        delete clone.profilePhoto
                        clone.profilePhoto = this.profilePhotoBase64;

                        client.robustPost(`/api/users/${this.editedUser.objectId}`,clone).then(function(result){
                            console.log('User Updated',result);
                        }).catch(function(error){
                            console.log("User Not Updated", error);
                        })
                    }
                    Object.assign(this.dtEditUsers[this.editedIndex], this.editedUser);
                }
                catch(error) {
                    console.error(error);
                }
            } else {
                this.dtEditUsers.push(this.editedUser)
            }
            this.closeModal();
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        async renderFile(e){
            try {
                this.selectedFile = this.editedUser.profilePhoto = e.target.files[0];
                let file = this.editedUser.profilePhoto;
                let base64 = await this.getBase64(file);
                this.profilePhotoBase64 = {type: file.type, base64, name: file.name};
                this.newPhoto = URL.createObjectURL(file);
                return true;
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted() {
        console.log(this.editedUser.profilePhoto)
    }
}
</script>
<style lang="scss">
.project-user-edit-dialog-card {
    .edit-photo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .haq-avatar-wrap {
            margin-bottom: 10px;
            border-radius: 75px;
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            overflow: hidden;
            .haq-avatar {
                min-width:100%;
                max-width: 120px;
            }
        }
    }
    .name-wrp {
        display: flex
    }
}
</style>