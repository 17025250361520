<template lang="pug">
    v-breadcrumbs(:items="items")
        template(v-slot:divider)
            v-icon mdi-chevron-right
</template>
<script>
export default {
  name: "Breadcrumbs",
  props: {
    items: Array,
  },
};
</script>
