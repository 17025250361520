<template lang="pug">
	v-dialog(v-model="dialog" max-width="792px" persistent)
		v-card.haq-dialog-card
			v-card-title
				span(class="text-h5") {{addDialog == "add" ? 'Create Widget' : 'Edit Widget'}} 
				v-spacer
				v-icon.close(text @click="close") mdi-close
			v-card-text
				v-container
					v-row
						v-container(style="background-color:rgb(255 255 255 / 20%); border-radius: 8px;padding:41px;min-height:148px")
							v-progress-circular(v-if="!loaded" indeterminate)
							h2(v-if="!measurement" style="text-align:center") Please click "Select Measurement" to get started. 
							#widgetPreview
							//-Widget(:measurement="measurement" :size="widgetSize" :color="widgetColor" :gradient="gradientBackground" :iconMode="iconMode" defaultScore="Excellent")
					v-row.mt-6
						v-col
							v-select.haq-text-field(v-model="widgetSize" :items="widgetSizes" item-text="text" item-value="value" label="Size Preview" @change="setupPreview(false)") Preview Size
						v-col

							v-checkbox(:disabled="widgetSize == 'medium' || widgetSize == 'large'" label="Icon Mode Preview" v-model="iconMode" @change="setupPreview(false)")
					v-row
						v-col
							v-select.haq-text-field(v-model="widgetColor" :items="widgetColors" item-text="text" item-value="value" label="Color Preview" @change="setupPreview(false)") Preview Size
						v-col
							v-checkbox(label="Gradient Background Preview" v-model="gradientBackground" @change="setupPreview(false)")
			v-card-actions
				v-spacer
				v-btn.haq-btn.haq-btn-secondary(text @click="close") Cancel
				v-btn.haq-btn.haq-btn-primary(light text @click="addMeasurement = true") Select Measurement 
				v-btn.haq-btn.haq-btn-primary(:disabled='!measurement' light text @click="saveWidget") Save
		WidgetMeasurementSelector(:measurementDialog="addMeasurement" :widgetUUID="widgetUUID" v-on:close="closeModal" v-on:setWidget="setWidget")

</template>
<style lang="scss" scoped>
.haq-dialog-card {
	overflow-x: hidden;
	#widgetPreview {
		position: relative;
		left: 25%;
	}
}
</style>
<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Widget from '@/components/Widget.vue'
import WidgetMeasurementSelector from '@/components/widget_crud/WidgetMeasurementSelector.vue'
import {HTTPClient} from '../../assets/js/http';

let client = new HTTPClient();
export default {
    name: 'ModalWidgetAdd',
    components: {
        Navbar,
        Breadcrumbs,
		Widget,
		WidgetMeasurementSelector
    },
	props: {
		addDialog: String,
		widgetPreview: Object,
	},
    data() {
        return {
			dialog: this.addDialog == 'add' || this.addDialog == 'edit' ? true : false,
			widgetSizes: [
				{text: 'Mini', value: 'mini'},
				{text: 'Small', value: 'small'},
				{text: 'Medium', value: 'medium'},
				{text: 'Large', value: 'large'},
			],
			widgetColors: [
				{text: 'Light', value: 'light'},
				{text: 'Light 2', value: 'light-2'},
				{text: 'Blue', value: 'blue'},
				{text: 'Dark', value: 'dark'},
			],
			widgetSize: this.addDialog == 'edit' && this.widgetPreview.defaultSize ? this.widgetPreview.defaultSize : 'large',
			widgetColor: this.addDialog == 'edit' && this.widgetPreview.defaultColor ? this.widgetPreview.defaultColor :  'blue',
			gradientBackground: this.addDialog == 'edit' && this.widgetPreview.defaultGradient != undefined ? this.widgetPreview.defaultGradient :  true,
			iconMode: this.addDialog == 'edit' && this.widgetPreview.defaultIconMode != undefined ? this.widgetPreview.defaultIconMode :  false,
			addMeasurement: false,
			measurement: this.widgetPreview.measurements[0] || null,
			loaded: false,
			widgetUUID: this.widgetPreview.uuid,
            siteId: this.$route.params.siteId,
        }
    },
    methods: {
		close() {
			this.dialog = false;
			this.$emit('closeAddModal')
		},
		closeModal() {
			this.addMeasurement = false;
		},
		async saveWidget() {
			if (this.addDialog == 'add') {
				await client.robustPost(`/api/widget`, {
					measurements: this.measurement.objectId,
					name: this.measurement.name,
					site: this.siteId,
					defaultSize: this.widgetSize,
					defaultColor: this.widgetColor,
					defaultIconMode: this.iconMode,
					defaultGradient: this.gradientBackground,
				})
			} else {
				await client.robustPost(`/api/widgets/${this.widgetPreview.uuid}`, {
					measurements: this.measurement.objectId,
					name: this.measurement.name,
					site: this.siteId,
					defaultSize: this.widgetSize,
					defaultColor: this.widgetColor,
					defaultIconMode: this.iconMode,
					defaultGradient: this.gradientBackground,
				})
			}
			this.close();
			this.$emit('reloadWidgets')
		},
		setWidget(m) {
			this.measurement = m;
			this.setupPreview(false)
		},
		async setupPreview(empty) {
			this.loaded = false;
			if (!empty) {
				let myNode = document.getElementById('widgetPreview')
				myNode.innerHTML = '';
			}
			let AQ = new window.IAQ("4e98a96cce429be1b423");
			let dom = document.querySelector('#widgetPreview');
			if (this.widgetPreview.measurements.length || this.measurement) {
				await AQ.generate(dom, this.widgetPreview.objectId, {theme: this.widgetColor, size: this.widgetSize, iconMode: this.iconMode, bgGradient: this.gradientBackground})
			}
			this.loaded = true;
		}
	},
	mounted() {
		console.log('mounted')
		this.setupPreview(true)
	},
	watch: {
		'$props.addDialog': {
			handler() {
				this.dialog = this.addDialog == 'add' || this.addDialog == 'edit' ? true : false;
				if (!this.dialog) {
					this.close();
				}
			}
		},
	}
}
</script>