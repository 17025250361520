<template lang="pug">
	#manageWidgets.haq-card-page
		Navbar(tab="")
		v-container
			v-row
				v-col
					Breadcrumbs(:items="bcItems")
			v-row   
				v-col
					.page-header
						.page-title
							h1 Widgets ({{widgets ? widgets.length : ''}})
						.page-actions
							v-btn.haq-btn.haq-btn-primary.btn-add-user(plain @click="currentDialog='add'") <v-icon>mdi-plus</v-icon> Widget 
			v-row
				v-col(md="4" v-if="sites")
					v-select.haq-text-field(v-model="siteId" :items="sites" item-text="name" item-value="objectId" label="Change Site" @change="changeSite" hide-details)
			
			v-row(v-if="widgets")
				v-col
					.page-content
						.page-has-cards#test(v-show="widgets.length > 0")
							div(v-for="w in widgets" :key="w.objectId" )
								v-row.mt-4
									.widget-title.ml-6 {{w.name}}
									v-spacer
									v-icon.mr-3(@click="editWidget=w;currentDialog='share'") mdi-share
									v-icon.mr-3(@click="currentDialog = 'edit'; editWidget = w") mdi-pencil
								v-col.mt-2.mb-2(:id="w.objectId" :ref="w.objectId")
							//-WidgetCard(v-for="w,index in widgets" :widget="w" ref="widgets" :key="index" :index="index" :users="users" v-on:sendAction="activateAction")
						.page-no-cards(v-show="widgets.length == 0")
							p There are no currently no widgets for this site.
							v-btn.haq-btn.haq-btn-secondary.btn-return(:href="'/projects/' + this.$route.params.projectId + '/sites'") Return to Sites
			ModalWidgetAdd(v-if="currentDialog == 'add' || currentDialog == 'edit'"
				:addDialog="currentDialog"
				:widgetPreview="currentDialog == 'add' ? widgetPreview : editWidget"
				v-on:closeAddModal="closeModal"
				v-on:reloadWidgets="getWidgets"
			)
			ModalWidgetShare(v-if="currentDialog == 'share'"
				:shareDialog="currentDialog"
				:widgetPreview="editWidget"
				:sites="sites"
				v-on:closeAddModal="closeModal"
				v-on:reloadWidgets="getWidgets"
			)
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import {HTTPClient} from '../assets/js/http';
import ModalWidgetAdd from '@/components/widget_crud/ModalWidgetAdd.vue'
import ModalWidgetShare from '@/components/widget_crud/ModalWidgetShare.vue'

let client = new HTTPClient();
export default {
    name: 'Widgets',
    components: {
        Navbar,
        Breadcrumbs,
		ModalWidgetAdd,
		ModalWidgetShare
    },
    data() {
        return {
			currentDialog: null,
            projectId: this.$route.params.projectId,
            siteId: this.$route.params.siteId,
            project: null,
            timer: null,
            sites: [],
			widgets: [],
			widgetSizes: [
				{text: 'Mini', value: 'mini'},
				{text: 'Small', value: 'small'},
				{text: 'Medium', value: 'medium'},
				{text: 'Large', value: 'large'},
			],
			widgetColors: [
				{text: 'Light', value: 'light'},
				{text: 'Light 2', value: 'light-2'},
				{text: 'Blue', value: 'blue'},
				{text: 'Dark', value: 'dark'},
			],
			widgetSize: 'large',
			widgetColor: 'blue',
			gradientBackground: false,
			iconMode: false,
			widgetPreview: {},
			editWidget: {},
            bcItems: [
                { text: 'Home', disabled: false, href: '/', },
                { text: 'Projects', disabled: false, href: '/projects', },
                { text: 'Sites', disabled: false, href: '/projects/' + this.$route.params.projectId + '/sites', },
                { text: 'Widgets', disabled: true, href: '', },
            ],
			AQ : null,
			items: [{title: 'Edit', action: 'edit'},]
        }
    },
	methods: {
		changeSite(siteId) {
            let projectId = this.projectId;
            this.$router.push({name: 'Widgets', params: {projectId, siteId}});
            this.getWidgets();
        },
		activateAction(options) {
			if (options) {
				this.currAction = options.action;
				this.currKiosk = options.kiosk;
				this.currKioskIndex = options.index;
			}
		},
		closeModal() {
			this.currentDialog = null
		},
		async getWidgets() {
			try {
				this.AQ = new window.IAQ(this.sites.find(x => x.objectId == this.$route.params.siteId).clientKey)
                this.widgets = [];
				let widgets = await client.robustQuery(`/api/widgets/site/${this.siteId}`);
				console.log('widgets', widgets)
				this.widgetPreview = widgets.results[widgets.results.findIndex(x => x.preview === true)]
				// If no preview create one
				if (!this.widgetPreview) {
					let results = await client.robustPost(`/api/widget`, {
						measurements: [],
						name: '',
						site: this.siteId,
						defaultSize: this.widgetSize,
						defaultColor: this.widgetColor,
						defaultIconMode: this.iconMode,
						defaultGradient: this.gradientBackground,
						preview: true
					});
					this.widgetPreview = results.results
				}
				this.widgets = widgets.results.filter(x => !x.preview);
				this.generateWidgets()
			} catch (e) {
				console.error(e)
			}
		},
		async generateWidgets() {
			this.$nextTick(function() {
				for (let w in this.widgets) {
					let dom = document.getElementById(this.widgets[w].objectId);
					dom.innerHTML = '';
					this.AQ.generate(dom, this.widgets[w].objectId, {
						theme: this.widgets[w].defaultColor || this.widgetColor,
						size: this.widgets[w].defaultSize || this.widgetSize,
						iconMode: this.widgets[w].defaultIconMode || this.iconMode,
						bgGradient: this.widgets[w].defaultGradient || this.gradientBackground
					})
				}
			});
		},
		async getProjectUsers() {
			let projectUsersResults = await client.robustQuery(`/api/admin/projects/${this.$route.params.projectId}/users`);
			this.users = projectUsersResults.users;
		},
		fetchSites: async function() {
			let results = await client.robustQuery(`/admins/projects/${this.projectId}/sites`);
			this.sites = results.sites;
			for(let s of this.sites) {
				for (let k of results.clientKeys) {
					if (s.objectId == k.site.objectId) {
						s['clientKey'] = k.key;
					}
				}
			}
			await this.getWidgets();
		},
	},
	mounted() {
		this.fetchSites();
		this.getProjectUsers();
	}
}
</script>
<style lang="scss" scoped>
</style>