<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card
            v-card-title
                span(class="text-h5") Remove {{multipleGroups ? 'Groups' : 'Group' }}
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-row
                    v-col(cols="12") Remove the following {{multipleGroups ? 'groups' : 'group' }} from {{project ? project.name : 'this project'}}?
                        ul
                            li(v-for="u,index in selectedRemoveGroup")
                                .group {{u.name}}
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveRemoveGroups") Confirm
</template>
<script>
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalProjectGroupRemove',
    props: {
        projectId: String,
        project: Object,
        selectedRemoveGroup: Array,
        openModal: String,
    },
    data() {
        return {
            multipleGroups: this.selectedRemoveGroup.length > 1 ? true : false,
            view: 0,
            dialog: this.openModal == 'remove' ? true : false,
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit('closeModal')
		},
        saveRemoveGroups() {
            console.log(this.selectedRemoveGroup);
            if(this.selectedRemoveGroup.length > 0) {
                this.selectedRemoveGroup.forEach(u => {
                    client.robustDelete(`/api/projects/${this.projectId}/groups/${u.objectId}`,u).then(function(result){
                        console.log('Groups Updated - Removed from project',result.successMessage);
                    }).catch(function(error){
                        console.log("Groups Not Updated", error.errorMessage);
                    })
                })
            }
            this.closeModal();
        }
    }
}
</script>