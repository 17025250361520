<template lang="pug">
	v-dialog(v-model="dialog" max-width="792px" persistent)
		v-card(color="var(--app-secondary-bg")
			v-card-title
				span(class="text-h5") Create Kiosk 
				v-spacer
				v-icon.close(text @click="close") mdi-close
			v-card-text
				v-container
					v-row.mt-6
						v-col
							v-select.haq-text-field(v-model="kioskType" :items="kioskTypes" item-text="text" item-value="value" label="Kiosk Type" @change="updateKiosk()") Kiosk Type 
					v-row
						v-container(style="linear-gradient(139.8deg, var(--card-primary-bg) 23.95%, var(--card-secondary-bg) 109.29%);border-radius: 8px;padding:41px;min-height:148px")
							v-progress-circular(v-if="!loaded" indeterminate)
							KioskPublic(:kiosk="{type: kioskType}" :preview="true" :shareKiosk="true" ref="kioskPreview" v-on:selectMeasurement="selectMeasurement")
					
			v-card-actions
				v-spacer
				v-btn.haq-btn.haq-btn-secondary(text @click="close") Cancel
				//-v-btn.haq-btn.haq-btn-primary(light text @click="addMeasurement = true") Select Measurement 
				v-btn.haq-btn.haq-btn-primary(light text @click="saveKiosk") Save
		//-MeasurementSelector(:measurementDialog="addMeasurement" v-on:close="closeModal" v-on:setKioskSlot="setKioskSlot")

</template>
<style lang="scss" scoped>
.haq-dialog-card {
	overflow-x: hidden;
	#widgetPreview {
		position: relative;
		left: 25%;
	}
}
</style>
<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import KioskPublic from '@/components/KioskPublic.vue'
//import Widget from '@/components/Widget.vue'
import {HTTPClient} from '../../assets/js/http';
let client = new HTTPClient();
export default {
    name: 'ModalKioskAdd',
    components: {
        Navbar,
        Breadcrumbs,
		KioskPublic,
//		Widget,
    },
	props: {
		addDialog: String,
	},
    data() {
        return {
			dialog: this.addDialog == 'add' ? true : false,
			kioskTypes: [
				{text: 'Branded', value: 'branded'},
				{text: 'Unbranded', value: 'unbranded'},
			],
			widgetColors: [
				{text: 'Light', value: 'light'},
				{text: 'Light 2', value: 'light-2'},
				{text: 'Blue', value: 'blue'},
				{text: 'Dark', value: 'dark'},
			],
			kioskType: 'branded',
			widgetColor: 'light',
			gradientBackground: false,
			iconMode: false,
			addMeasurement: false,
			measurement: null,
			loaded: true,
            siteId: this.$route.params.siteId,
        }
    },
    methods: {
		close() {
			this.dialog = false;
			this.$emit('closeAddModal')
		},
		closeModal() {
			this.addMeasurement = false;
		},
		async saveKiosk() {
			if (this.kioskType == 'branded') {
				console.log(this.$refs['kioskPreview'].$refs['kiosk-branded'])
				let carouselMeasurements = this.$refs['kioskPreview'].$refs['kiosk-branded'].previewCarouselMeasurements.map(x => x.uuid);
				let componentMeasurements = this.$refs['kioskPreview'].$refs['kiosk-branded'].previewComponentMeasurements.map(x => x.uuid);
				let measurements = [...carouselMeasurements, ...componentMeasurements]
				let result = await client.robustPost(`/api/kiosk`, {
					measurements,
					site: this.siteId,
					type: 'branded'
				});
				this.zones = result.results;
				console.log('measurements', measurements)
			} else {
				console.log(this.$refs['kioskPreview'].$refs['kiosk'])
				let measurements = this.$refs['kioskPreview'].$refs['kiosk'].previewMeasurements.map(x => x.uuid);
				let result = await client.robustPost(`/api/kiosk`, {
					measurements,
					site: this.siteId,
				});
				this.zones = result.results;
				console.log('measurements', measurements)
			}
			this.close();
		},
		setKiosk(m) {
			this.measurement = m;
			this.setupPreview(false)
		},
		updateKiosk() {
			
		},
		selectMeasurement(index) {
			console.log('modal index', index)
			this.addMeasurement = true;
		},
		setKioskSlot(index) {
			console.log('kiosk modal index', index)
		}
	},
	mounted() {
		console.log('mounted')
	},
	watch: {
		'$props.addDialog': {
			handler() {
				this.dialog = this.addDialog == 'add' ? true : false;
				if (!this.dialog) {
					this.close();
				}
			}
		},
	}
}
</script>