<template lang="pug">
v-dialog(v-model="value" content-class="multiMeasurementSelectorModal" scrollable)
    v-card.haq-dialog-card
        v-card-title
            v-row
                v-col(:cols="12" :sm="12") Select Measurement
                v-col(:cols="12" :md="5")
                    v-text-field(v-model="searchZoneQuery" append-icon="mdi-magnify" label="Search Zones" dense hide-details outlined ref="searchBar2")
                v-col(:cols="12" :md="5" :offset-md="1")
                    v-text-field(v-model="searchQuery" append-icon="mdi-magnify" label="Search Measurements" dense hide-details outlined ref="searchBar")
        v-card-text(v-if="alertableMeasurements == null").d-flex.justify-center
            v-progress-circular(indeterminate color="primary")
        v-card-text(v-else)
            .optionsDiv
                v-col(:cols="12" :md="6" :sm="12" ).zoneDiv
                    .option
                        v-subheader.pa-0.mx-0.ml-4 Filter by Zone
                    v-list.zoneList(hide-details)
                        v-list-item-group(v-model="selected")
                            v-list-item(:value="siteId")
                                v-list-item-icon
                                    v-icon mdi-domain
                                v-list-item-content
                                    v-list-item-title Site
                            v-list-item(v-for="z in zones" :key="z.id" :value="z.id" v-show="showThisZone(z)")
                                v-list-item-icon
                                    v-icon mdi-home-group
                                v-list-item-content
                                    v-list-item-title {{z.name}}
                v-col(:cols="12" :md="6" :sm="12").measurementDiv
                    .option
                        v-subheader.pa-0.mx-0.ml-4 Measurements
                    v-list(v-if="alertableMeasurements.length > 0" hover hide-details).measurementList
                        v-list-item(v-for="m,index in alertableMeasurements" :key="m.objectId" v-show="showThisMeasurement(m, getDisplayName(m))" :data-measurement-id="m.objectId" :data-measurement-index="index").my-0
                            v-list-item-content(:data-list-item-index="index" hide-details).ma-0.py-0
                                v-checkbox(v-model="m.selected" hide-details :label="getDisplayName(m)")
        v-card-actions
            v-spacer
            div.mt-2
                v-btn.haq-btn.haq-btn-secondary(@click="$emit('input', false)") Cancel
                v-btn.haq-btn.haq-btn-primary(@click="addMeasurements") Confirm
</template>

<script>
import {HTTPClient} from "../../assets/js/http";
let client = new HTTPClient();
export default {
    name: "MultiMeasurementSelector",
    props: {
        value: Boolean,
        siteId: String,
        preSelectedMeasurements: Array
    },
    data(){
        return {
            zones: [],
            measurementForTopology: [],
            alertableMeasurements: null,
            selected: this.siteId,
            searchQuery: "",
            searchZoneQuery: "",
            selectedMeasurements: {}
        }
    },
    methods: {
        async loadAlertableMeasurements(){
            try {
                let measurements = [];
                let res = await client.robustQuery(`/admins/sites/${this.siteId}/alertableMeasurements`);
                measurements = measurements.concat(res.results.site.measurements)
                // console.log(measurements.map((el) => el.objectId))
                for(let z in res.results.zones){
                    let zone = res.results.zones[z];
                    this.zones.push({
                        name: zone.name,
                        id: z,
                    })
                    // console.log('Concating measurement of ' + z);
                    // console.log(zone.measurements.map((el) => el.objectId))
                    measurements = measurements.concat(zone.measurements)
                }
                measurements.forEach((el) => {
                    this.preSelectedMeasurements.includes(el.objectId) ? el.selected = true : el.selected = false
                })
                this.alertableMeasurements = measurements;
                // console.log(measurements.map((el) => el.objectId))
                // console.log(this.alertableMeasurements)
            } catch (error) {
                console.error(error);
            }
        },
        // loadMeasurementForTopology(){
        //     this.measurementForTopology = [];
        //     if(this.selected == this.siteId){
        //         this.alertableMeasurements.site.measurements.forEach((el) => {
        //             this.measurementForTopology.push({
        //                 name: el.name,
        //                 id: el.objectId,
        //                 unit: el.unit ? `(${el.unit})` : "",
        //                 type: el.type,
        //                 selected: this.preSelectedMeasurements.includes(el.id)
        //             })
        //         })
        //     } else {
        //         this.alertableMeasurements.zones[this.selected]?.measurements.forEach((el) => {
        //             this.measurementForTopology.push({
        //                 name: el.name,
        //                 id: el.objectId,
        //                 unit: el.unit ? `(${el.unit})` : "",
        //                 type: el.type,
        //                 selected: this.preSelectedMeasurements.includes(el.id)
        //             })
        //         })
        //     }
        // }
        addMeasurements(){
            let selectedMeasurements = this.alertableMeasurements.filter((el) => el.selected);
            this.$emit("addMeasurements", selectedMeasurements);
        }
    },
    mounted(){
        this.loadAlertableMeasurements()
    },
    computed: {
        getDisplayName(){
            return (m) => {
                if(m.type == "scoreAQ"){
                    return "Air Quality Score"
                } else if(m.type == "scoreEQ"){
                    return "Equipment Score"
                } else if(m.type == "scoreOW"){
                    return "Occupant Wellness Score"
                } else if(m.type == "scoreOA"){
                    return "Overall Score"
                } else if(m.type == "scoreAF"){
                    return "Air Freshness Score"
                } else {
                    return m.name + " " + (m.unit ? `(${m.unit})` : "")
                }
            }
        },
        showThisMeasurement(){
           return (m, dn) => {
               let regex = new RegExp(this.searchQuery, "i")
                if(this.selected == this.siteId){
                    return m.zone == null && (regex.test(m.name) || regex.test(m.type) || regex.test(m.unit) || regex.test(dn))
                } 
                else {
                    return m.zone?.objectId === this.selected && (regex.test(m.name) || regex.test(m.type) || regex.test(m.unit) || regex.test(dn))
                }
           }
        },
        showThisZone(){
            return (z) => {
                let regex = new RegExp(this.searchZoneQuery, "i")
                return regex.test(z.name)
            }
        }
    },
}
</script>
<style lang="sass" scoped>

</style>