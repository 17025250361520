<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card
            v-card-title
                span(class="text-h5") Add Alert Configuration
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-container
                    v-row(v-if="errorMessage")
                        v-col.error {{ errorMessage }}
                    v-row
                        v-col(cols="12" sm="12" md="12") Message <span class="required">*</span>
                            v-text-field.haq-text-field(v-model="newAlertConfig.message" :rules="msgRules" counter)
                    //- v-row
                    //-     v-col(cols="5" sm="12" md="5") Measurement Kind <span class="required">*</span>
                    //-     v-col(cols="7" sm="12" md="7")
                    //-         v-select.haq-text-field(v-model="newAlertConfig.kind" :items="measurementKinds" item-text="t" item-value="v" label="" hide-details)
                    v-row.align-center
                        v-col(cols="5" sm="12" md="5") Alert Type <span class="required">*</span>
                        v-col(cols="7" sm="12" md="7")
                            v-select.haq-text-field(v-model="newAlertConfig.alertType" :items="alertTypes" item-text="t" item-value="v" label="" hide-details)
                    v-row.align-center
                        v-col(cols="5" sm="12" md="5") Measurements <span class="required">*</span>
                        v-col(cols="7" sm="12" md="7")
                            .measurement-chips(v-if="selectedMeasurements.length > 0")
                                v-chip(v-for="item in selectedMeasurements" :key="item.objectId").ma-1
                                    span {{getMeasurementChipName(item)}}
                            v-btn.haq-btn.haq-btn-secondary.measurement-chips-btn(@click="selectMeasurementDialog = true") Select Measurements
                    v-row.align-center
                        v-col(cols="5" sm="12" md="5") Notify All Users <span class="required">*</span>
                        v-col(cols="7" sm="12" md="7").d-flex.align-center
                            v-checkbox(v-model="notifyAllUsers" hide-details).pa-0.ma-0
                    v-row.align-center(v-show="!notifyAllUsers")
                        v-col(cols="5" sm="12" md="5") Users To Notify <span class="required">*</span>
                        v-col(cols="7" sm="12" md="7")
                            ProjectUsersAutoComplete(:users="selectedUsers" v-on:modifyAlertUsers="modifyAlertUsers")
                    v-row
                        v-col(cols="5" sm="12" md="5") Notification Channels
                        v-col(cols="7" sm="12" md="7")
                            v-select.haq-text-field(v-model="newAlertConfig.channels" 
                                                        :items="channels" 
                                                        :menu-props="{ maxHeight: '400' }"
                                                        item-text="t" 
                                                        item-value="v" 
                                                        label=""
                                                        chips
                                                        hide-details 
                                                        multiple)
                    v-row
                        v-col(cols="5" sm="12" md="5") Condition <span class="required">*</span>
                        v-col(cols="7" sm="12" md="7" )
                            v-select.haq-text-field(v-model="configKey" :items="configTypes" item-text="t" item-value="v" label="" hide-details)
                            v-text-field.haq-text-field(v-if="configTypeStr.includes(configKey)" v-model="configStrVal"  label="" hide-details)
                            v-text-field.haq-text-field(v-else-if="configTypeNum.includes(configKey)" v-model="configNumVal" label="" type="number" hide-details)
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="addNewAlertConfig") Save
        v-dialog(v-model="selectMeasurementDialog" content-class="multiMeasurementSelectorModal" scrollable)
            MultiMeasurementSelector(:siteId="siteId" v-if="selectMeasurementDialog" :preSelectedMeasurements="selectedMeasurementIds" v-on:addMeasurements="addMeasurements" v-on:closeModal="selectMeasurementDialog=false")

</template>
<script>
import MultiMeasurementSelector from './MultiMeasurementSelector.vue'
import ProjectUsersAutoComplete from './ProjectUsersAutocomplete.vue'
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalAlertConfigAdd',
    components: {
        MultiMeasurementSelector,
        ProjectUsersAutoComplete
    },
    props: {
        siteId: String,
		openModal: String,
	},
    data() {
        return {
            dialog: this.openModal == 'add' ? true : false,
            errorMessage: null,
            msgRules: [v => v.length <= 500 || 'Max 500 characters'],
            newAlertConfig: {
                site: null,
                message: '',
                channels: null,
                config: null,
                // kind: null,
                alertType: null,
            },
            configKey: null,
            configVal: null,
            configArrVal: null,
            configNumVal: null,
            notifyAllUsers: true,
            configStrVal: null,
            selectedUsers: [],
            projectId: this.$route.params.projectId,
            currentProjectUsers: [],
            measurementKinds: [
                { v: 'Bool', t: 'Bool' },
                { v: 'Number', t: 'Number' },                
                { v: 'String', t: 'String' },
            ],
            alertTypes: [
                { v: 'info', t: 'Info' },
                { v: 'warning', t: 'Warning' },
                { v: 'critical', t: 'Critical' },
            ],
            configTypes: [
                { v: 'eq', t: 'Equals' }, //string
                { v: 'notEq', t: 'Does not equal' }, //string
                { v: 'gt', t: 'Greater than' }, //number
                { v: 'gte', t: 'Greater than/equal' }, //number
                { v: 'lt', t: 'Less than' }, //number
                { v: 'lte', t: 'Less than/equal' }, //number
                // { v: 'in', t: 'Value IN the array' }, //[]
                // { v: 'notIn', t: 'Value NOT in the array' }, //[]
                // { v: 'off', t: 'Offline' }, //bool
            ],
            channels: [
                // { v: 'ui', t: 'App'},
                { v: 'email', t: 'Email'},
                // { v: 'push', t: 'Push'},
                { v: 'sms', t: 'SMS'},
            ],            
            configTypeStr: ['eq','notEq'],
            configTypeNum: ['gt','gte','lt','lte'],
            configTypeArr: ['in','notIn'],
            configTypeBool: ['off'],
            selectMeasurementDialog: false,
            selectedMeasurements: [],
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit("closeModal")
		},
        validate() {
            if (!this.newAlertConfig.message || 
                !this.newAlertConfig.alertType || 
                // !this.newAlertConfig.kind || 
                !this.configKey) {
                this.errorMessage = "Complete all required fields.";
                return false;
            }
            if(this.newAlertConfig.message.length > 500) {
                this.errorMessage = "Message exceeds max characters.";
                return false;
            }
            this.errorMessage = null
            return true;
        },
        getNewConfig(){
            if(this.configTypeStr.includes(this.configKey))
                this.configVal = this.configStrVal;
            else if(this.configTypeNum.includes(this.configKey))
                this.configVal = this.configNumVal;
            else
                this.configVal = '';
            let newObj = {};
            newObj[this.configKey] = this.configVal;
            return newObj;
        },
        addMeasurements(measurements){
            this.selectedMeasurements = measurements;
            this.selectMeasurementDialog = false;
        },
        async addNewAlertConfig() {
            let formValid = this.validate();
            if(formValid) {
                this.newAlertConfig.config = this.getNewConfig();
                this.newAlertConfig.selectedMeasurements = this.selectedMeasurementIds
                this.newAlertConfig.selectedUsers = this.selectedUsers;
                console.log(this.newAlertConfig);
                try{
                    client.robustPost(`/admins/alertconfigs`,{newAlertConfig: this.newAlertConfig, siteId:this.siteId}).then(function(result){
                        console.log('AlertConfig Added',result.successMessage);
                    }).catch(function(error){
                        console.log("AlertConfig Not Added", error.errorMessage);
                    })
                    this.closeModal();
                }
                catch(error) {
                    console.error(error);
                }
            }
        },
        getDisplayName(m){
            if(m.type == "scoreAQ"){
                return "Air Quality Score"
            } else if(m.type == "scoreEQ"){
                return "Equipment Score"
            } else if(m.type == "scoreOW"){
                return "Occupant Wellness Score"
            } else if(m.type == "scoreOA"){
                return "Overall Score"
            } else if(m.type == "scoreAF"){
                return "Air Freshness Score"
            } else {
                return m.name + " " + (m.unit ? `(${m.unit})` : "")
            }
        },
        modifyAlertUsers(users){
            this.selectedUsers = users;
        }
    },
    computed: {
        selectedMeasurementIds(){
            return this.selectedMeasurements.map((el) => el.objectId)
        },
        getMeasurementChipName(){
            return (m) => {
                return this.getDisplayName(m) + (m.zone ? ` - ${m.zone.name}` : " - Site")
            }
        }
    },
}
</script>
<style lang="sass" scoped>
    .required
        color: #f00
    .add-users-actions
        display: flex
        align-items: flex-end
        justify-content: space-between
        padding-bottom: 15px
        gap: 35px
        .v-input--selection-controls
            margin: 0
            padding: 0
    .measurement-chips
        background: var(--input-bg)
        border-radius: 12px
        color: var(--input-txt)
        margin-bottom: 15px
        padding: 8px
        width: 100%
        overflow-x: auto
    .measurement-chips-btn
        margin: 0
</style>