<template lang="pug">
    .haq-card(:data-site-id="siteId").elevation-8
        .haq-card-header
            .haq-card-header-img.d-none.d-sm-flex(v-show="logoUrl")
                img(:src="logoUrl")
            .haq-card-header-info
                v-tooltip(top)
                    template(v-slot:activator="{on,attrs}")
                        .site-title.truncate(v-bind="attrs" v-on="on") {{name}}
                    span {{name}}
                v-tooltip(top v-if="address")
                    template(v-slot:activator="{on,attrs}")
                        .site-address.truncate(v-bind="attrs" v-on="on") {{address}}
                    span {{address}}
            v-spacer
            .haq-card-header-icons
                button.haq-card-header-icons-settings(@click="openModal")
                    v-icon mdi-cog
                a.haq-card-header-icons-link-to(@click="openSite")
                    v-icon mdi-open-in-new
        .haq-card-content
            v-btn.haq-card-button(@click="$router.push('/projects/' + projectId + '/sites/' + siteId + '/zones')")
                .haq-card-button-icon
                    CardIcons(:itemType="'zone'" :color="'#7D85C5'")
                .haq-card-button-title {{ zoneCount }} Zones
            v-btn.haq-card-button(@click="$router.push('/projects/' + projectId + '/sites/' + siteId + '/kiosks')")
                .haq-card-button-icon
                    CardIcons(:itemType="'kiosk'" :color="'#7D85C5'")
                .haq-card-button-title {{ kioskCount }} Kiosks
            v-btn.haq-card-button(@click="$router.push('/projects/' + projectId + '/sites/' + siteId + '/alertconfigs')")
                .haq-card-button-icon
                    CardIcons(:itemType="'alarm'" :color="'#7D85C5'")
                .haq-card-button-title {{ alertConfigCount }} Alerts
            v-btn.haq-card-button(@click="$router.push('/projects/' + projectId + '/sites/' + siteId + '/widgets')")
                .haq-card-button-icon
                    CardIcons(:itemType="'widget'" :color="'#7D85C5'")
                .haq-card-button-title {{ widgetCount }} Widgets
            v-btn.haq-card-button(@click="$router.push('/projects/' + projectId + '/sites/' + siteId + '/floorplans')")
                .haq-card-button-icon
                    CardIcons(:itemType="'floorplans'" :color="'#7D85C5'")
                .haq-card-button-title {{ widgetCount }} Floorplan
</template>
<script>
import CardIcons from '@/components/CardIcons.vue';

export default {
    name: 'SiteCard',
    components: {
        CardIcons,
    },
    props: {
        projectId: String,
        siteId: String,
        name: String,
        shortName: String,
        address: String,
        activeStatus: Boolean,
        logoUrl: String,        
        zoneCount: Number,
        kioskCount: Number,
        alertConfigCount: Number,
        widgetCount: Number,
    },
    data() {
        return {}
    },
    methods: {
        openModal() {
            this.$emit("openEditModal",this.siteId)
        },
        openSite(){
            let subdomain = location.host.split('.')[0];
            let domain = location.host.split('.')[1];
            let beta = subdomain.indexOf("beta") > -1 ? true : false;
            let betaUrl = `https://beta.${domain}.com/projects/${this.projectId}/sites/${this.siteId}`
            let prodUrl = `https://app.${domain}.com/projects/${this.projectId}/sites/${this.siteId}`
            location = beta ? betaUrl : prodUrl;
        },
        saveEditSite() {
            this.toggleEditSite();
        },
    }
}
</script>
<style lang="scss" scoped>
.haq-card {
    .haq-card-header-info {
        .site-title {
            font-size: 1.2rem;
        }
    }
    .haq-card-button {        
        .icon-report {
            height: 31px;
            .v-icon {
                color: rgb(125, 133, 197) !important;
            }
        }
    }
}
</style>