import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    // dark: true,
    themes: {
      dark: {
        // defaults
        primary: "#FFFFFF", // links
        secondary: "#2D325A",
        // accent: '#FFFFFF',
        // error: '#FFFFFF',
        // info: '#FFFFFF',
        success: "#02C39A",
        warning: "#FF5050",

        // custom
        background1: "#1D213C",
        background2: "#25294A",
        background3: "#2D325A",

        text1: "#FFFFFF",
        text2: "#7D85C5",
        text3: "#A8AEDE",

        button: {
          background: "#FFFFFF",
          text: "#021449",
        },

        input: {
          background: "#141729",
          text: "#FFFFFF",
        },

        status: {
          excellent: "#4E7CFF",
          good: "#FFCA3A",
          poor: "#FF5050",
        },
      },
      light: {
        // defaults
        primary: "#1D213C", // links
        secondary: "#F6F8FB",
        // accent: '#FFFFFF',
        // error: '#FFFFFF',
        // info: '#FFFFFF',
        success: "#02C39A",
        warning: "#FF5050",

        // custom
        background1: "#FFFFFF",
        background2: "#EFF1F7",
        background3: "#F6F8FB",

        text1: "#FFFFFF",
        text2: "#7D85C5",
        text3: "#A8AEDE",

        button: {
          background: "#FFFFFF",
          text: "#1D213C",
        },

        input: {
          background: "#A8AEDE",
          text: "#25294A",
        },

        status: {
          excellent: "#4E7CFF",
          good: "#FFCA3A",
          poor: "#FF5050",
        },
      },
    },
    options: { customProperties: true },
  },
});
