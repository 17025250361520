<template lang="pug">
    #manageKiosks.haq-card-page
        Navbar(tab="")
        v-container
            v-row
                v-col
                    Breadcrumbs(:items="bcItems")
            v-row   
                v-col
                    .page-header
                        .page-title
                            h1 Kiosks ({{kiosks ? kiosks.length : ''}})
                        .page-actions
                            v-btn.haq-btn.haq-btn-primary.btn-add-user(plain @click="currAction='add'") <v-icon>mdi-plus</v-icon> Kiosk
            v-row
                v-col(md="6" v-if="sites")
                    v-select.haq-text-field(v-model="siteId" :items="sites" item-text="name" item-value="objectId" label="Change Site" @change="changeSite" hide-details)
            v-row(v-if="kiosks")
                v-col
                    .page-content
                        .page-has-cards.kiosks(v-if="kiosks.length > 0")
                            KioskCard(v-for="k,index in kiosks" :kiosk="k" ref="kiosks" :key="index" :index="index" :users="users" v-on:sendAction="activateAction")
                        .page-no-cards(v-else)
                            p There are no kiosks for this site.
                            v-btn.haq-btn.haq-btn-secondary.btn-return(:href="'/projects/' + this.$route.params.projectId + '/sites'") Return to Sites
                    
        ModalShareKiosk(v-if="currAction == 'share'" :shareDialog="currAction" :site="currKiosk.site" :kiosk="currKiosk" :users="users" v-on:closeShareModal="closeModal")
        ModalRenameKiosk(v-if="currAction == 'rename'" :shareDialog="currAction" :site="currKiosk.site" :kiosk="currKiosk" :users="users" :index="currKioskIndex" v-on:closeShareModal="closeModal" v-on:renameKiosk="renameKiosk")
        ModalAddKiosk(v-if="currAction == 'add'" :addDialog="currAction" :site="currKiosk.site" :kiosk="currKiosk" :users="users" :index="currKioskIndex" v-on:closeAddModal="closeModal" v-on:renameKiosk="renameKiosk")
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import KioskCard from '@/components/KioskCard.vue'
import ModalShareKiosk from '@/components/ModalShareKiosk'
import ModalRenameKiosk from '@/components/ModalRenameKiosk'
import ModalAddKiosk from '@/components/kiosk_crud/ModalAddKiosk'
import {HTTPClient} from '../assets/js/http';

let client = new HTTPClient();
export default {
    name: 'Kiosks',
    components: {
        Navbar,
        Breadcrumbs,
		KioskCard,
		ModalShareKiosk,
		ModalRenameKiosk,
		ModalAddKiosk,
    },
    data() {
        return {
            projectId: this.$route.params.projectId,
            siteId: this.$route.params.siteId,
            sites: null,
			kiosks: null,
			bcItems: [
                { text: 'Home', disabled: false, href: '/', },
                { text: 'Projects', disabled: false, href: '/projects', },
                { text: 'Sites', disabled: false, href: '/projects/' + this.$route.params.projectId + '/sites', },
                { text: 'Kiosks', disabled: true, href: '', },
            ],
			users: [],
			currAction: null,
			currKiosk: {},
			currKioskIndex: 0,
        }
    },
	methods: {
		closeModal() {
			this.currAction = null
		},
        changeSite(siteId) {
            let projectId = this.projectId;
            this.$router.push({name: 'Kiosks', params: {projectId, siteId}});
            this.getKiosks();
        },
		activateAction(options) {
			if (options) {
				this.currAction = options.action;
				this.currKiosk = options.kiosk;
				this.currKioskIndex = options.index;
			}
		},
		addKiosk() {

		},
		closeShareModal() {
			this.currAction = null
		},
		renameKiosk(options) {
			this.kiosks[options.index].name = options.name;
			this.$refs.kiosks[options.index].renameKiosk(options.name);
		},
		async getKiosks() {
			try {
                this.kiosks = [];
				let kiosks = await client.robustQuery(`/api/kiosks/site/${this.siteId}`);
				this.kiosks = kiosks.results;
			} catch (e) {
				console.error(e)
			}
		},
		async getProjectUsers() {
			let projectUsersResults = await client.robustQuery(`/admins/projects/${this.$route.params.projectId}/users`);
			this.users = projectUsersResults.users;
		},
        fetchSites: async function() {
            let results = await client.robustQuery(`/admins/projects/${this.projectId}/sites`);
            this.sites = results.sites;
        },
	},
	mounted() {
        this.fetchSites();
		this.getKiosks();
		this.getProjectUsers();
	}
}
</script>
<style lang="scss" scoped>
.kiosks {
    overflow: hidden;
}
</style>