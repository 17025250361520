<template lang="pug">
    #manageZones.haq-table-page
        Navbar(tab="")
        v-container
            v-row 
                v-col
                    Breadcrumbs(:items="bcItems")
            v-row
                v-col
                    .page-header
                        .page-title
                            h1 {{project ? project.name : ''}} - Zones ({{dtZones ? dtZones.length : ''}})
                        .page-actions
                            //- v-btn.haq-btn.haq-btn-primary.add-zone(plain @click="addZone()") <v-icon>mdi-plus</v-icon> Zone
            v-row
                //- v-col(md="6")
                //-     v-select.haq-text-field(v-model="projectId" :items="$store.state.projects" item-text="name" item-value="objectId" label="Change Project" @change="changeProject" hide-details)
                v-col(md="6" v-if="sites")
                    v-select.haq-text-field(v-model="siteId" :items="sites" item-text="name" item-value="objectId" label="Change Site" @change="changeSite" hide-details)
            v-row(v-if="dtZones")
                v-col
                    .page-content
                        .page-has-table(v-if="dtZones.length > 0")
                            v-data-table.haq-data-table(
                                item-key="objectId"
                                :search="searchZones" 
                                :headers="dtZoneHeaders" 
                                :items="dtZones" 
                                :items-per-page="10")
                                template(v-slot:top)
                                    v-col(sm=5)
                                        v-text-field.haq-search(v-model="searchZones" append-icon="mdi-magnify" label="Search Zones" dense filled hide-details single-line outlined)
                                template(v-slot:item.edit="{ item }")
                                    v-icon(small class="mr-2" @click="openEditModal(item)") mdi-dots-vertical
                        .page-no-table(v-else)
                            p There are no zones for this site.
                            v-btn.haq-btn.haq-btn-secondary.btn-return(:href="'/projects/' + this.$route.params.projectId + '/sites'") Return to Sites
        ModalSiteZoneEdit(v-if="currentDialog == 'edit'" :openModal="currentDialog" v-on:closeEditModal="closeEditModal"
            :editedZone="editedZone"
            :editedIndex="editedIndex"
            :dtZones="dtZones")
 </template>
<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import ModalSiteZoneEdit from '@/components/site_crud/ModalSiteZoneEdit'
import {HTTPClient} from '../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'SiteZones',
    components: {
        Navbar,
        Breadcrumbs,
        ModalSiteZoneEdit,
    },
    data() {
        return {
            projectId: this.$route.params.projectId,
            siteId: this.$route.params.siteId,
            project: null,
            site: null,
            sites: null,
            timer: null,
            bcItems: [
                { text: 'Home', disabled: false, href: '/', },
                { text: 'Projects', disabled: false, href: '/projects', },
                { text: `Sites`, disabled: false, href: '/projects/' + this.$route.params.projectId + '/sites', },
                { text: 'Zones', disabled: true, href: '', },
            ],
            currentDialog: null,
            dtZoneHeaders:[],
            dtZones: null,
            editedIndex: -1,
            editedZone: {
                objectId: '',
                name: '',
            },
            searchZones: '',
        }
    },
    methods: {
        closeModal() {
			this.currentDialog = null;
		},
        openEditModal(zone) {
            this.currentDialog = 'edit';
            this.editedIndex = this.dtZones.indexOf(zone)
            this.editedZone = Object.assign({}, zone)
        },
        closeEditModal() {
            this.closeModal();
            this.$nextTick(() => {
                this.editedZone = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            });
        },
        // changeProject(projectId) {
        //     this.$router.push({name: 'SiteZones', params: {projectId}});
        //     this.fetchProject();
        //     this.fetchSites();
        //     this.fetchZones();
        // },
        changeSite(siteId) {
            let projectId = this.projectId;
            this.$router.push({name: 'SiteZones', params: {projectId, siteId}});
            this.fetchZones();
        },
        buildZonesTable(zones) {
            this.dtZones = [];
            zones.forEach(z => {
                let zone = {
                    objectId: z.objectId,
                    name: z.name,
                    edit: "",
                };
                this.dtZones.push(zone);
            });
            this.dtZoneHeaders = [
                { text: 'Name', value: 'name', align: 'start', sortable: true, },
                { text: '', value: 'edit', align: 'center', sortable: false, width: 1, },
            ];
        },
        fetchProject: async function(){
            try {
                let result = await client.robustQuery(`/admins/projects/${this.projectId}`);
                this.project = result.project;
            }
            catch(error) {
                console.error(error);
            }
        },
        fetchSite: async function() {
            let results = await client.robustQuery(`/admins/sites/${this.siteId}`);
            this.site = results.site;
        },
        fetchSites: async function() {
            let results = await client.robustQuery(`/admins/projects/${this.projectId}/sites`);
            this.sites = results.sites;
        },
        fetchZones: async function(){
            try {
                let result = await client.robustQuery(`/admins/sites/${this.siteId}/zones`);
                this.buildZonesTable(result.zones);
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted() {
        this.fetchProject();
        this.fetchSite();
        this.fetchSites();
        this.fetchZones();
    },
}
</script>
<style lang="scss" scoped>
.header {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .project-name {
        font: normal normal 20px Roboto;
    }
}
.zones-card {
    margin: 0 auto;
    width: 100%;
}
</style>