<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card.site-edit-dialog-card
            v-card-title
                span(class="text-h5") Edit Site
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-container
                    v-row
                        v-col(cols="12").title
                        v-col(cols="12" sm="3" md="3").edit-photo
                            button(@click="changePhoto")
                                img.site-image(v-if="newLogo" :src="newLogo")
                                img.site-image(v-else-if="site.logo" :src="site.logo.url.replace('http://','https://')")
                            v-btn.haq-btn.haq-btn-secondary(@click="changePhoto") {{ defaultButtonText }}
                            input(ref="uploader" class="d-none" type="file" accept="image/*" @change="renderFile($event, 'siteLogo')")
                        v-col(cols="12" sm="9" md="9").edit-personal-info
                            v-col(sm="12")
                                v-text-field.haq-text-field(v-model="site.name" label="Name" hide-details)
                            v-col(sm="12")
                                v-text-field.haq-text-field(v-model="site.shortName" label="Short Name" hide-details)
                    v-row.settings
                        v-col(:cols="12" :sm="12").title.ma-0 Address
                        //- v-col(sm="12")
                        //-     //- ADD IS ACTIVE TOGGLE
                        v-col(:cols="12" :sm="12")
                            v-text-field.haq-text-field(v-model="site.address" label="Address" hide-details)
                    v-row.settings
                        v-col(:cols="12" :sm="12").title.ma-0 Timezone
                        v-col(:cols="12" :sm="12")
                            v-autocomplete(v-model="timezone" :items="timezones").haq-text-field
                    //- v-row.settings(v-if="$store.state.user.role === 'superAdmin'" :data-site-id="site.objectId")
                    //-     v-col(:cols="12" :sm="12").title.ma-0 Derived Measurements
                    //-     v-col(:cols="12" :sm="12")
                    //-         .subtitle This will hide measurements such as Infection Transmission Risk, Time in Comfort Zone etc. This will only splice derived measurements from the parent measurement and not delete the measurement itself
                    //-         v-switch(v-model="showDerivedScores" color="primary" label="Show Derived Measurements")
                    v-row.settings(v-if="$store.state.user.role === 'superAdmin'" :data-site-id="site.objectId")
                        v-col(:cols="12" :sm="12").title.ma-0 Enable Air Quality App
                        v-col(:cols="12" :sm="12").ma-0
                            v-switch(v-model="aqEnabled" color="primary" label="Enable Air Quality App").ma-0
                    v-row.logos
                        v-col(cols="12")
                            .title Branding Logos
                            .subtitle These are used in the branded kiosk. If you have a project logo, the logo you select here will override it for this site.
                        v-col(sm="6")
                            .logo
                                .logo-name Primary Logo
                                img.brandingLogo1(v-if="site.brandingLogo1" :src="site.brandingLogo1.url ? site.brandingLogo1.url.replace('http://','https://') : newBrandingLogo1")
                                v-file-input(v-model="site.brandingLogo1" label="Primary Logo" accept="image/*" show-size hide-details @change="renderFile($event, 'brandingLogo1')")
                        v-col(sm="6")
                            .logo
                                .logo-name Secondary Logo
                                img.brandingLogo2(v-if="site.brandingLogo2" :src="site.brandingLogo2.url ? site.brandingLogo2.url.replace('http://','https://') : newBrandingLogo2")
                                v-file-input(v-model="site.brandingLogo2" label="Secondary Logo" accept="image/*" show-size hide-details @change="renderFile($event, 'brandingLogo2')")
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveSite" :disabled="isDisabled") Save
</template>
<script>
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();
import {toast} from 'materialize-css'

export default {
    name: 'ModalSiteEdit',
    props: {
        site: Object,
        openModal: String,
    },
    data() {
        return {
            newLogo: null,
            isDisabled: false,
            newLogoBase64: null,
            dialog: this.openModal == 'edit' ? true : false,
            defaultButtonText: this.site.logo ? 'Change Image' : 'Upload Image',
			newBrandingLogo1: null,
            newBrandingLogo2: null,
			newBrandingLogo1Base64: null,
            newBrandingLogo2Base64: null,
            showDerivedScores: this.site.showDerivedScores != null ? this.site.showDerivedScores : true,
            timezone: this.site.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            timezones: Intl.supportedValuesOf('timeZone'),
            aqEnabled: this.site.aqEnabled != null ? this.site.aqEnabled : true,
        }
    },
    methods: {
        closeModal(site) {
            this.dialog = false;
			this.$emit("closeModal", site)
		},
        changePhoto() {
            this.$refs.uploader.click();
        },
        saveSite(){
            try {
                this.isDisabled = true;
                let clone = JSON.parse(JSON.stringify(this.site));
                
                if(this.newLogoBase64) {
                    delete clone.logo;
                    clone.logo = this.newLogoBase64;
                }
                
                if(this.newBrandingLogo1Base64) {
                    delete clone.brandingLogo1;
                    clone.brandingLogo1 = this.newBrandingLogo1Base64;
                }
                if (this.newBrandingLogo2Base64) {
                    delete clone.brandingLogo2;
                    clone.brandingLogo2 = this.newBrandingLogo2Base64;
                }
                clone.timezone = this.timezone
                clone.aqEnabled = this.aqEnabled
                clone.showDerivedScores = this.showDerivedScores
                console.log(clone.showDerivedScores)
                client.robustPost(`/api/sites/${this.site.objectId}`,clone).then((result) => {
                    toast({
                        html: "Updated Site Details"
                    })
                    this.isDisabled = false;
                    console.log(result)
                    this.closeModal(result.site)
                }).catch(function(error){
                    console.error(error)
                    this.isDisabled = false
                    toast({
                        html: "Cannot update site. Please check if details are valid"
                    })
                })
            }
            catch(error) {
                console.error(error);
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        async renderFile(e, logoFor){
            try {
                if(e) {
                    let file = e.target.files[0]
                    let base64 = await this.getBase64(file);
                    console.log(file.name, logoFor)
					/*
                    let file = e.target.files[0];
                    let base64 = await this.getBase64(file);
                    this.newLogoBase64 = {type: file.type, base64, name: file.name};
                    this.newLogo = URL.createObjectURL(file);
                    */
					if(logoFor == "brandingLogo1") {
                        this.newBrandingLogo1Base64 = {type: file.type, base64, name: file.name};
                        this.newBrandingLogo1 = URL.createObjectURL(file);
                    }
                    else if (logoFor == "brandingLogo2") {
                        this.newBrandingLogo2Base64 = {type: file.type, base64, name: file.name};
                        this.newBrandingLogo2 = URL.createObjectURL(file);
                    } else if (logoFor == "siteLogo") {
						this.newLogoBase64 = {type: file.type, base64, name: file.name};
						this.newLogo = URL.createObjectURL(file);
					}
                    return true;
                }
                return false;
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted() {},    
}
</script>
<style lang="scss">
.site-edit-dialog-card {
    .edit-photo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .site-image {
            border-radius: 12px;
            height: 70px;
            max-width: 102px;
        }
    }
}
</style>