<template lang="pug">
v-dialog(v-model='dialog' max-width='590' @input="close" scrollable)
	v-card.haq-dialog-card
		v-card-title.text-h5
			| Rename Kiosk 
		v-text-field.haq-text-field.mr-3.ml-3.white-text(label="Kiosk Name" dark v-model="kioskName")
		v-card-actions
			v-spacer
			v-btn.haq-btn.haq-btn-secondary(text @click="close") Cancel
			v-btn.haq-btn.haq-btn-primary(text @click="updateName") Save
	v-snackbar(v-model="renameSnackbar" :top="true" :right="true") {{renameMessage}} 
</template>
<script>
import {HTTPClient} from '@/assets/js/http';
let client = new HTTPClient();
export default {
	name: 'ModalRenameKiosk',
	props: {
		site: Object,
		kiosk: Object,
		shareDialog: String,
		index: Number,
	},
	data() {
		return {
			renameSnackbar: false,
			renameMessage: '',
			kioskName: this.kiosk.name ? this.kiosk.name : this.kiosk.zone ? this.kiosk.zone.name : this.kiosk.site ? this.kiosk.site.name : '', 
			siteId: this.site.objectId,
			dialog: this.shareDialog == 'rename' ? true : false,
		};
	},
	methods: {
		close() {
			this.dialog = false;
			this.$emit('closeShareModal')
		},
		async updateName() {
			let res = await client.robustPost('/api/kiosk/' + this.kiosk.uuid, {name: this.kioskName});
			if (res.successMessage) {
				this.$emit('renameKiosk', {kiosk: this.kiosk.objectId, name: this.kioskName, index: this.index})
				this.renameMessage = "Kiosk name updated";
				this.renameSnackbar = true;

			} else {
				this.renameMessage = "Error updating kiosk name";
				this.renameSnackbar = true;
			}
			this.close();
		}
	},
	mounted() {
	},
};
</script>
<style lang="scss" scoped>
	v-dialog {
		border-radius: 8px
	}
	.white-text {
		color: var(--card-primary-txt) !important;
	}
	.v-card__title {
		background: var(--card-primary-bg);
	}
	.v-item-group {
		background: var(--card-primary-bg);
	}
	.v-card {
		background: var(--card-primary-bg);
	}
	.v-tab {
		background: var(--card-primary-bg);
	}
	.v-card__actions {
		background: var(--card-primary-bg);
	}
	.modal-header {
		padding-left: 10px;
	}
	.center {
		text-align: center;
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 40%;
	}	
	div::placeholder {
		color: var(--card-primary-txt);
	}	
	#cancelBtn {
		margin-right: 20px
	}
	#shareBtn {
		color: black
	}
</style>
<style lang="scss">
	.chip {
		border-radius: 8px;
		background: var(--app-secondary-txt);
		color: var(--card-primary-txt);
		i.close {
			position: unset
		}
	}
	.input {
		color: var(--card-primary-txt) !important;
	}
</style>