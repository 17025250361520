/*eslint no-undef: "warn"*/

<template lang="pug">
	#kiosk-wrapper(v-bind:class="{small: s}")
		#kiosk(v-bind:class="{small: s}")
			#k-carousel.carousel(:style="(measurements&&measurements.length<4 && !preview)?'width:68%;':''")
				.carousel-slide(v-if="!preview" v-for="(i,slideindex) in measurements", :class="'slide-' + getKioskKind(i.type) + ' slide-count-' + measurements.length",:style="getSlideAnimationDelay(slideindex)")
					.k-slide-time {{ timestamp }}
					.k-slide-measurement
						.k-slide-measurement-icon
							MeasurementIcons(:measurement="getKioskKind(i.type)",:iconcolor="'currentColor'",:size="52")
						.k-slide-measurement-curVal(v-bind:class="{small: s}") {{getDisplayVal(i)}}
							span {{i.unit}}
						.k-slide-measurement-name {{ getMeasurementDisName(i) }}
						.k-slide-measurement-progress
							span(v-if="i.curScore >= 75") Excellent
							span(v-else-if="i.curScore < 75 && i.curScore >= 50") Good
							span(v-else) Poor
						.k-slide-counts
							.k-slide-count(v-for="(item,pagingindex) in measurements.length", :class="{'active':(pagingindex === slideindex)}")
				.carousel-slide(v-if="preview" v-for="(i,slideindex) in previewMeasurements", :class="'slide-' + getKioskKind(i.type) + ' slide-count-' + previewMeasurements.length",:style="getSlideAnimationDelay(slideindex)")
					.k-slide-time {{ timestamp }}
					.k-slide-measurement
						.k-slide-measurement-icon
						.k-slide-measurement-curVal(v-if="!i.dummy" v-bind:class="{small: s}") {{getDisplayVal(i)}}
							span {{i.unit}}
						.k-slide-measurement-name(v-if="!i.dummy") {{ getMeasurementDisName(i) }}
						.k-slide-measurement-progress(v-if="!i.dummy")
							span(v-if="i.curScore >= 75") Excellent
							span(v-else-if="i.curScore < 75 && i.curScore >= 50") Good
							span(v-else) Poor
						.k-slide-counts
							.k-slide-count(v-for="(item,pagingindex) in previewMeasurements.length", :class="{'active':(pagingindex === slideindex)}")
			#k-cards(:style="(measurements&&measurements.length<4 && !preview)?'width:32%;background-color:var(--bg-color);':'background-color:var(--bg-color);'")
				.k-card(v-if="!preview" v-for="(i,index) in measurements.slice(0,measurements.length)", :class="'card-' + getKioskKind(i.type)")
					.k-card-measurement(:class="getKioskKind(i.type)")
						.k-card-measurement-progress
							span.progress-excellent(v-if="i.curScore >= 75") Excellent
							span(v-else-if="i.curScore < 75 && i.curScore >= 50") Good
							span(v-else) Poor
						.k-card-measurement-icon
							MeasurementIcons(:measurement="getKioskKind(i.type)")
						.k-card-measurement-curVal {{getDisplayVal(i)}}
							span {{i.unit}}
						.k-card-measurement-name(v-bind:class="{small: s}") {{ getMeasurementDisName(i) }}
				.k-card(v-if="preview" v-for="(i,index) in previewMeasurements.slice(0,previewMeasurements.length)", :class="'card-' + getKioskKind(i.type)" @click="addMeasurement = true; currIndex = index;overallMeasurement=false")
					.k-card-measurement
						.k-card-measurement-progress(v-if="!i.dummy")
							span.progress-excellent(v-if="i.curScore >= 75") Excellent
							span(v-else-if="i.curScore < 75 && i.curScore >= 50") Good
							span(v-else) Poor
						.k-card-measurement-icon(v-if="!i.dummy")
							
						.k-card-measurement-curVal {{getDisplayVal(i)}}
							span {{i.unit}}
						.k-card-measurement-name(v-if="i.dummy" v-bind:class="{small: s}" style="text-align:center") {{i.name}}
						.k-card-measurement-name(v-else v-bind:class="{small: s}") {{i.name}}
		KioskMeasurementSelector(:measurementDialog="addMeasurement" :overallMeasurement="overallMeasurement" v-on:close="closeModal" v-on:setMeasurement="setKioskSlot")
</template>

<script>
import { HTTPClient } from "../assets/js/http";
import MeasurementIcons from '@/components/MeasurementIcons.vue';
import KioskMeasurementSelector from '@/components/kiosk_crud/KioskMeasurementSelector';
let client = new HTTPClient();
var $ = require('jquery')
export default {
	name: "Kiosk",
	components: {
		MeasurementIcons,
		KioskMeasurementSelector
	},
	props: {
		site: Object,
		kioskUUID: String,
		siteObjectId: String,
		zoneObjectId: String,
		s: Boolean,
		preview: Boolean,
		config: Object,
	},
	data() {
		return {
			siteId: this.$route.query.siteId || this.siteObjectId || this.$route.params.siteId,
			zoneId: this.$route.query.zoneId || this.zoneObjectId,
			measurements: [],
			previewMeasurements: [
				{dummy: true, name: 'Select Measurement', type: 'dummy'},
				{dummy: true, name: 'Select Measurement', type: 'dummy'},
				{dummy: true, name: 'Select Measurement', type: 'dummy'},
				{dummy: true, name: 'Select Measurement', type: 'dummy'},
				{dummy: true, name: 'Select Measurement', type: 'dummy'},
				{dummy: true, name: 'Select Measurement', type: 'dummy'}],
			timestamp: '',
			kioskId: this.$route.params.id || this.kioskUUID,
			addMeasurement: false,
			currIndex: 0,
			overallMeasurement: false,
		}
	},
	methods: {
		setKioskSlot(m) {
			//let scores = ['scoreaq', 'scoreow'];
			let components = ['spacetemperature','relativehumidity', 'co2', 'pm2.5', 'infectiontransmissibility', 'timeincomfortzone','ionlevel',
			'ionizerstatus', 'ionizationtime', 'pm10', 'carbonmonoxide', 'ozone', 'scoreaf', 'scoreaq', 'scoreow','equipment'];
			console.log('M', m)
			for(let c of components){
				if(m.type.toLowerCase() == c){
					this.previewMeasurements[this.currIndex] = m;
				}
			}
		},
		closeModal() {
			this.addMeasurement = false;
		},
		getCurrentTime() {
			const today = new Date();
			this.timestamp = today.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
		},
		getSlideAnimationDelay(index) {
			let delay = ['0s','10s','20s','30s','40s','50s'];
			return 'animation-delay:' + delay[index].toString() + ';';
		},
		getKioskKind(kind) {
			if (kind == 'dummy') {
				return 'airquality';
			}
			let k = kind.toLowerCase();
			if(k.includes('airquality') || k.includes('aq') || k.includes('scoreow'))
				return 'airquality';
			if(['co2', 'carbonmonoxide', 'ozone'].includes(k)){
				return 'co2';
			}
			if(['pollutant', 'pm2.5', 'pm10'].includes(k)){
				return 'pollutant'
			}
			else {
				return k.toLowerCase();
			}
		},
		getDisplayVal(m){
			if(m.type.includes('score')){
				return Math.round(m.curScore)
			} else {
				if(isNaN(m.curVal)){
					return m.curVal
				} else {
					return Math.round(m.curVal);
				}
			}
		},
		async getMeasurements(){
			try{
				let measurementRes;
				if(this.site){
					measurementRes = await client.robustQuery(`/api/kiosk/${this.$route.params.id}/measurements`);
					return measurementRes;
				} else {
					if (this.config?.measurements.length) {
						measurementRes = await client.robustQuery(`/api/kiosk/${this.kioskUUID}/measurements`);
						return measurementRes;
					}
					measurementRes  = await client.robustQuery('/api/measurements', {siteId: this.siteId, zoneId: this.zoneId});
					return measurementRes;
				}
			} catch (error) {
				console.error(error);
				/*
				setTimeout(function() {
					window.location.href = '/login'
				}, 250);
				*/
			}
		},
		resizeToScreen(id){
			try{
				let that = this;
				let el = $('#'+id)[0];
				let iw = window.innerWidth;
				let ih = window.innerHeight;
				if(iw > window.outerWidth || ih > window.outerHeight){
					iw = window.outerWidth;
					ih = window.outerHeight;
				}
				let sr = iw / ih;
				let er = el.clientWidth / el.clientHeight; 
				// use the relative aspect ratio to determine which dimension to resize by
				if(sr < er){
					// use width
					let b = iw / el.clientWidth;
					let c = Math.floor(b*100)/100;
					let d = Math.round(el.clientWidth*(1-c)*50)/100;
					let dd = Math.round(el.clientHeight*(1-c)*50)/100;
					if(c < 1){
						$(el).css('transform', 'translate(-'+d+'px, -'+dd+'px) scale('+c+')');
					}else if(c > 1){
						dd = Math.round(el.clientHeight*(c-1)*50)/100;
						$(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					}else{
						$(el).css('transform', 'scale('+c+')');
					}
				}else{
					// use height
					let b = ih / el.clientHeight;
					let c = Math.floor(b*100)/100;
					// let dd = Math.round(el.clientHeight*(c-1)*50)/100;
					// $(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					let d = Math.round(el.clientWidth*(1-c)*50)/100;
					let dd = Math.round(el.clientHeight*(1-c)*50)/100;
					let ddd = Math.round(Math.abs(iw-el.clientWidth*c)*50)/100;
					if(c < 1){
						$(el).css('transform', 'translate(-'+(d-ddd)+'px, -'+dd+'px) scale('+c+')');
					}else if(c > 1){
						dd = Math.round(el.clientHeight*(c-1)*50)/100;
						$(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					}else{
						$(el).css('transform', 'scale('+c+')');
					}
				}
				if(!window['resizelistener'+id]){
					window['resizelistener'+id] = true;
					// window.addEventListener('resize', () => {
					// 	// detect window rotation and refresh
					// 	// console.log('resize');
					// 	that.resizeToScreen(id);
					// });
					let lastOrient = null;
					window.addEventListener('orientationchange', function(e){
						that.resizeToScreen(id);
						if(e.target.screen.orientation.angle == 0)
							window.location.reload();
						if(Math.abs(e.target.screen.orientation.angle)==90)
							window.location.reload();
						if(lastOrient != null){
							if(Math.abs(lastOrient) == 90 && Math.abs(e.target.screen.orientation.angle) != 90)
								window.location.reload();
							if(Math.abs(lastOrient) != 90 && Math.abs(e.target.screen.orientation.angle) == 90)
								window.location.reload();
						}
						lastOrient = e.target.screen.orientation.angle;
					});
				}
			}catch(e){
				console.error(e);
			}
		},
		async loadSite() {
			try {
				// console.log(this.site);
				/*eslint no-undef: "warn"*/
				this.resizeToScreen('kiosk');
				let components = ['spacetemperature','relativehumidity', 'co2', 'infectiontransmissibility', 'timeincomfortzone','ionlevel',
				'ionizerstatus', 'ionizationtime', 'pm10', 'carbonmonoxide', 'ozone', 'pm2.5', 'scoreaf', 'scoreaq', 'scoreow','equipment'];
				let measurementsRes = {results:[]};
				if (!this.preview) {
					measurementsRes = await this.getMeasurements();
				}
				for(let c of components){
					for(let m of measurementsRes.results){
						if(this.measurements.length >= 6){
							break;
						}
						if(m.type.toLowerCase() == c){
							this.measurements.push(m);
						}
					}
				}
			} catch (error) {
				window.M.toast({html: 'Cannot Load Site'})
			} finally {
				setTimeout(() => {window.M.AutoInit()}, 100);
			}
		}
	},
	mounted() {
		this.loadSite();
	},
	created(){
		setInterval(this.getCurrentTime, 333);
	},
	updated(){
		this.resizeToScreen('kiosk');
	},
	computed: {
		getMeasurementDisName(){
			let scoreNames = {
				scoreAQ: "Current Air Quality",
				scoreOW: "Occupant Comfort",
				scoreOA: "Overall"
			}
			return (m) => {
				if(scoreNames[m.type]){
					return scoreNames[m.type]
				} else {
					return m.name
				}
			}
		}
	}
};
</script>

<style lang="scss">
	.embed_documentation_footer--documentationFooter--2gpG4 {
		display: none;
	}
</style>
<style lang="scss" scoped>
	$airquality: var(--airquality);
	$co2: var(--co);
	$humidity: var(--rh);
	$pollutant: var(--plt);
	$temperature: var(--spt);
	$ventilation: var(--vt);
	$infectiontransmissibility: var(--plt);
	$equipment: var(--spt);

	@keyframes kf1 {
		0%,100% { opacity: 1; }
	}
	@keyframes kf2 {
		50% { opacity: 1; }
		80% { opacity: 0; }
	}
	@keyframes kf3 {
		33% { opacity: 1; }
		53% { opacity: 0; }
	}
	@keyframes kf4 {
		25% { opacity: 1; }
		45% { opacity: 0; }
	}
	@keyframes kf5 {
        10% { opacity: 1; }
		20% { opacity: 1; }
        30% { opacity: 0; }
	}
	@keyframes kf6 {
        7% { opacity: 1; }
		17% { opacity: 1; }
		27% { opacity: 0; }
	}
	
	#kiosk-wrapper {
		width: 100vw;
		background-color:#000;
		&.small {
			height: 345px;
			width: unset;
			background-color: unset;
		}
	}

	#kiosk,
	#k-carousel,
	#k-cards {
		height: 100vh;
		max-height: 840px;
		min-height: 840px;
	}

	#k-carousel,
	#k-cards {
		float: left;
	}

	#kiosk {
		background: var(--bg-color3);
		color: var(--text-1);
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		margin: 0 auto;
		max-width: 1280px;
		min-height: 840px;
		min-width: 1280px;
		width: 100%;
		-webkit-font-smoothing: antialiased;
		overflow: hidden;
		&.small {
			position: relative;
			top: -205px;
			left: -335px;
			transform: scale(.45) !important;
			margin-top: 70px;
			border-radius: 40px;
		}
	}

	#k-carousel {
		position: relative;
		width: 40.78%;

		.carousel-slide {
			padding: 80px 64px 0;
			left: 0;
			top: 0;
			opacity: 0;
			position: absolute;
			width: 100%;
			height: 100%;

			.k-slide-time {
				font-size: 1.6rem;
				height: 48%;
				opacity: .7;
			}

			.k-slide-measurement {
				.k-slide-measurement-icon {
					position: absolute;
					top: 405px;
					font-size: 1rem;
				}
				.k-slide-measurement-curVal {
					position: absolute;
					top: 481px;
					font-size: 8rem;
					font-weight: 300;
					line-height: 8rem;
					&.small {
						font-size: 6rem;
					}
					span {
						font-size: 2.133rem;
						opacity: .7;
					}
				}
				.k-slide-measurement-name {
					position: absolute;
					top: 625px;
					font-size: 2.133rem;
					line-height: 2.933rem;
				}
				.k-slide-measurement-progress {
					position: absolute;
					top: 677px;
					font-size: 1.6rem;
					line-height: 2.133rem;
					opacity: .7;
				}
			}

			.k-slide-counts {
				position: absolute;
				top: 750px;
				height: 10px;

				.k-slide-count {
					background: #fff;
					border-radius: 50%;
					float: left;
					height: 10px;
					opacity: .4;
					margin-right: 12px;
					width: 10px;

					&.active { opacity: 1; }
				}
			}

			&.slide-airquality ,
			&.slide-co2 {
				color: #25294A;
				.k-slide-count {
					background: #25294A;
				}
			}

			// transitions / bg colors
			&.slide-count-1 { animation: kf1 10s infinite; }
			&.slide-count-2 { animation: kf2 20s infinite; }
			&.slide-count-3 { animation: kf3 30s infinite; }
			&.slide-count-4 { animation: kf4 40s infinite; }
			&.slide-count-5 { animation: kf5 50s infinite; }
			&.slide-count-6 { animation: kf6 60s infinite; }

			&.slide-spacetemperature { background: $temperature; color: white;}
			&.slide-relativehumidity { background: $humidity; color: white;}
			&.slide-airquality { background: $airquality; }
			&.slide-ventilation { background: $ventilation; color: white;}
			&.slide-co2 { background: $co2; }
			&.slide-pollutant { background: $pollutant; color: white;}
			&.slide- { background: #1D213C; }
			&.slide-timeincomfortzone {background: $humidity; color: white;}
			&.slide-infectiontransmissibility { background: $infectiontransmissibility;}
			&.slide-equipment { background: $equipment; color: white;}
		}
	}

	#k-cards {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		font-weight: 400;
		padding: 32px;
		width: 59.22%;

		.k-card {
			flex: 1 50%;
			padding: 16px;
			max-width: 50%;
			max-height: 258px;
			cursor: pointer;
			.k-card-measurement {
				background: #25294A;
				border-radius: 20px;
				min-width: 315px;
				min-height: 226px;
				padding: 16px 24px 0 32px;
				box-shadow: 0px 100px 80px rgba(2, 4, 12, 0.12), 0px 41.7776px 33.4221px rgba(2, 4, 12, 0.0862625), 0px 22.3363px 17.869px rgba(2, 4, 12, 0.0715329), 0px 12.5216px 10.0172px rgba(2, 4, 12, 0.06), 0px 6.6501px 5.32008px rgba(2, 4, 12, 0.0484671), 0px 2.76726px 2.21381px rgba(2, 4, 12, 0.0337375);

				&.spacetemperature { background: var(--spt2); }
				&.relativehumidity { background: var(--rh2) }
				&.airquality { background: var(--airquality2) }
				&.ventilation { background: var(--vt2); }
				&.co2 { background: var(--co2); }
				&.pollutant { background: var(--plt2); }
				&.timeincomfortzone {background: var(--rh2)}
				&.infectiontransmissibility { background: var(--plt) }
				&.equipment { background: var(--spt2);}
				
				.k-card-measurement-progress {
					color: #E8912B;
					font-size: 1.2rem;
					line-height: 2rem;
					text-align: right;

					.progress-excellent { color: #02C39A; }
				}
				.k-card-measurement-icon {
					height: 40px;
					width: 40px;
					margin-bottom: 18px;
				}
				.k-card-measurement-curVal {
					font-size: 3.2rem;
					font-weight: 300;
					line-height: 3.3rem;

					span {
						color: var(--text-1);
						font-size: 1.2rem;
						margin-left: 5px;
					}
				}
				.k-card-measurement-name {
					font-size: 1.65rem;
					line-height: 2rem;
					&.small {
						font-size: 1.29rem;
					}
				}
			}
		}
	}
</style>