<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card.zone-edit-dialog-card
            v-card-title
                span(class="text-h5") Edit Zone
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-container
                    v-row
                        v-col(cols="12").title
                        v-col(cols="12" sm="9" md="9").edit-personal-info
                            v-col(sm="12")
                                v-text-field.haq-text-field(v-model="editedZone.name" label="Name" hide-details)
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveZone") Save
</template>
<script>
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalSiteZoneEdit',
    props: {
        editedZone: Object,
        editedIndex: Number,
        dtZones: Array,
        openModal: String,
    },
    data() {
        return {
            dialog: this.openModal == 'edit' ? true : false,
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit('closeEditModal');
        },
        changePhoto() {
            this.$refs.uploader.click();
        },
        saveZone(){
            try {
                client.robustPost(`/api/zones/${this.editedZone.objectId}`,this.editedZone).then(function(result){
                    console.log('Zone Updated',result);
                }).catch(function(error){
                    console.log("Zone Not Updated", error);
                })
                Object.assign(this.dtZones[this.editedIndex], this.editedZone);
            }
            catch(error) {
                console.error(error);
            }
            this.closeModal();
        },
    },
}
</script>