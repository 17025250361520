<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
import {wlData} from '../../frontend/src/assets/js/constants';
// set the page title and favicon
document.title = wlData.name+' Admin Dashboard'; // eslint-disable-line no-undef
export default {
  name: 'App',
  async beforeCreate(){
    try {
      if(window.webpackHotUpdate || window.webpackHotUpdatefrontend){
        await this.$store.dispatch('loadLocals');
        if (this.$store.state.theme === 'dark')
          this.$vuetify.theme.dark = true
        else
          this.$vuetify.theme.dark = false
      } else {
        this.$store.dispatch('localsThroughWindow')
        if (this.$store.state.theme === 'dark')
          this.$vuetify.theme.dark = true
        else
          this.$vuetify.theme.dark = false
      }
    } catch (error) {
      console.error(error);
    }
  }
};
</script>
<style lang="scss">
  @import './assets/sass/global.sass';
</style>