import { render, staticRenderFns } from "./ModalAlertConfigAdd.vue?vue&type=template&id=6238230a&scoped=true&lang=pug"
import script from "./ModalAlertConfigAdd.vue?vue&type=script&lang=js"
export * from "./ModalAlertConfigAdd.vue?vue&type=script&lang=js"
import style0 from "./ModalAlertConfigAdd.vue?vue&type=style&index=0&id=6238230a&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6238230a",
  null
  
)

export default component.exports