<template lang="pug">
v-dialog(v-model='dialog' max-width='590' @input="close" scrollable)
	v-card.haq-dialog-card
		v-card-title.text-h5 Share with 
		v-tabs(v-model="tabs" fixed-tabs)
			v-tab(@click="currTab = 'email'") Email
			v-tab(@click="currTab = 'link'") Link 
			v-tab(v-if="kiosk.qrCode" @click="currTab = 'qrCode'") QR Code 
		v-tabs-items(v-model="tabs")
			v-tab-item
				v-col
					v-combobox(label="Share with email" placeholder="Email address" multiple chips v-model="chips" :clearable="true")
					p Share with existing users
					v-data-table.haq-data-table(v-model="selectedUsers"
						show-select
						item-key="objectId"
						:headers="dtEditUsersHeaders"
						:items="users"
						:items-per-page="5")
						template(v-slot:item.profilePhoto="{ item }")
							.img-wrap
								img.avatar(v-if="item.profilePhoto" :src="item.profilePhoto.url")
						template(v-slot:item.email="{ item }") {{ item.email }} 
							v-icon(v-if="item.emailVerified" small color="success") mdi-check-decagram
			v-tab-item
				v-col
					v-text-field.haq-text-field(label="Copy Link" v-model="url" :append-icon="'mdi-gear'" readonly)
			v-tab-item(v-if="kiosk.qrCode")
				//-v-col
					v-text-field(label="Copy Link" v-model="url" :append-icon="'mdi-gear'" readonly)
				v-img.center.mt-5(:src="kiosk.qrCode.url")
				a.v-row.center.download.mt-2.mb-4(download="QRCode.png" target="_blank" :href="kiosk.qrCode.url")
					.v-col
						v-icon mdi-download
						span Download Image
		v-card-actions(v-if="currTab == 'email'")
			v-spacer
			v-btn.haq-btn.haq-btn-secondary(text @click="close") Cancel
			v-btn.haq-btn.haq-btn-primary(text @click="sendEmail") Share
		v-snackbar(v-model="emailError" :top="true" :right="true") At least one email must be selected to share
</template>
<script>
import {HTTPClient} from '@/assets/js/http';
let client = new HTTPClient();
export default {
	name: 'ModalShareKiosk',
	props: {
		site: Object,
		kiosk: Object,
		shareDialog: String,
		users: Array,
	},
	data() {
		return {
			siteId: this.site.objectId,
			url: 'http://a.iaq.ai/k/' + this.kiosk.objectId,
			dialog: this.shareDialog == 'share' ? true : false,
			tabs: null,
			userList: [
				{header: 'Select an option or create one'},
			],
			dtEditUsersHeaders: [
                { text: '', value: 'profilePhoto', align: 'center', sortable: false, width: 1, },
                { text: 'First Name', value: 'firstName', align: 'start', },
                { text: 'Last Name', value: 'lastName', align: 'start', },
                { text: 'Email', value: 'username', },
            ],
			emailError: false,
			selectedUsers: [],
			chips: [],
			currTab: 'email',
		};
	},
	methods: {
		close() {
			this.dialog = false;
			this.$emit('closeShareModal')
		},
		sendEmail() {
			if (this.chips.length <= 0) {
				this.emailError = true;
				return;
			}
			client.robustPost('/api/kiosk/share', {emails: this.chips, kiosk: this.kiosk});
			this.close();
		}
	},
	mounted() {
	},
	watch: {
		'$props.shareDialog': {
			handler() {
				this.dialog = this.shareDialog == 'share' ? true : false;
			}
		},
		'$data.selectedUsers': {
			handler() {
				// if chips exists but does not exist in users keep it
				if (this.chips.length == 0) {
					if (this.selectedUsers.length == 0) {
						return;
					}
					this.chips = this.selectedUsers.map(a => a.username);
				}
				for (let [i,chip] of this.chips.entries()) {
					if (this.users.map(a => a.username).indexOf(chip) > 0) {
						if (this.selectedUsers.map(a => a.username).indexOf(chip) < 0 || this.selectedUsers.length == 0) {
							if (this.chips.length == 1) {
								this.chips = [];
							} else {
								this.chips.splice(i, 1);
							}
							//remove chip
						}
					}	
				}
				this.chips = [...new Set(this.chips.concat(this.selectedUsers.map(a => a.username)))];
				// else if the chip exists and it exists in users but not selected Users, remove it
				//let temp = this.selectedUsers.map(a => a.username);
				//this.chips = [...new Set(this.chips.concat(this.selectedUsers.map(a => a.username)))];
			},
			
		},
		'$data.chips': {
				handler() {
					if (this.chips.length == 0) {
						this.selectedUsers = [];
					}
				},
			}
	}
};
</script>
<style lang="scss" scoped>
	.v-dialog {
		border-radius: 8px
	}
	.white-text {
		color: var(--app-primary-txt) !important
	}
	.v-card__title {
		background: var(--card-primary-bg);
	}
	.v-item-group {
		background: var(--card-primary-bg);
	}
	.v-card {
		background: var(--card-primary-bg);
	}
	.v-tab {
		background: var(--card-primary-bg);
	}
	.v-card__actions {
		background: var(--card-primary-bg);
	}
	.modal-header {
		padding-left: 10px;
	}
	.center {
		text-align: center;
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 40%;
	}	
	div::placeholder {
		color: var(--app-primary-txt);
	}	
	.tabs {
		background: transparent;
			height: 55px;
			padding-left: 10px;
			li.tab {
				height: 55px;
				line-height: 55px;
				a {
					color: var(--app-primary-txt);
					opacity: 0.6;
					&.active {
						color: var(--app-primary-txt);
						opacity: 1;
						border-bottom: 2px solid var(--border);
						border-bottom-width: 3px;
					}
				}
			}
			.indicator {
				display: none;
			}
	} 
	.download {
		cursor: pointer;
		color: var(--app-primary-txt);
		text-decoration: none;
	}
	.btn {
		border-radius: 12px;
	}
	#cancelBtn {
		margin-right: 20px
	}
	#shareBtn {
		color: black
	}
</style>
<style lang="scss">
	.chip {
		border-radius: 8px;
		background: var(--app-secondary-txt);
		color: var(--app-primary-txt);
		i.close {
			position: unset
		}
	}
	.input {
		color: var(--app-primary-txt) !important;
	}
</style>