<template lang="pug">
  div(v-if="itemType === 'site'" :class="'icon-site'")
    svg(width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M5 12H3L12 3L21 12H19" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
      path(d="M5 12V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V12" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
      path(d="M9 21V15C9 14.4696 9.21071 13.9609 9.58579 13.5858C9.96086 13.2107 10.4696 13 11 13H13C13.5304 13 14.0391 13.2107 14.4142 13.5858C14.7893 13.9609 15 14.4696 15 15V21" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
  div(v-else-if="itemType === 'user'" :class="'icon-user'")
    svg(width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
      path(d="M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14C15.0609 15 16.0783 15.4214 16.8284 16.1716C17.5786 16.9217 18 17.9391 18 19V21" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
  div(v-else-if="itemType === 'alarm'" :class="'icon-alarm'")
    svg(width="30" height="29" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M10.5 5C10.5 4.46957 10.7107 3.96086 11.0858 3.58579C11.4609 3.21071 11.9696 3 12.5 3C13.0304 3 13.5391 3.21071 13.9142 3.58579C14.2893 3.96086 14.5 4.46957 14.5 5C15.6484 5.54303 16.6274 6.38833 17.3321 7.4453C18.0367 8.50227 18.4404 9.73107 18.5 11V14C18.5753 14.6217 18.7954 15.2171 19.1428 15.7381C19.4902 16.2592 19.9551 16.6914 20.5 17H4.5C5.04494 16.6914 5.50981 16.2592 5.85719 15.7381C6.20457 15.2171 6.42474 14.6217 6.5 14V11C6.55956 9.73107 6.9633 8.50227 7.66795 7.4453C8.3726 6.38833 9.35159 5.54303 10.5 5" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
      path(d="M9.5 17V18C9.5 18.7956 9.81607 19.5587 10.3787 20.1213C10.9413 20.6839 11.7044 21 12.5 21C13.2956 21 14.0587 20.6839 14.6213 20.1213C15.1839 19.5587 15.5 18.7956 15.5 18V17" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
  div(v-else-if="itemType === 'report'" :class="'icon-report'")
    //- v-icon mdi-file-chart-outline
    svg(width="30" height="30" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg")
      path(d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2M18 20H6V4H13V9H18V20M9 13V19H7V13H9M15 15V19H17V15H15M11 11V19H13V11H11Z")
  div(v-else-if="itemType === 'zone'" :class="'icon-zone'")
    //- v-icon mdi-home-floor-1
    //- v-icon mdi-home-floor-2
    //- v-icon mdi-home-floor-3
    svg(width="30" height="30" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg")
      path(d="M12,3L2,12H5V20H19V12H22L12,3M10,8H14V18H12V10H10V8Z")
    svg(width="30" height="30" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg")
      path(d="M12,3L2,12H5V20H19V12H22L12,3M9,8H13A2,2 0 0,1 15,10V12A2,2 0 0,1 13,14H11V16H15V18H9V14A2,2 0 0,1 11,12H13V10H9V8Z")
    svg(width="30" height="30" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg")
      path(d="M12,3L22,12H19V20H5V12H2L12,3M15,11.5V10C15,8.89 14.1,8 13,8H9V10H13V12H11V14H13V16H9V18H13A2,2 0 0,0 15,16V14.5A1.5,1.5 0 0,0 13.5,13A1.5,1.5 0 0,0 15,11.5Z")
  div(v-else-if="itemType === 'kiosk'" :class="'icon-kiosk'")
    //- v-icon mdi-chart-donut
    svg(width="30" height="30" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg")
      path(d="M13,2.05V5.08C16.39,5.57 19,8.47 19,12C19,12.9 18.82,13.75 18.5,14.54L21.12,16.07C21.68,14.83 22,13.45 22,12C22,6.82 18.05,2.55 13,2.05M12,19A7,7 0 0,1 5,12C5,8.47 7.61,5.57 11,5.08V2.05C5.94,2.55 2,6.81 2,12A10,10 0 0,0 12,22C15.3,22 18.23,20.39 20.05,17.91L17.45,16.38C16.17,18 14.21,19 12,19Z")
  div(v-else-if="itemType === 'widget'" :class="'icon-widget'")
    //- v-icon mdi-widgets-outline
    svg(width="30" height="30" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg")
      path(d="M16.7 4.5L19.5 7.3L16.7 10.1L13.9 7.3L16.7 4.5M9 5V9H5V5H9M19 15V19H15V15H19M16.7 1.7L11 7.3L16.7 13H13V21H21V13H16.7L22.3 7.3L16.7 1.7M11 3H3V11H11V3M9 15V19H5V15H9M11 13H3V21H11V13Z")
  div(v-else-if="itemType === 'group'" :class="'icon-group'")
    svg(width="54" height="30" viewBox="0 0 96 48" :fill="'#ffffff'" xmlns="http://www.w3.org/2000/svg")
        path(d="M16 28C20.4 28 24 24.4 24 20C24 15.6 20.4 12 16 12C11.6 12 8 15.6 8 20C8 24.4 11.6 28 16 28ZM20.52 32.4C19.04 32.16 17.56 32 16 32C12.04 32 8.28 32.84 4.88 34.32C1.92 35.6 0 38.48 0 41.72V48H18V41.56C18 38.24 18.92 35.12 20.52 32.4ZM80 28C84.4 28 88 24.4 88 20C88 15.6 84.4 12 80 12C75.6 12 72 15.6 72 20C72 24.4 75.6 28 80 28ZM96 41.72C96 38.48 94.08 35.6 91.12 34.32C87.72 32.84 83.96 32 80 32C78.44 32 76.96 32.16 75.48 32.4C77.08 35.12 78 38.24 78 41.56V48H96V41.72ZM64.96 30.6C60.28 28.52 54.52 27 48 27C41.48 27 35.72 28.56 31.04 30.6C26.72 32.52 24 36.84 24 41.56V48H72V41.56C72 36.84 69.28 32.52 64.96 30.6ZM32.28 40C32.64 39.08 32.8 38.44 35.92 37.24C39.8 35.72 43.88 35 48 35C52.12 35 56.2 35.72 60.08 37.24C63.16 38.44 63.32 39.08 63.72 40H32.28ZM48 8C50.2 8 52 9.8 52 12C52 14.2 50.2 16 48 16C45.8 16 44 14.2 44 12C44 9.8 45.8 8 48 8ZM48 0C41.36 0 36 5.36 36 12C36 18.64 41.36 24 48 24C54.64 24 60 18.64 60 12C60 5.36 54.64 0 48 0Z" :fill="color")
  div(v-else-if="itemType === 'floorplans'" :class="'icon-floorplans'")
    //- v-icon mdi-map
    svg(width="30" height="30" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg")
      path(d="M20,2V20H22V2M2,2H4V20H2M20,22H4C2.9,22 2,21.1 2,20V2C2,0.9 2.9,0 4,0H20C21.1,0 22,0.9 22,2V20C22,21.1 21.1,22 20,22Z")
  div(v-else)
    //- No icon
</template>
<script>
export default {
  name: "CardIcons",
  props: {
    itemType: String,
    color: String,
  }
};
</script>