<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card
            v-card-title
                span(class="text-h5") Edit Group
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-container
                    v-row
                        v-col(sm="12")
                            v-text-field(v-model="group.name" label="Name" hide-details dense filled outlined)
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveGroup") Save
</template>
<script>
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalGroupEdit',
    props: {
        group: Object,
        projectId: String,
        project: Object,
        openModal: String,
    },
    data() {
        return {
            dialog: this.openModal == 'edit' ? true : false,
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit("closeModal")
		},
        saveGroup(){
            try {
                let clone = JSON.parse(JSON.stringify(this.group));

                client.robustPost(`/api/projects/${this.project.objectId}/groups/${this.group.objectId}`,clone).then(function(result){
                    // console.log('Group Updated',result);
                    window.M.toast({
                        html: `Group updated`,
                    });
                }).catch(function(error){
                    console.log("Group Not Updated", error);
                    window.M.toast({
                        html: `Group not updated: ${error.message}`,
                    });
                })
            }
            catch(error) {
                console.error(error);
            }
            this.closeModal();
        },
    },
    mounted() {},    
}
</script>
<style lang="scss">
    
</style>