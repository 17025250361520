import { render, staticRenderFns } from "./WidgetMeasurementSelector.vue?vue&type=template&id=0dd5e330&scoped=true&lang=pug"
import script from "./WidgetMeasurementSelector.vue?vue&type=script&lang=js"
export * from "./WidgetMeasurementSelector.vue?vue&type=script&lang=js"
import style0 from "./WidgetMeasurementSelector.vue?vue&type=style&index=0&id=0dd5e330&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dd5e330",
  null
  
)

export default component.exports