<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal")
        v-card.haq-dialog-card
            v-card-title
                span(class="text-h5") Delete the following {{multipleAlertConfigs ? 'configurations' : 'configuration' }}?
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-row
                    v-col(cols="12")
                        ul
                            li(v-for="ac,index in selectedDeleteAlertConfig") {{ac.alertType.charAt(0).toUpperCase() + ac.alertType.slice(1)}} alert configuration: {{getConfigName(ac.config)}}
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="deleteAlertConfigs") Confirm
</template>
<script>
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalAlertConfigDelete',
    props: {
        selectedDeleteAlertConfig: Array,
        openModal: String,
    },
    data() {
        return {
            multipleAlertConfigs: this.selectedDeleteAlertConfig.length > 1 ? true : false,
            dialog: this.openModal == 'delete' ? true : false,
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit('closeModal')
		},
        getConfigKeyName(key) {
            switch(key) {
                case 'eq':      return 'Equals';
                case 'notEq':   return 'Does not equal';
                case 'gt':      return 'Greater than';
                case 'gte':     return 'Greater than/equal';
                case 'lt':      return 'Less than';
                case 'lte':     return 'Less than/equal';
                case 'in':      return 'Value IN the array';
                case 'notIn':   return 'Value NOT in the array';
                case 'off':     return 'Offline';
                default:        return '';
            }
        },
        getConfigName(config){
            let configKey = Object.keys(config)[0];
            let configVal = Object.values(config)[0];
            let key = this.getConfigKeyName(configKey);
            if(configKey === 'off')
                return key;
            else
                return key + ': ' + configVal;            
        },
        async deleteAlertConfigs() {
            let alertConfigIds = [];
            this.selectedDeleteAlertConfig.forEach(ac => {
                alertConfigIds.push(ac.objectId);
            })

            let result = await client.robustDelete('/admins/alertconfigs',{data: alertConfigIds});
            if(result.successMessage) {
                console.log('AlertConfig Deleted',result.successMessage);
                this.closeModal();
            }
            else {
                console.log("AlertConfig Not Deleted", result.errorMessage);
                this.closeModal();
            }
        }
    }
}
</script>