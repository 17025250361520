<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card.alertconfig-edit-dialog-card
            v-card-title
                span(class="text-h5") Edit Alert Configuration
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-container
                    v-row(v-if="errorMessage")
                        v-col.error {{ errorMessage }}
                    v-row
                        v-col(cols="12" sm="12" md="12") Message <span class="required">*</span>
                            v-text-field.haq-text-field(v-model="editedAlertConfig.message" :rules="msgRules" counter)
                    //- v-row
                    //-     v-col(cols="5" sm="12" md="5") Measurement Kind <span class="required">*</span>
                    //-     v-col(cols="7" sm="12" md="7")
                    //-         v-select.haq-text-field(v-model="editedAlertConfig.kind" :items="measurementKinds" item-text="t" item-value="v" label="" hide-details)
                    v-row
                        v-col(cols="5" sm="12" md="5") Alert Type <span class="required">*</span>
                        v-col(cols="7" sm="12" md="7")
                            v-select.haq-text-field(v-model="editedAlertConfig.alertType" :items="alertTypes" item-text="t" item-value="v" label="" hide-details)
                    v-row
                        v-col(cols="5" sm="12" md="5") Measurements <span class="required">*</span>
                        v-col(cols="7" sm="12" md="7")
                            .measurement-chips(v-if="selectedMeasurements.length > 0")
                                v-chip(v-for="item in selectedMeasurements" :key="item.objectId").ma-1
                                    span {{getMeasurementChipName(item)}}
                            v-btn.haq-btn.haq-btn-secondary.measurement-chips-btn(@click="selectMeasurementDialog = true") Select Measurements
                    v-row.align-center
                        v-col(cols="5" sm="12" md="5") Notify All Users <span class="required">*</span>
                        v-col(cols="7" sm="12" md="7").d-flex.align-center
                            v-checkbox(v-model="notifyAllUsers" hide-details).pa-0.ma-0
                    v-row.align-center(v-show="!notifyAllUsers")
                        v-col(cols="5" sm="12" md="5") Users To Notify <span class="required">*</span>
                        v-col(cols="7" sm="12" md="7")
                            ProjectUsersAutoComplete(:users="selectedUsers" v-on:modifyAlertUsers="modifyAlertUsers")
                    v-row.align-center(v-show="!notifyAllUsers")
                        v-col(cols="5" sm="12" md="5") Groups To Notify
                        v-col(cols="7" sm="12" md="7")
                            v-autocomplete.haq-text-field(v-model="editedAlertConfig.groups" :items="groups" item-text="name" item-value="objectId" label="Groups" multiple chips deletable-chips hide-details)
                    v-row
                        v-col(cols="5" sm="12" md="5") Notification Channels
                        v-col(cols="7" sm="12" md="7")
                            v-select.haq-text-field(v-model="editedAlertConfig.channels" :items="channels" :menu-props="{ maxHeight: '400' }" item-text="t" item-value="v" label="" chips hide-details multiple)
                    v-row
                        v-col(cols="5" sm="12" md="5") Condition <span class="required">*</span>
                        v-col(cols="7" sm="12" md="7" )
                            v-select.haq-text-field(v-model="configKey" :items="configTypes" item-text="t" item-value="v" label="" hide-details)
                            v-text-field.haq-text-field(v-if="configTypeStr.includes(configKey)" v-model="configStrVal"  label="" hide-details)
                            v-text-field.haq-text-field(v-else-if="configTypeNum.includes(configKey)" v-model="configNumVal" label="" type="number" hide-details)
                            //- v-switch(v-if="configTypeBool.includes(currentConfigKey)" v-model="currentConfigVal" :label="currentConfigVal == 'true' ? 'Offline' : 'Online'" inset hide-details)
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveEditAlertConfig") Save
        
        
        MultiMeasurementSelector(:siteId="siteId" v-model="selectMeasurementDialog" :preSelectedMeasurements="selectedMeasurementIds" v-on:addMeasurements="addMeasurements")

</template>
<script>
import MultiMeasurementSelector from './MultiMeasurementSelector.vue'
import ProjectUsersAutoComplete from './ProjectUsersAutocomplete.vue'
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalAlertConfigEdit',
    components: {
        MultiMeasurementSelector,
        ProjectUsersAutoComplete
    },
    props: {
        siteId: String,
        editedAlertConfig: Object,
        editedIndex: Number,
        dtEditAlertConfigs: Array,
        openModal: String,
        groups: Array
    },
    data() {
        let selectedUsers = this.editedAlertConfig.users?.map((el) => el.objectId) || [];
        return {
            dialog: this.openModal == 'edit' ? true : false,
            errorMessage: null,
            msgRules: [v => v.length <= 500 || 'Max 500 characters'],
            configKey: this.editedAlertConfig.config ? Object.keys(this.editedAlertConfig.config)[0] : null,
            configVal: this.editedAlertConfig.config ? Object.values(this.editedAlertConfig.config)[0] : null,
            configArrVal: null,
            configNumVal: null,
            notifyAllUsers: !this.editedAlertConfig.users?.length > 0 && !this.editedAlertConfig.groups?.length > 0,
            selectedUsers,
            configStrVal: null,
            measurementKinds: [
                { v: 'Bool', t: 'Bool' },
                { v: 'Number', t: 'Number' },                
                { v: 'String', t: 'String' },
            ],
            alertTypes: [
                { v: 'info', t: 'Info' },
                { v: 'warning', t: 'Warning' },
                { v: 'critical', t: 'Critical' },
            ],
            configTypes: [
                { v: 'eq', t: 'Equals' }, //string
                { v: 'notEq', t: 'Does not equal' }, //string
                { v: 'gt', t: 'Greater than' }, //number
                { v: 'gte', t: 'Greater than/equal' }, //number
                { v: 'lt', t: 'Less than' }, //number
                { v: 'lte', t: 'Less than/equal' }, //number
                // { v: 'in', t: 'Value IN the array' }, //[]
                // { v: 'notIn', t: 'Value NOT in the array' }, //[]
                // { v: 'off', t: 'Offline' }, //bool
            ],
            channels: [
                // { v: 'ui', t: 'App'},
                { v: 'email', t: 'Email'},
                // { v: 'push', t: 'Push'},
                { v: 'sms', t: 'SMS'},
            ],            
            configTypeStr: ['eq','notEq'],
            configTypeNum: ['gt','gte','lt','lte'],
            configTypeArr: ['in','notIn'],
            configTypeBool: ['off'],
            selectMeasurementDialog: false,
            selectedMeasurements: [],
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit('closeModal');
		},
        validate() {
            if (!this.editedAlertConfig.message || 
                !this.editedAlertConfig.alertType || 
                // !this.editedAlertConfig.kind || 
                !this.configKey) {
                this.errorMessage = "Complete all required fields.";
                return false;
            }
            if(this.editedAlertConfig.message.length > 500) {
                this.errorMessage = "Message exceeds max characters.";
                return false;
            }
            return true;
        },
        getNewConfig(){
            if(this.configTypeStr.includes(this.configKey))
                this.configVal = this.configStrVal;
            else if(this.configTypeNum.includes(this.configKey))
                this.configVal = this.configNumVal;
            else
                this.configVal = '';

            let newObj = {};
            newObj[this.configKey] = this.configVal;
            return newObj;
        },
        addMeasurements(measurements){
            console.log(measurements)
            this.selectedMeasurements = measurements;
            // this.selectedMeasurementIds = measurements.map((e) => e.objectId)
            this.selectMeasurementDialog = false;
        },
        async saveEditAlertConfig() {
            if (this.editedIndex > -1) {
                let formValid = this.validate();
                if(formValid) {
                    this.editedAlertConfig.config = this.getNewConfig();
                    this.editedAlertConfig.selectedMeasurements = this.selectedMeasurements.map((el) => el.objectId)
                    this.editedAlertConfig.selectedUsers = this.selectedUsers;
                    if(this.notifyAllUsers){
                        this.editedAlertConfig.selectedUsers = [];
                    }
                    // return null;
                    try {
                        let res = await client.robustPost(`/admins/alertconfigs/${this.editedAlertConfig.objectId}`,{alertConfig: this.editedAlertConfig, siteId: this.siteId})
                        if(res.successMessage) {
                            console.log('AlertConfig Updated',res.alertConfig);
                            Object.assign(this.dtEditAlertConfigs[this.editedIndex], this.editedAlertConfig);
                            this.closeModal();
                        }
                    }
                    catch(error) {
                        console.error(error);
                    }
                }
            } else {
                this.dtEditAlertConfigs.push(this.editedAlertConfig)
                this.closeModal();
            }
        },
        getDisplayName(m){
            if(m.type == "scoreAQ"){
                return "Air Quality Score"
            } else if(m.type == "scoreEQ"){
                return "Equipment Score"
            } else if(m.type == "scoreOW"){
                return "Occupant Wellness Score"
            } else if(m.type == "scoreOA"){
                return "Overall Score"
            } else if(m.type == "scoreAF"){
                return "Air Freshness Score"
            } else {
                return m.name + " " + (m.unit ? `(${m.unit})` : "")
            }
        },
        modifyAlertUsers(users){
            // console.log(users)
            this.selectedUsers = users;
        }
    },
    mounted() {
        this.addMeasurements(this.editedAlertConfig.measurements)
        if(this.configKey) {
            if(this.configTypeStr.includes(this.configKey))
                this.configStrVal = this.configVal;
            if(this.configTypeNum.includes(this.configKey))
                this.configNumVal = this.configVal;
        }
    },
    computed: {
        selectedMeasurementIds(){
            return this.selectedMeasurements.map((el) => el.objectId)
        },
        getMeasurementChipName(){
            return (m) => {
                return this.getDisplayName(m) + (m.zone ? ` - ${m.zone.name}` : " - Site")
            }
        }
    },
}
</script>
<style lang="sass" scoped>
    .required
        color: #f00
    .alertconfig-edit-dialog-card
        .name-wrp
            display: flex
    .measurement-chips
        background: var(--input-bg)
        border-radius: 12px
        color: var(--input-txt)
        margin-bottom: 15px
        padding: 8px
        width: 100%
        overflow-x: auto
    .measurement-chips-btn
        margin: 0
</style>