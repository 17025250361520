<template lang="pug">
#haq-nav
    v-app-bar(app dark).elevation-4
      router-link(to='/').logo
        img(:src="$store.state.logo" :class="$store.state.defaultLogo ? 'default-logo' : '' " )
      v-tabs.d-none.d-sm-flex
        v-tab(v-for="item in navItems" :key="item.title" link :to="item.link") {{item.title}}
      v-spacer
      v-app-bar-nav-icon.d-flex.d-sm-none(dark @click.stop="drawer=!drawer")
      v-icon.d-none.d-sm-flex.theme-toggle(@click="toggleTheme") mdi-invert-colors
      v-divider.d-none.d-sm-flex.mx-4(inset vertical)
      v-menu.d-none.d-sm-flex(offset-y origin="top right" transition="scale-transition")
        template(v-slot:activator="{on, attrs}")
          v-list.desktop-admin-list.d-none.d-sm-flex
            v-list-item(two-line)
              v-list-item-content
                v-list-item-title {{firstName}} {{lastName}}
                v-list-item-subtitle {{$store.state.isSuperAdmin ? 'Super Admin' : 'Admin'}}
              v-list-item-avatar(v-bind="attrs" v-on="on")
                img.avatar(:src="profilePhoto ? profilePhoto : '/images/ssprofile.png'")
        v-list
          v-list-item(@click="openApp")
            v-list-item-title Open HawkenAQ
          v-list-item(@click="logout" color="warning")
            v-list-item-title Logout

    //- MOBILE DRAWER
    v-navigation-drawer.mobile-drawer(v-model="drawer" app temporary)
      template(v-slot:prepend)
        v-list-item.user-profile(two-line)
          v-list-item-avatar.user-img(v-if="$store.state.user")
            img(:src="profilePhoto ? profilePhoto : '/images/ssprofile.png'")
          v-list-item-content.user-info(v-if="$store.state.user")
            v-list-item-title.user-name {{firstName}} {{lastName}}
            v-list-item-subtitle.user-role {{$store.state.isSuperAdmin ? 'Super Admin' : 'Admin'}}
          v-btn(icon @click.stop="drawer = !drawer")
            v-icon mdi-chevron-left
      v-divider
      v-list
        v-list-item.nav-links(v-for="item in navItems" :key="item.title" link :to="item.link")
          v-list-item-content {{item.title}}
      .h-divide
      v-list
        v-list-item(@click="openApp")
          v-list-item-title Open HawkenAQ
      template(v-slot:append)
        .h-divide
        v-list
          v-list-item.theme-toggle(@click="toggleTheme")
            v-list-item-icon
              v-icon mdi-invert-colors
            v-list-item-content
            v-list-item-title {{currentTheme ? 'Disable Dark Theme' : 'Enable Dark Theme'}}
        .h-divide
        v-list-item(@click="logout")
          v-list-item-icon
            v-icon mdi-logout
          v-list-item-content
            v-list-item-title Log Out
</template>
<script>
import {HTTPClient} from '../assets/js/http';
let client = new HTTPClient();

export default {
  name: "Navbar",
  data(){
    return {
      drawer: false,
      navItems: []
    }
  },
  methods: {
    logout() {
      let subdomain = location.host.split('.')[0];
      let domain = location.host.split('.')[1];
      let beta = subdomain.indexOf("beta") > -1 ? true : false;

      // if(subdomain.includes('localhost'))
      //   location = 'http://localhost:3000/logout'
      // else
      location = beta ? `https://beta.${domain}.com/logout` : `https://app.${domain}.com/logout`;
    },
    openApp(){
      let subdomain = location.host.split('.')[0];
      let domain = location.host.split('.')[1];
      let beta = subdomain.indexOf("beta") > -1 ? true : false;

      // if(subdomain.includes('localhost'))
      //   location = 'http://localhost:3000'
      // else
      location = beta ? `https://beta.${domain}.com/` : `https://app.${domain}.com/`;
    },
    toggleTheme(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      let theme = this.$vuetify.theme.dark ? 'dark' : 'light';
      this.$store.dispatch('setTheme',theme);
      client.robustPost('/api/theme', {theme}).then((function() {
        // console.log('Theme saved to ' + theme);
      })).catch(function(error) {
        console.error(error);
      });
    }
  },
  mounted(){
    this.navItems = [
      {
        title: "Home",
        icon: "mdi-view-dashboard",
        link: "/"
      },
      {
        title: "Projects",
        link: "/projects"
      },
    ]
  },
  computed: {
    firstName(){
      return this.$store.state?.user?.firstName
    },
    lastName(){
      return this.$store.state.user?.lastName
    },
    profilePhoto(){
      return this.$store.state.user?.profilePhoto?.url.replace('http://','https://')
    },
    currentTheme(){
      return this.$vuetify.theme.dark;
    }
  }
};
</script>
<style lang="sass">
  header
    .v-tabs-slider-wrapper
      height: 4px !important
      color: var(--navbar-link-active) !important
    .v-toolbar__content
      margin: 0 auto
    //   max-width: 1340px !important
</style>
<style lang="sass" scoped>
  .theme--dark.v-app-bar.v-toolbar.v-sheet
    background: var(--app-secondary-bg) !important

  // SHARED NAV ITEMS
  .h-divide
    background: rgba(125, 133, 197, 0.2)
    height: 1px
    margin: 8px auto
    width: 90%
  .v-divide
    background: rgba(125, 133, 197, 0.2)
    height: 40px
    margin: 0 40px 0 20px
    transform: matrix(-1, 0, 0, 1, 0, 0)
    width: 2px


  header
    height: 64px
    padding: 0 20px 0 20px
    box-shadow: 0px 4px 4px 0px #00000040
    
    .theme-toggle
      color: var(--navbar-theme-toggle)
    
    .v-divider 
      border-color: var(--border)
    
    .v-btn 
      margin-left: -8px !important
      color: var(--navbar-theme-toggle) !important
      .v-icon 
        font-size: 36px !important
      
    
    a.v-tab 
      color: var(--navbar-link-inactive) !important
      font-size: 20px
      font-weight: 400
      text-transform: none
    
    a.v-tab--active 
      color: var(--navbar-link-active) !important
        
    .logo 
      max-height: 64px
      line-height: 0
      padding-top: 4px
      margin-right: 40px
      .default-logo 
        max-height: 40px
      
      
    .v-list 
      background: transparent
      .v-list-item__title,
      .v-list-item__subtitle 
        color: var(--navbar-txt) !important
      
      .v-list-item__subtitle 
        opacity: .6
      
    
    .desktop-admin-list 
      padding: 0
      .v-list-item 
        min-height: auto
        padding: 0
        text-align: right
        .v-list-item__content 
          padding: 0
        
        .v-list-item__avatar 
          display: flex
          align-items: center
          justify-content: center
          border-radius: 50px
          max-height: 40px
          max-width: 40px
          overflow: hidden
          margin-top: 0
          margin-bottom: 0
          .avatar 
            min-width:100%
            max-width: 40px

  .mobile-drawer
      background-color: var(--app-secondary-bg) !important
      display: flex
      flex-direction: column
      height: 100vh
      padding-bottom: 0
      a
          color: var(--app-tertiary-txt)
          cursor: pointer
          font-size: 16px
          font-weight: 400

      .user-profile
          .user-img,
          .user-info
              display: inline-block
              vertical-align: middle
          .user-img,
          .user-img img
              width: 50px !important
              height: 50px !important
              border-radius: 25px

          .user-info
              .user-name
                  font-size: 18px
                  line-height: 24px
              .user-role
                  color: var(--navbar-link-inactive)
                  font-size: 14px
                  line-height: 20px
                  font-weight: 300
</style>