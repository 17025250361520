<template lang="pug">
	.kiosk-card
		.kiosk-header
			.kiosk-title {{kioskName}}
			v-spacer
			v-menu(offset-y origin="top right" transition="scale-transition")
				template(v-slot:activator="{on,attrs}")
					v-icon(v-on="on" v-bind="attrs") mdi-cog 
				v-list
					v-list-item(v-for="(item, index) in items" :key="index" @click.stop="currAction = item.action;sendAction()")
						v-list-item-title {{item.title}}
		.kioskcard-measurement
			KioskPublic(:kiosk="kiosk" :shareKiosk="true")
		#modal-share-kiosk.modal
</template>
<script>
import KioskPublic from '@/components/KioskPublic.vue'
import ModalShareKiosk from '@/components/ModalShareKiosk'
export default {
	name: 'KioskCard',
	components: {
		KioskPublic,
		ModalShareKiosk,
	},
	data() {
		return {
			kioskName: this.kiosk.name ? this.kiosk.name : this.kiosk?.zone?.name || this.kiosk?.site?.name,
			siteId: this.$route.query.siteId,
			currentSite: {},
			currentKiosk: {},
			zone: this.kiosk?.zone?.name,
			items: [{title: 'Rename', action: 'rename'},{title: 'Share', action: 'share'}],//[ { title: 'Edit' }, {title: 'Rename'}, {title: 'Share', action: 'share'}, {title: 'Delete'}],
			currAction: null,
		}
	},
    props: {
		title: String,
		kiosk: Object,
		users: Array,
		index: Number,
	},
	mounted() {
	},
	methods: {
		closeShareModal() {
			this.currAction = null
		},
		sendAction() {
			this.$emit('sendAction', {action: this.currAction, kiosk: this.kiosk, index: this.index});
		},
		renameKiosk(name) {
			this.kioskName = name;
		}
	}
}
</script>
<style lang="scss" scoped>
	.kiosk-card {
		background: linear-gradient(139.8deg, var(--card-primary-bg) 23.95%, var(--card-secondary-bg) 109.29%);
		border-radius: 12px;
		box-shadow: var(--box-shadow);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 24px;
		min-width: 100px;
		padding: 24px;
		width: 100%;
		height: 485px;
		@media (min-width: 960px) {
			max-width: 625px;
		}
		.kiosk-header {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap:14px;
			.project-logo {
				height: 30px;
				margin-right: 15px;
			}
			@media (max-width: 500px) {
				.project-title {
					flex-basis: 100%;
					order: 15;              
				}
			}
		}
	}
	.navigateToLink {
		color: var(--app-primary-txt);
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
	.kioskcard-measurement {
		width: 406px;
		height: 265px;
	}
	.kioskcard-body {
		padding: 92px 1.5rem;
		padding-top: 0px;
	}
	.material-icons {
		&.settings {
			display: none;
			cursor: pointer;
		}
	}
	.cardHeader {
		&:hover {
			.settings {
				display: inline-flex
			}
		}
	}
	#modal-share-kiosk {
		background-color: var(--bg-color3);
		border-radius: 20px;
		width: 35%;
		height: 65%;
	}	
</style>