<template lang="pug">
    #manageProjects.haq-card-page
        Navbar(tab="Projects")
        v-container
            v-row
                v-col
                    Breadcrumbs(:items="bcItems")
            v-row
                v-col(:cols="12" :sm="12" :md="8")
                    .page-header
                        .page-title
                            h1 Projects
                        .page-actions
                            //- v-btn.haq-btn.haq-btn-primary.add-project(plain @click="currentDialog='add'") <v-icon>mdi-plus</v-icon> Project
                v-col(:cols="12" :sm="12" :md="4")
                    v-text-field(v-model="searchQuery" label="Search Projects" append-icon="mdi-magnify" dense hide-details single-line outlined).haq-search
            //- v-row
            //-     v-col(sm=5)
            //-         v-text-field.haq-search(v-model="searchQuery" append-icon="mdi-magnify" label="Filter Projects" dense filled hide-details single-line outlined)
            v-row(v-if="projects")
                v-col
                    .page-content
                        .page-has-cards.projects(v-if="projects.length > 0")
                            ProjectCard(
                                v-for="p,index in projects"
                                :projectId="p.project.objectId"
                                :title="p.project.name" 
                                :logoUrl="getLogo(p.project)" 
                                :customerName="p.project.customer?p.project.customer.name:''"
                                :siteCount="p.sites.length"
                                :userCount="p.users.length"
                                :groupCount="p.groups.length"
                                :key="index"
                                v-show="showThisProject(p.project.name)"
                                v-on:openEditModal="openEditModal"
                            )
                        .page-no-cards.no-projects(v-else)
                            p There are no projects assigned to your account.
                            //- v-btn.haq-btn.haq-btn-primary.add-project(plain @click="currentDialog='add'") <v-icon>mdi-plus</v-icon> Project

        ModalProjectAdd(v-if="currentDialog == 'add'" :openModal="currentDialog" v-on:closeModal="closeModal")
        ModalProjectEdit(v-if="currentDialog == 'edit'" :openModal="currentDialog" v-on:closeModal="closeModal"
            :project="editProject")
        ModalProjectRemove(v-if="currentDialog == 'remove'" :openModal="currentDialog" v-on:closeModal="closeModal")
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import ProjectCard from '@/components/ProjectCard.vue'
import ModalProjectAdd from '@/components/project_crud/ModalProjectAdd'
import ModalProjectEdit from '@/components/project_crud/ModalProjectEdit'
import ModalProjectRemove from '@/components/project_crud/ModalProjectRemove'
import {HTTPClient} from '../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'Projects',
    components: {
        Navbar,
        Breadcrumbs,
        ProjectCard,
        ModalProjectAdd,
        ModalProjectEdit,
        ModalProjectRemove,
    },
    data() {
        return {
            projects: null,
            bcItems: [
                { text: 'Home', disabled: false, href: '/', },
                { text: 'Projects', disabled: true, href: '/project', },
            ],
            currentDialog: null,
            searchQuery: "",
            editProject: null,
        }
    },
    methods: {
        closeModal() {
			this.currentDialog = null;
		},
        openEditModal(projectId) {
            this.editProject = this.projects.find(p => p.project.objectId === projectId).project;
            this.currentDialog = 'edit';
        },
        loadOutNavItems(projects){
            let array = JSON.parse(JSON.stringify(projects));
            array = array.map(function(el) {
                return {
                    title: el.name,
                    icon: 'mdi-certificate',
                    link: "/project/" + el.objectId
                }
            })
            this.navItems = array;
        },
        getLogo(project) {
            let theme = this.$vuetify.theme.dark ? 'dark' : 'light';
            let dlogo = project.darkLogo;
            let llogo = project.lightLogo;

            if(!dlogo && !llogo)
                return '';

            if (theme === 'dark')
                return dlogo ? dlogo.url : '';
            return llogo ? llogo.url : '';
        },
        fetchProjects: async function(){
            try {
                let projects = [];
                let results = await client.robustQuery('/admins/projects');
                if (results.projects.length > 0) {
                    results.projects.forEach(p => {
                        let project = {
                            project: p,
                            sites: [],
                            users: [], 
                            groups: []
                        };
                        results.sites.forEach(s => {
                            if(s.project?.objectId === p.objectId)
                                project.sites.push(s)
                        })                        
                        results.users.forEach(u => {
                            if(u.projects?.includes(p.objectId))
                                project.users.push(u)
                        })
                        results.groups.forEach(g => {
                            if(g.project?.objectId === p.objectId)
                                project.groups.push(g)
                        })
                        projects.push(project);
                    })
                }
                this.projects = projects;
            } catch (error) {
                console.error(error);
            }
        },
    },
    mounted() {
        this.fetchProjects();
    },
    computed: {
        showThisProject(){
            return(name) => {
                let r = new RegExp(this.searchQuery, "i");
                return r.test(name)
            }
        }
    }
}
</script>