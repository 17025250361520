<template lang="pug">
    v-dialog(v-model="dialog" max-width="792px" @input="closeModal" scrollable)
        v-card.haq-dialog-card
            v-card-title
                span(class="text-h5") Remove {{multipleUsers ? 'Users' : 'User' }}
                v-spacer
                v-icon.close(text @click="closeModal") mdi-close
            v-card-text
                v-row
                    v-col(cols="12") Remove the following {{multipleUsers ? 'users' : 'user' }} from {{project ? project.name : 'this project'}}?
                        ul
                            li(v-for="u,index in selectedRemoveUser")
                                .user {{u.firstName}} {{u.lastName}} ({{u.email}})
            v-card-actions
                v-spacer
                v-btn.haq-btn.haq-btn-secondary(text @click="closeModal") Cancel
                v-btn.haq-btn.haq-btn-primary(text @click="saveRemoveUsers") Confirm
</template>
<script>
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'ModalProjectUserRemove',
    props: {
        projectId: String,
        project: Object,
        selectedRemoveUser: Array,
        openModal: String,
    },
    data() {
        return {
            multipleUsers: this.selectedRemoveUser.length > 1 ? true : false,
            view: 0,
            dialog: this.openModal == 'remove' ? true : false,
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit('closeModal')
		},
        saveRemoveUsers() {
            if(this.selectedRemoveUser.length > 0) {
                this.selectedRemoveUser.forEach(u => {
                    if (u.projects.indexOf(this.projectId) > -1){
                        u.projects.splice(u.projects.indexOf(this.projectId), 1);
                    }
                    client.robustPost(`/api/users/${u.objectId}`,u).then(function(result){
                        console.log('User Updated - Removed from project',result.successMessage);
                    }).catch(function(error){
                        console.log("User Not Updated", error.errorMessage);
                    })
                })
            }
            this.closeModal();
        }
    }
}
</script>